import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addRoles, updateRoles } from "../../actions/userActions/roleAction";
import {
  getAllPermissions,
  getAllPermissionsGroup,
} from "../../actions/userActions/permissionsAction";
import Loader from "../subComponents/Loader";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import "./Roles.css";
function AddRoles() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const initialValues = {
    name: "",
    description: "",
    roleGroup: [],
    active: false,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [selected, setSelected] = useState([]);
  const [preSelected, setPreSelected] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getAllPermissionsGroup());

    if (location.role) {
      const roleGroupIds = location.role.row.permissionGroup.map(
        (item) => item.id
      );

      setFormValues({
        ...formValues,
        name: location.role.row.name,
        description: location.role.row.description,
        id: location.id,
        active: location.role.row.active,
        roleGroup: roleGroupIds,
      });

      setSelected(
        location.role.row.permissionGroup.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );

      setPreSelected(roleGroupIds);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      location.role
        ? dispatch(updateRoles(formValues))
        : dispatch(addRoles(formValues));
    }
  }, [formErrors]);

  const addRolesHandle = async (e) => {
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (name === "active" && formValues.active === true) {
      setFormValues({ ...formValues, [name]: false });
    } else if (name === "active" && formValues.active === false) {
      setFormValues({ ...formValues, [name]: true });
    }
  };

  const multiHandleChange = (e) => {
    const select = [];
    setSelected(e);
    e.map((row, i) => {
      select.push(row.value);
    });
    setFormValues({
      ...formValues,
      ["roleGroup"]: select.map((row, i) => row),
    });
  };
  const getOptions = (data, name) => {
    const getData =
      data && data !== "isLoading"
        ? data.map((row, i) => ({
            value: row.id,
            label: row.name
              ? row.name
              : row.firstName && row.lastName
              ? `${row.firstName} ${row.lastName}`
              : row.address,
            name: name,
          }))
        : [];
    return getData;
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.description) {
      errors.description = "Description is required!";
    }
    if (values.roleGroup.length <= 0) {
      errors.roleGroup = "Permission Group is required!";
    }
    return errors;
  };
  const addRole = useSelector((state) => state.roles_reducer.addRoles);
  const allPermissionsGroup = useSelector(
    (state) => state.permissions_reducer.allPermissionsGroup
  );
  const updateRole = useSelector((state) => state.roles_reducer.updateRole);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  return (
    <>
      {addRole && addRole !== "isLoading" && isSubmit && (
        <Redirect to="/admin/roles" />
      )}
      {updateRole && updateRole !== "isLoading" && isSubmit && (
        <Redirect to="/admin/roles" />
      )}
      {!location.role && <Redirect to="/admin/AddRoles" />}
      {!permissions.includes("CanAddRole") && <Redirect to="/admin" />}
      {!permissions.includes("CanUpdateRole") && <Redirect to="/admin" />}

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inline-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              {location.role ? "Update Role" : "Add Role"}
            </h2>
          </div>
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label required">NAME</label>
              <input
                name="name"
                type="text"
                className="form-control"
                value={formValues.name}
                onChange={handleChange}
              />
              <div style={{ color: "red" }}>{formErrors.name}</div>
            </div>
            <div className="form-group">
              <label className="form-control-label required">Description</label>
              <input
                name="description"
                type="text"
                className="form-control"
                value={formValues.description}
                onChange={handleChange}
              />
              <div style={{ color: "red" }}>{formErrors.description}</div>
            </div>
            <div className="col-md-12 p-0">
              <div className="form-group">
                <label className="form-control-label required">
                  Permission
                </label>
                <MultiSelect
                  options={getOptions(allPermissionsGroup)}
                  value={selected}
                  onChange={multiHandleChange}
                  labelledBy="Select"
                  className="multi-select"
                />
                <div style={{ color: "red" }}>{formErrors.roleGroup}</div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="form-control-label">Is Role active ?</label>
                <input
                  name="active"
                  type="checkbox"
                  checked={formValues.active}
                  className="ml-2 mt-2"
                  onChange={handleChange}
                />
              </div>
            </div>
            {addRole === "isLoading" ? (
              <div className="col-lg-12 ">
                <Loader style={{ color: "red" }} />
              </div>
            ) : (
              <div className="col-lg-12 ">
                <div className="col-lg-12 login-btm login-button d-block">
                  <button
                    className="btn btn-outline-primary d-block m-auto"
                    onClick={addRolesHandle}
                  >
                    {" "}
                    {location.role ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddRoles;
