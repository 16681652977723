import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getPracticeLocationTypes,
  addPracticeLocation,
  updatePracticeLocations,
} from '../../actions/userActions/practiceLocationAction'
import Loader from '../subComponents/Loader'
import { getCities } from '../../actions/userActions/cityAction'
import { Redirect } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router-dom'
function AddPracticeLocations() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    name: '',
    type: '',
    address: '',
    city: '',
  }

  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const [page, setPage] = useState(1)

  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })
  const cityBody = {
    filter: cityFilters,
    pageNumber: page,
    pageSize: 10,
  }

  useEffect(() => {
    dispatch(getCities(cityBody))
    .then(() => {
      dispatch(getPracticeLocationTypes());
    })
    .catch(error => {
      console.error("Error fetching data:", error);
  });
    {
      location.practiceLocation &&
        setFormValues({
          ...formValues,
          ['name']: location.practiceLocation.row.name,
          ['type']: location.practiceLocation.row.type,
          ['address']: location.practiceLocation.row.address,
          ['city']: location.practiceLocation.row.city,
          ['id']: location.id,
        })
    }
  }, [])

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      {
        location.practiceLocation
          ? dispatch(updatePracticeLocations(formValues))
          : dispatch(addPracticeLocation(formValues))
      }
    }
  }, [formErrors])

  const addSubmitHandle = async (e) => {
    setFormErrors(validate(formValues))
    setIsSubmit(true)
  }

  const hanleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Name is required!'
    }
    if (!values.address) {
      errors.address = 'Address is required!'
    }
    if (values.city === '') {
      errors.city = 'City is required!'
    }
    if (values.type === '') {
      errors.type = 'Type is required!'
    }
    return errors
  }

  const allCities = useSelector((state) => state.cities_reducer.cities)
  const practiceLocationType = useSelector(
    (state) => state.practiceLocations_reducer.practiceLocationTypes,
  )
  const addPracticeLocations = useSelector(
    (state) => state.practiceLocations_reducer.addPracticeLocation,
  )
  const updatePracticeLocation = useSelector(
    (state) => state.practiceLocations_reducer.updatePracticeLocation,
  )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  return (
    <>
      {addPracticeLocations &&
        addPracticeLocations != 'isLoading' &&
        isSubmit && <Redirect to="/admin/organizationLocations" />}
      {!permissions.includes('CanAddOfficeLocation') && (
        <Redirect to="/admin" />
      )}
      {!permissions.includes('CanUpdateOfficeLocation') && (
        <Redirect to="/admin" />
      )}
      {!location.practiceLocation && (
        <Redirect to={'/admin/AddOrganizationLocations'} />
      )}

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />{' '}
              {location.practiceLocation ? 'Update' : 'Add'}  Location
            </h2>
          </div>
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label required">Name</label>
              <input
                name="name"
                type="text"
                className="form-control"
                value={formValues.name}
                onChange={hanleChange}
              />
             <div style={{ color: 'red' }}>{formErrors.name}</div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label required">Location Type</label>
              <select
                name="type"
                className="form-control"
                style={{ height: '45px' }}
                value={formValues.type}
                onChange={hanleChange}
              >
                <option value="" disabled>
                  {' '}
                  Select Type
                </option>
                {practiceLocationType !== 'isLoading' &&
                  practiceLocationType.map((row, i) => (
                    <option key={i} value={row.id}>
                      {row.name}
                    </option>
                  ))}
              </select>
              <div style={{ color: 'red' }}>{formErrors.type}</div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label required">Address</label>
              <input
                name="address"
                type="text"
                className="form-control"
                value={formValues.address}
                onChange={hanleChange}
              />
             <div style={{ color: 'red' }}>{formErrors.address}</div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label required">Select City</label>
              <select
                name="city"
                className="form-control"
                style={{ height: '45px' }}
                value={formValues.city}
                onChange={hanleChange}
              >
                <option value="" disabled>
                  {' '}
                  Select City
                </option>
                {allCities !== 'isLoading' &&
                  allCities?.cities.map((row, i) => (
                    <option key={i} value={row.id}>
                      {row.name}
                    </option>
                  ))}
              </select>
              <div style={{ color: 'red' }}>{formErrors.city}</div>
            </div>
          </div>
          {addPracticeLocations === 'isLoading' ? (
            <div className="col-lg-12 loginbttm">
              <Loader style={{ color: 'red' }} />
            </div>
          ) : (
            <div className="col-lg-12 loginbttm">
              <div className="col-lg-12 login-btm login-button d-block">
                <button
                  className="btn btn-outline-primary d-block m-auto"
                  onClick={addSubmitHandle}
                >
                  {' '}
                  {location.practiceLocation ? 'Update' : 'Submit'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AddPracticeLocations
