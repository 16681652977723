import {
    EMPLOYEE_SUCCESS,
    EMPLOYEE_FAIL,
    SET_MESSAGE,
    EMPLOYEE_PENDING,
    SINGLE_EMPLOYEE_SUCCESS,
    SINGLE_EMPLOYEE_FAIL,
    SINGLE_EMPLOYEE_PENDING,
    EMPLOYEE_SUB_ORDINATES_SUCCESS,
    EMPLOYEE_SUB_ORDINATES_PENDING,
    EMPLOYEE_SUB_ORDINATES_FAIL,
    ADD_EMPLOYEE_SUCCESS,
    ADD_EMPLOYEE_FAIL,
    ADD_EMPLOYEE_PENDING,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAIL,
    UPDATE_EMPLOYEE_PENDING,
    GENDER_SUCCESS, 
    GENDER_PENDING, 
    GENDER_FAIL, 
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";


export const getEmployees = () => async(dispatch) => {
    dispatch({
        type: EMPLOYEE_PENDING,
        payload: { employees: "isLoading" },
    });
    return await UserService.getEmployees().then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: EMPLOYEE_SUCCESS,
                    payload: { employees: data.data.result },
                }) :
                dispatch({
                    type: EMPLOYEE_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: EMPLOYEE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

export const getEmployee = (id) => async(dispatch) => {
    dispatch({
        type: SINGLE_EMPLOYEE_PENDING,
        payload: { employee: "isLoading" },
    });
    return await UserService.getEmployee(id).then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: SINGLE_EMPLOYEE_SUCCESS,
                    payload: { employee: data.data.result[0] },
                }) :
                dispatch({
                    type: SINGLE_EMPLOYEE_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SINGLE_EMPLOYEE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

export const getEmployeeSubOrdinates = (id) => async(dispatch) => {
    dispatch({
        type: EMPLOYEE_SUB_ORDINATES_PENDING,
        payload: { employeeSubordinates: "isLoading" },
    });
    return await UserService.getEmployeeSubOrdinates(id).then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: EMPLOYEE_SUB_ORDINATES_SUCCESS,
                    payload: { employeeSubordinates: data.data.result },
                }) :
                dispatch({
                    type: EMPLOYEE_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: EMPLOYEE_SUB_ORDINATES_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

//Get Genders
export const getGenders = () => async(dispatch) => {
    dispatch({
        type: GENDER_PENDING,
        payload: { genders: "isLoading" },
    });
    return await UserService.getGender().then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: GENDER_SUCCESS,
                    payload: { genders: data.data.result },
                }) :
                dispatch({
                    type: GENDER_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: GENDER_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

    // Add employees 
    export const addEmployees = (body) => async(dispatch) => {
        dispatch({
            type: ADD_EMPLOYEE_PENDING,
            payload: { addEmployee: "isLoading" },
        });
        return await UserService.addEmployee(body).then(
            (data) => {
                data.data.status ? toast.success("Employee added Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
                data.data.status ?
                    dispatch({
                        type: ADD_EMPLOYEE_SUCCESS,
                        payload: { addEmployee : data.data.status},
                    })
                    :
                    dispatch({
                        type: ADD_EMPLOYEE_FAIL,
                        payload: { addEmployee : data.data.status},
                    });
                return Promise.resolve();
            },
            (error) => {
                console.log("error......", error);
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
    
                dispatch({
                    type: ADD_EMPLOYEE_FAIL,
                });
    
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
    
                return Promise.reject();
            }
        ).catch((error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
                toast.error(message, {autoClose: 2000 })
            dispatch({
                type: ADD_EMPLOYEE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        });
    };

    // Update employees 
    export const updateEmployees = (body) => async(dispatch) => {
        dispatch({
            type: UPDATE_EMPLOYEE_PENDING,
            payload: { updateEmployee: "isLoading" },
        });
        return await UserService.updateEmployee(body).then(
            (data) => {
                data.data.status ? toast.success("Employee updated Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
                data.data.status ?
                    dispatch({
                        type: UPDATE_EMPLOYEE_SUCCESS,
                        payload: { updateEmployee : data.data.status},
                    })
                 :
                    dispatch({
                        type: UPDATE_EMPLOYEE_FAIL,
                        payload: { updateEmployee : data.data.status},
                    });
                return Promise.resolve();
            },
            (error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
    
                dispatch({
                    type: UPDATE_EMPLOYEE_FAIL,
                });
    
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
    
                return Promise.reject();
            }
        ).catch((error) => {
            console.log("API",error)
            const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
    toast.error(message, {autoClose: 2000 })
                dispatch({
                    type: UPDATE_EMPLOYEE_FAIL,
                });
    
                dispatch({
                    type: SET_MESSAGE,
                    payload: message,
                });
    
                return Promise.reject();
        });
    };