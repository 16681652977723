import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getEmploymentStatuses } from "../../actions/userActions/employmentStatusAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
function EmploymentStatuses() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getEmploymentStatuses());
  }, []);
  const employmentStatuses = useSelector(
    (state) => state.employmentStatuses_reducer.employmentStatuses
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");
  const filter_employmentStatuses =
    employmentStatuses !== "isLoading"
      ? employmentStatuses.filter((employmentStatus) => {
          return employmentStatus.name
            .toLowerCase()
            .includes(query.toLowerCase());
        })
      : [];
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              Employment Status
            </h2>
          </div>
          <div className="col-md-4">
            <Search
              setText={setQuery}
              text={query}
              placeholder="Search Status"
            />
          </div>
          <div className="col-md-4">
            {permissions.includes("CanAddEmploymentStatus") && (
              <Link
                to={"/admin/AddEmploymentStatuses"}
                className="btn btn-primary float-right"
              >
                Add Employment Status
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>Name</td>
                  <td>Description</td>
                  <td>
                    {" "}
                    <b>Active</b>{" "}
                  </td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody className="text-left">
                {employmentStatuses === "isLoading" && (
                  <tr>
                    <td
                      colSpan={6}
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {filter_employmentStatuses !== "isLoading" &&
                  filter_employmentStatuses.map((row, i) => (
                    <tr key={i}>
                      <td>{row.name}</td>
                      <td>{row.description}</td>
                      <td>{row.active ? "Yes" : "No"}</td>
                      <td>
                        <Link
                          to={{
                            pathname: "/admin/UpdateEmploymentStatuses",
                            employmentStatus: { row },
                            id: row.id,
                          }}
                        >
                          <i className="fas fa-edit" />
                        </Link>{" "}
                        {/* <Link className="ml-2" to={"#"}>
                          <i
                            className="fas fa-trash"
                            style={{ color: "red" }}
                          />
                        </Link> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmploymentStatuses;
