import "./Linechart.css";
import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { LinechartFilter } from "./LinechartFilter";
import Loader from "../Loader";

export function Linechart() {
  const [appliedFilters, setAppliedFilters] = useState([]);
  function choosefilters(filters) {
    setAppliedFilters(filters);
  }
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [productsName, setProductsName] = useState([]);
  const products = useSelector((state) => state.products_reducer.products);
 
  const setProductNames = (array) => {
    setProductsName(array);
  };
  useEffect(() => {
   
    let data = [];
    products.products &&
      products !== "isLoading" &&
      products.products.map((row) => {
        appliedFilters.map((row2) => {
          if (row.id == row2) {
            data.push(row.name);
          }
        });
      });
    setProductNames(data);
  }, [products, appliedFilters]);


  const toggleModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };
  const [uniqueProducts, setUniqueProducts] = useState([]);

  const callAvgEmployee = useSelector(
    (state) => state.call_avg_emp_reducer.callAvgEmp
  );

  // push data in array for rechart
  const [data, setData] = useState([]);
  useEffect(() => {
    if (
      callAvgEmployee &&
      callAvgEmployee !== "isLoading" &&
      callAvgEmployee.byEmployee &&
      callAvgEmployee.byEmployee.length > 0
    ) {
      const newData = callAvgEmployee.byEmployee.map((item) => ({
        date: moment(item.dateTime).format("YYYY-MM-DD"),
        call: Number(item.calls),
        productName: item.productName || "",
      }));
      setData(newData);
    }
  }, [callAvgEmployee]);

  // data conversion
  useEffect(() => {

    // Check if callAvgEmployee is defined and not null
    if (
      callAvgEmployee &&
      callAvgEmployee !== "isLoading" &&
      callAvgEmployee.byEmployee &&
      callAvgEmployee.byEmployee.length > 0
    ) {
      const uniqueProducts = [];

      const groupedData = callAvgEmployee.byEmployee.reduce((result, item) => {
        const date = item.dateTime.split("T")[0];
        const key = `${date}_${item.productName}`;

        if (!result[key]) {
          result[key] = { date };
        }

        // Handle any product name
        if (!uniqueProducts.includes(item.productName)) {
          uniqueProducts.push(item.productName);
        }

        // Set calls for the respective product
        result[key][item.productName] =
          (result[key][item.productName] || 0) + item.calls;

        return result;
      }, {});

      // Convert grouped data to the desired format
      const outputData = Object.values(groupedData).map((item) => {
        const formattedItem = {
          date: moment(item.date).format("MMM D"),
        };

        // Map calls for each product dynamically
        uniqueProducts.forEach((product) => {
          formattedItem[product] = item[product] || 0;
        });

        return formattedItem;
      });

      // Update state with the unique product names
      setUniqueProducts(uniqueProducts);

      // Update state with the converted data
      setData(outputData);
    }
  }, [callAvgEmployee]);

  return (
    <>
      <div className="chart-container" style={{ position: "relative", width: '628px', height: '374px', padding: '24px'}}>
        <div className="container">
          <div className="row" style={{height: '73px', width: '480px'}}>
            <div style={{ width: "260px", height: "23px" }}><p style={{
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "23px",
              textAlign: "left",
              color: "#000000"
            }}>Call average by Employee</p>
            </div>
          
            <div className="col-10" style={{height: '32px'}}>
              <p  style={{margin: "8px", maxHeight: '24px', fontFamily: "inter", fontWeight: "500", fontSize: "16px", lineHeight: "24px"}}>
                {callAvgEmployee.byEmployee?.[0]?.employeeName}
                </p>
            </div>
            <div className="col-2"  style={{width: '32px', height: '32px'}}>
              <button
                className="btn-sm"
                onClick={toggleModal}
                style={{ backgroundColor: "#FFFFFF", border: "1px #D9D9D9" }}
              >
                <i className="bi bi-funnel"></i>
              </button>
            </div>
            <div className="row" style={{marginTop: '8px'}}>
              {/* <div className="col-12 d-flex" style={{width: '480px', height: '24px'}}>
                {productsName &&
                  productsName.map((pn) => <span className="mr-2" style={{paddingLeft: '4px', paddingBottom: '3px', paddingRight: '4px', borderRadius: '100px', border: '1px solid #00000014', height: '24px', width: ""}}  key={pn}>{pn}</span>)}
              </div> */}
            </div>
          </div>
        </div>
        
        <div className="graph-container" style={{maxWidth: '480px', height: '240px'}}>
        {callAvgEmployee === "isLoading" &&(<div className="col-12 d-flex justify-content-center"><Loader colored={true} /></div>) }
        {callAvgEmployee !== "isLoading" &&
        callAvgEmployee.byEmployee &&
          callAvgEmployee.byEmployee !== undefined &&
          callAvgEmployee.byEmployee.length > 0 && (
            <LineChart
              width={480}
              height={240}
              data={data}
              margin={{
                top: 24,
                right: 10,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />

              {/* Dynamically generate Line components for each product */}
              {uniqueProducts.map((product, index) => (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={product} // Assuming product names are valid identifiers
                  stroke={`#${Math.floor(Math.random() * 16777215).toString(
                    16
                  )}`} // Random color
                  activeDot={{ r: 8 }}
                />
              ))}
            </LineChart>
          )}
        
          {!callAvgEmployee || (callAvgEmployee === "isLoading") ||
            (callAvgEmployee.length === 0 ||
              (callAvgEmployee.byEmployee && callAvgEmployee.byEmployee.length === 0)) && (
                <div style={{height: "240px", width: "480", border: "1px solid grey", display: "flex", alignItems: "center", justifyContent: "center", margin: "24px 10px 5px 0px"}}>
                  <div className="col-12 d-flex justify-content-center">
                No Data to show "Apply or change Filters"
              </div>
                </div>
              
            )}

        {/* Modal */}

        <LinechartFilter
          show={isModalVisible}
          onClose={closeModal}
          choosefilters={choosefilters}
        />

        </div>

       
      </div>
    </>
  );
}
