import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getProducts } from '../../actions/userActions/productAction'
import Search from '../subComponents/search/Search'
import Loader from '../subComponents/Loader'
import Pagination from '../paginatioin/pagination'

function Products() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [selected, setSelected] = useState({
    textSearch: '',
  })
  const [filters, setFilters] = useState({
    textSearch: '',
  })
  const body = {
    filter: filters,
    pageNumber: page,
    pageSize: 10,
  }
  const products = useSelector((state) => state.products_reducer.products)
  const [query, setQuery] = useState('')
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))

  useEffect(() => {
    dispatch(getProducts(body))
  }, [page, filters])

  const getPaginationNo = (pageNum) => {
    const object = []
    if (products !== 'isLoading' && products.length !== 0) {
      for (let i = 0; i < products.page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }

  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 4) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />{' '}
              Products
            </h2>
          </div>
          <div className="col-md-4">
            <input
              className="search-input"
              style={{
                width: '100%',
                height: '100%',
                paddingLeft: '10px',
                border: '2px solid #ccc',
                borderRadius: '10px',
              }}
              type="text"
              placeholder="Search Product"
              onChange={handleSearch}
              value={selected.textSearch}
            />
          </div>
          <div className="col-md-4">
            {permissions.includes('CanAddProduct') && (
              <Link
                to={'/admin/addProducts'}
                className="btn btn-primary float-right"
              >
                Add Product
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>
                    {' '}
                    <b>Name</b>{' '}
                  </td>
                  <td>
                    {' '}
                    <b>Description</b>{' '}
                  </td>
                  <td>
                    {' '}
                    <b>Active</b>{' '}
                  </td>
                  <td>
                    {' '}
                    <b>Action</b>{' '}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {products?.products && products?.products.length == 0 && (
                  <tr className="text-center">
                    <td colSpan={6}>No data found</td>
                  </tr>
                )}
                {products === 'isLoading' && (
                  <tr>
                    <td
                      colSpan={4}
                      style={{ backgroundColor: '#fff !important' }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {products !== 'isLoading' &&
                  products.length !== 0 &&
                  products.products.length !== 0 &&
                  products.products.map((row, i) => (
                    <tr key={i}>
                      <td>{row.name}</td>
                      <td>{row.description}</td>
                      <td>{row.active ? 'Yes' : 'No'}</td>
                      <td>
                        <Link
                          to={{
                            pathname: '/admin/UpdateProducts',
                            product: { row },
                            id: row.id,
                          }}
                        >
                          <i className="fas fa-edit" />
                        </Link>{' '}
                        {/* <Link className="ml-2" to={'#'}>
                          <i
                            className="fas fa-trash"
                            style={{ color: 'red' }}
                          />
                        </Link> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {products !== 'isLoading' && (
          <div className="task-pagination-wrapper">
            <Pagination
              total={
                products.page != undefined &&
                products.page.totalPages * products.page.pageSize
              }
              current={products.page != undefined && products.page.pageNumber}
              pageSize={products.page != undefined && products.page.pageSize}
              onChange={(current) => setPage(current)}
              showLessItems
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Products
