import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addDesignations,
  updateDesignations,
} from '../../actions/userActions/designationAction'
import Loader from '../subComponents/Loader'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
function AddDesignations() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    name: '',
    shortName: '',
    description: '',
    active: false,
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  useEffect(() => {
    {
      location.designation &&
        setFormValues({
          ...formValues,
          ['name']: location.designation.row.name,
          ['shortName']: location.designation.row.shortName,
          ['description']: location.designation.row.description,
          ['id']: location.id,
          ['active']: location.designation.row.active,
        })
    }
  }, [])
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      location.designation
        ? dispatch(updateDesignations(formValues))
        : dispatch(addDesignations(formValues))
    }
  }, [formErrors])

  const addDesignationHandle = async (e) => {
    setFormErrors(validate(formValues))
    setIsSubmit(true)
  }

  const hanleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    if (
      (name === 'active' && formValues.active === true) ||
      formValues.active === 'on'
    ) {
      setFormValues({ ...formValues, [name]: false })
    } else if (name === 'active' && formValues.active === false) {
      setFormValues({ ...formValues, [name]: true })
    }
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Name is required!'
    }
    if (!values.shortName) {
      errors.shortName = 'Short name is required!'
    }
    if (!values.description) {
      errors.description = 'Description is required!'
    }
    return errors
  }
  const addDesignation = useSelector(
    (state) => state.designations_reducer.addDesignation,
  )
  const updateDesignation = useSelector(
    (state) => state.designations_reducer.updateDesignation,
  )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  return (
    <>
      {addDesignation && addDesignation !== 'isLoading' && isSubmit && (
        <Redirect to="/admin/designations" />
      )}
      {!location.designation && <Redirect to="/admin/AddDesignations" />}
      {!permissions.includes('CanAddDesignation') && <Redirect to="/admin" />}
      {!permissions.includes('CanUpdateDesignation') && (
        <Redirect to="/admin" />
      )}

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />{' '}
              {location.designation ? 'Update Designation' : 'Add Designation'}
            </h2>
          </div>
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label required">NAME</label>
              <input
                name="name"
                type="text"
                className="form-control"
                value={formValues.name}
                onChange={hanleChange}
              />
              <div style={{ color: 'red' }}>{formErrors.name}</div>
            </div>
            <div className="form-group">
              <label className="form-control-label required">SHORT NAME</label>
              <input
                name="shortName"
                type="text"
                className="form-control"
                value={formValues.shortName}
                onChange={hanleChange}
              />
              <div style={{ color: 'red' }}>{formErrors.shortName}</div>
            </div>
            <div className="form-group">
              <label className="form-control-label required">Description</label>
              <input
                name="description"
                type="text"
                className="form-control"
                value={formValues.description}
                onChange={hanleChange}
              />
              <div style={{ color: 'red' }}>{formErrors.description}</div>
            </div>
            <div className="col-md-12"></div>
            {addDesignation === 'isLoading' ? (
              <div className="col-lg-12 loginbttm">
                <Loader style={{ color: 'red' }} />
              </div>
            ) : (
              <div className="col-lg-12 loginbttm">
                <div className="col-lg-12 login-btm login-button d-block">
                  <button
                    className="btn btn-outline-primary d-block m-auto"
                    onClick={addDesignationHandle}
                  >
                    {' '}
                    {location.designation ? 'Update ' : 'Submit'}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddDesignations
