import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getDoctors } from "../../actions/userActions/doctorAction";
import Loader from "../subComponents/Loader";
import { getCities } from "../../actions/userActions/cityAction";
import Search from "../subComponents/search/Search";
import { TextField, Autocomplete } from "@mui/material";
function Doctors() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedCity, setSelectedCity] = useState("1");

  const [page, setPage] = useState(1);

  const [cityFilters, setCityFilters] = useState({
    textSearch: "",
    countryId: "1",
  });
  const cityBody = {
    filter: cityFilters,
    pageNumber: page,
    pageSize: 100,
  };
  const doctorBody = {
    pageNumber: -1,
    pageSize: 0,
    textSearch: "",
    cityId: selectedCity,
  };

  useEffect(() => {
    dispatch(getCities(cityBody))
    
      .then(() => {
        dispatch(getDoctors(doctorBody))
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });

  }, []);

  //   useEffect(() => {
  //   dispatch(getDoctors(doctorBody));
  //   dispatch(getCities(cityBody));
    

  // }, []);


  const hanleChange = (e) => {
    setSelectedCity(e.target.value);
  };

  useEffect(() => {
    dispatch(getDoctors(doctorBody));
  }, [selectedCity]);
  const doctors = useSelector((state) => state.doctors_reducer.doctors);

  const allCities = useSelector((state) => state.cities_reducer.cities);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");
  const filter_doctors =
    doctors !== "isLoading"
      ? doctors.filter((doctor) => {
          return doctor.name.toLowerCase().includes(query.toLowerCase());
        })
      : [];
  console.log("====================================");
  console.log(allCities);
  console.log("====================================");
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-3">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              Customers
            </h2>
          </div>
          <div className="col-md-4">
            <Search
            
              setText={setQuery}
              text={query}
              placeholder="Search Customer"
            />
          </div>
          <div className="col-md-3 mt-1">
          
              {allCities && allCities !== "isLoading" && (
                <Autocomplete
                  id="grouped-demo"
                  name="type"
                  size="small"
                  style={{ maxWidth: "300px"}}
                 
                  onChange={(e, newVal) => {
                    setSelectedCity(newVal?.id ? newVal?.id : 1);
                  }}
                  options={allCities.cities}
                 
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Select City" />
                  )}
                />
              )}
          </div>
        

         
          <div className="col-md-2">
            {permissions.includes("CanAddDoctor") && (
              <Link
                to={"/admin/AddCustomer"}
                className="btn btn-primary float-right"
              >
                Add Customer
              </Link>
            )}
           
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>Name</td>
                  <td>Description</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody className="text-left">
                {doctors === "isLoading" && (
                  <tr>
                    <td
                      colSpan={3}
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {filter_doctors === 'isLoading' &&
                filter_doctors.length === 0 && (
                  <tr className="text-center">
                    <td></td> <td>No data found</td> <td></td>
                  </tr>
                )}
                {filter_doctors !== "isLoading" &&
                  filter_doctors.map((row, i) => (
                    <tr key={i}>
                      <td>{row.name}</td>
                      <td>{row.description}</td>
                      <td>
                        {permissions.includes("CanUpdateDoctor") && (
                          <Link
                            to={{
                              pathname: "/admin/UpdateCustomer",
                              doctors: { row },
                              id: row.id,
                            }}
                          >
                            <i className="fas fa-edit" />
                          </Link>
                        )}{" "}
                        {/* <Link className="ml-2" to={"#"}>
                          <i
                            className="fas fa-trash"
                            style={{ color: "red" }}
                          />
                        </Link> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Doctors;
