import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getCities } from '../../actions/userActions/cityAction'
import Loader from '../subComponents/Loader'
import Modal from '../subComponents/Modal'
import Search from '../subComponents/search/Search'
import Pagination from '../paginatioin/pagination'

function Cities() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)

  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })

  // City Body

  const cityBody = {
    filter: cityFilters,
    pageNumber: page,
    pageSize: 10,
  }

 


  const [selected, setSelected] = useState({
    textSearch: '',
    countryId: '1',
  })
  useEffect(() => {
    dispatch(getCities(cityBody))
  }, [page, cityFilters])

  const cities = useSelector((state) => state.cities_reducer.cities)
  const [query, setQuery] = useState('')
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))

  const getPaginationNo = (pageNum) => {
    const object = []
    if (cities !== 'isLoading' && cities.length !== 0) {
      for (let i = 0; i < cities.page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }
  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 4) {
      setTimeout(() => {
        setCityFilters({ ...cityFilters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setCityFilters({ ...cityFilters, ['textSearch']: e.target.value })
    }
  }
  console.log(cities.page)
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />{' '}
              Cities
            </h2>
          </div>
          <div className="col-md-4">
            <input
              className="search-input"
              style={{
                width: '100%',
                height: '100%',
                paddingLeft: '10px',
                border: '2px solid #ccc',
                borderRadius: '10px',
              }}
              type="text"
              placeholder="Search City"
              onChange={handleSearch}
              value={selected.textSearch}
            />
          </div>
          <div className="col-md-4">
            {permissions.includes('CanAddCity') && (
              <Link
                to={'/admin/AddCities'}
                className="btn btn-primary float-right"
              >
                Add City
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>
                    {' '}
                    <b>Name</b>{' '}
                  </td>
                  <td>
                    {' '}
                    <b>Short Name</b>{' '}
                  </td>
                  <td>
                    {' '}
                    <b>Action</b>{' '}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {cities?.cities && cities?.cities.length == 0 && (
                  <tr className="text-center">
                    <td colSpan={6}>No data found</td>
                  </tr>
                )}
                {cities === 'isLoading' && (
                  <tr>
                    <td
                      colSpan={3}
                      style={{ backgroundColor: '#fff !important' }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {cities !== 'isLoading' &&
                  cities.length !== 0 &&
                  cities.cities.length !== 0 &&
                  cities.cities.map((row, i) => (
                    <tr key={i}>
                      <td>{row.name}</td>
                      <td>{row.shortName}</td>
                      <td>
                        <Link
                          to={{
                            pathname: '/admin/UpdateCities',
                            city: { row },
                            id: row.id,
                          }}
                        >
                          <i className="fas fa-edit" />
                        </Link>{' '}
                        {/* <Link
                          className="ml-2"
                          data-toggle="modal"
                          data-target={`#delete${row.id}`}
                          to={'#'}
                        >
                          <i
                            className="fas fa-trash"
                            style={{ color: 'red' }}
                          />
                        </Link> */}
                        <Modal
                          id={`delete${row.id}`}
                          title={`Confirmation Modal`}
                          btn={
                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{ backgroundColor: 'red', border: '0px' }}
                            >
                              {' '}
                              Confirm
                            </button>
                          }
                          body={`Please confirm that you want to delete ${row.name} city`}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {cities !== 'isLoading' && (
              <div className="task-pagination-wrapper">
                <Pagination
                  total={
                    cities.page != undefined &&
                    cities.page.totalPages * cities.page.pageSize
                  }
                  current={cities.page != undefined && cities.page.pageNumber}
                  pageSize={cities.page != undefined && cities.page.pageSize}
                  onChange={(current) => setPage(current)}
                  showLessItems
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Cities
