import React,{useEffect,useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {addDoctorSpecializations,updateDoctorSpecializations} from '../../actions/userActions/doctorSpecializationAction'
import Loader from '../subComponents/Loader'
import {Redirect,useLocation,useHistory} from 'react-router-dom';
function AddDoctorSpecializations () {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = {name: "", description: ""};
    const [formValues,setFormValues] = useState(initialValues);
    const [formErrors,setFormErrors] = useState({});
    const [isSubmit,setIsSubmit] = useState(false);

    useEffect(()=>{
        {location.doctorSpecialization && setFormValues({...formValues,
            ["name"]:location.doctorSpecialization.row.name,
            ["description"]:location.doctorSpecialization.row.description,
            ["id"]:location.id,
            });
        }
    },[])
    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmit){
            location.doctorSpecialization ? 
            dispatch(updateDoctorSpecializations(formValues))
            :
            dispatch(addDoctorSpecializations(formValues));
        }
    },[formErrors])
    const addDoctorSpecializationHandle = async(e) =>{
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }

    const hanleChange = (e) =>{
        const {name,value} = e.target;
        setFormValues({...formValues,[name]:value});
    }

    const validate = (values) => {
        const errors = {};
        if(!values.name){
            errors.name = "Name is required!"
        }
        if(!values.description){
            errors.description = "Description is required!"
        }
        return errors;
    }
    const addDoctorSpecialization =  useSelector(state => state.doctor_specialization_reducer.addSpecializations);
    const updateDoctorSpecialization =  useSelector(state => state.doctor_specialization_reducer.updateSpecializations);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    return (
        <>
        {addDoctorSpecialization && addDoctorSpecialization !== "isLoading" && isSubmit && <Redirect to="/admin/doctorSpecializations"/>}
        {/* {updateDoctorSpecialization && updateDoctorSpecialization !== "isLoading" && isSubmit && <Redirect to="/admin/doctorSpecializations"/>} */}
        {!location.doctorSpecialization && <Redirect to="/admin/AddDoctorSpecializations"/>}
        {!permissions.includes("CanAddDoctorSpecialization") && <Redirect to="/admin"/>}
        {!permissions.includes("CanUpdateDoctorSpecialization") && <Redirect to="/admin"/>}

            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6">
                    <h2 className='d-inine-block float-left'><i onClick={history.goBack} className="fas fa-arrow-left" aria-hidden="true" style={{cursor:"pointer"}} /> {location.doctorSpecialization ? "Update Doctor Specialization" : "Add Doctor Specialization"}</h2>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label required">NAME</label>
                            <input name='name' type="text" className="form-control"
                            value={formValues.name}  
                            onChange={hanleChange}
                            />
                            <div style={{ color: 'red' }}>{formErrors.name}</div>
                        </div>
                        <div className="form-group">
                            <label className="form-control-label required">Description</label>
                            <input name='description' type="text" className="form-control"
                            value={formValues.description}  
                            onChange={hanleChange}
                            />
                           <div style={{ color: 'red' }}>{formErrors.description}</div>
                        </div>
                        {addDoctorSpecialization === "isLoading" ? 
                                    <div className="col-lg-12 loginbttm">
                                        <Loader style={{color:"red"}}/>
                                    </div>
                                    : 
                        <div className="col-lg-12 loginbttm"> 
                            <div className="col-lg-12 login-btm login-button d-block">
                                <button className="btn btn-outline-primary d-block m-auto"
                                onClick={addDoctorSpecializationHandle}
                                >  {location.doctorSpecialization ? "Update " : "Submit"}</button>
                            </div>
                        </div> }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDoctorSpecializations
