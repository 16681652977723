import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getTaskStatuses } from "../../actions/userActions/taskStatusAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
function TaskStatuses() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getTaskStatuses());
  }, []);
  const taskStatuses = useSelector(
    (state) => state.task_statuses_reducer.taskStatuses
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");
  const filter_taskStatuses =
    taskStatuses !== "isLoading"
      ? taskStatuses.filter((taskStatus) => {
          return taskStatus.name.toLowerCase().includes(query.toLowerCase());
        })
      : [];

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              Task Status
            </h2>
          </div>
          <div className="col-md-4">
            <Search
              setText={setQuery}
              text={query}
              placeholder="Search Status"
            />
          </div>
          <div className="col-md-4">
            {/* {permissions.includes("CanAddTaskStatus") && <Link to={'/admin/addTaskStatuses'} className='btn btn-primary float-right'>Add Status</Link>} */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>
                    {" "}
                    <b>Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Active</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {filter_taskStatuses === 'isLoading' &&
                filter_taskStatuses.length === 0 && (
                  <tr className="text-center">
                    <td></td> <td>No data found</td> <td></td>
                  </tr>
                )}
                {taskStatuses === "isLoading" && (
                  <tr>
                    <td
                      colSpan={6}
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {filter_taskStatuses !== "isLoading" &&
                  filter_taskStatuses.map((row, i) => (
                    <tr key={i}>
                      <td>{row.name}</td>
                      <td>{row.description}</td>
                      <td>{row.active ? "Yes" : "No"}</td>
                      <td>
                        {/* <Link to={{pathname: "/admin/UpdateTaskStatuses", taskStatuses: {row} ,id : row.id }} disabled><i className="fas fa-edit" /></Link> */}
                        {/* <Link className="ml-2" to={"#"}>
                          <i
                            className="fas fa-trash"
                            style={{ color: "red" }}
                          />
                        </Link> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskStatuses;
