import React,{useEffect,useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getCountries} from '../../actions/userActions/countryAction'
import {addCities,updateCities} from '../../actions/userActions/cityAction'
import PreLoader from '../preLoader/PreLoader'
import Loader from '../subComponents/Loader'
import {Redirect,useLocation,useHistory} from 'react-router-dom';
function AddCities() {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = {name: "", shortName: "", countryId: ""};
    const [formValues,setFormValues] = useState(initialValues);
    const [formErrors,setFormErrors] = useState({});
    const [isSubmit,setIsSubmit] = useState(false);
    
    useEffect(() =>{
        dispatch(getCountries());
        {location.city && setFormValues({...formValues,
            ["name"]:location.city.row.name,
            ["shortName"]:location.city.row.shortName,
            ["countryId"]:location.city.row.countryId,
            ["id"]:location.id
            });
        }
    },[])

    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmit){
            location.city ?   
            dispatch(updateCities(formValues))
            : 
            dispatch(addCities(formValues)) 
        }
    },[formErrors])

    const addSubmitHandle = async(e) =>{
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }

    const hanleChange = (e) =>{
        const {name,value} = e.target;
        setFormValues({...formValues,[name]:value});
    }

    const validate = (values) => {
        const errors = {};
        if(!values.name){
            errors.name = "Name is required!"
        }
        if(!values.shortName){
            errors.shortName = "Short Name is required!"
        }
        if(values.countryId === ''){
            errors.countryId = "Country is required!"
        }
        return errors;
    }

    const countries =  useSelector(state => state.countries_reducer.countries);
    const addCity =  useSelector(state => state.cities_reducer.addCity);
    const updateCity =  useSelector(state => state.cities_reducer.updateCity);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    return (
        <>
        {addCity && addCity != "isLoading" && isSubmit && <Redirect to="/admin/cities"/>}
        {!location.city  && <Redirect to="/admin/AddCities"/>}
        {!permissions.includes("CanAddCity") && <Redirect to="/admin"/>}
        {!permissions.includes("CanUpdateCity") && <Redirect to="/admin"/>}

        {/* {countries === "isLoading" && <PreLoader/> } */}
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6">
                    <h2 className='d-inine-block float-left'><i onClick={history.goBack} className="fas fa-arrow-left" aria-hidden="true" style={{cursor:"pointer"}} /> {location.city ? "Update City" : "Add City" }</h2>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label required">NAME</label>
                            <input name='name' type="text" className="form-control"
                            value={formValues.name}  
                            onChange={hanleChange}
                            />
                            <div style={{ color: 'red' }}>{formErrors.name}</div>
                        </div>
                        <div className="form-group">
                            <label className="form-control-label required">Short Name</label>
                            <input name='shortName' type="text" className="form-control"
                            value={formValues.shortName}  
                            onChange={hanleChange}
                            />
                            <div style={{ color: 'red' }}>{formErrors.shortName}</div>
                        </div>
                        <div className="form-group">
                            <label className="form-control-label required">Select group</label>
                            <select name='countryId'  className="form-control" style={{height:"45px"}} value={formValues.countryId} onChange={hanleChange}>
                                <option value="" disabled> Select Group</option>
                                {countries !== "isLoading" && countries.map((row, i) => (
                                    <option key={i} value={row.id}  >{row.name}</option>
                                ))}
                            </select>
                            <div style={{ color: 'red' }}>{formErrors.countryId}</div>
                        </div>
                        {addCity === "isLoading" || updateCity === "isLoading" ? 
                                    <div className="col-lg-12 loginbttm">
                                        <Loader/>
                                    </div>
                                    : 
                        <div className="col-lg-12 loginbttm"> 
                            <div className="col-lg-12 login-btm login-button d-block">
                               <button className="btn btn-outline-primary d-block m-auto"
                                onClick={addSubmitHandle}
                                >
                                    {location.city ?  "Update" : "Submit"}
                                </button> 
                            </div>
                        </div> }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCities
