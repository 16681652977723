import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addEmployees,
  updateEmployees,
  getEmployee,
} from "../../actions/userActions/employeeAction";
import { getCities } from "../../actions/userActions/cityAction";
import { getDesignations } from "../../actions/userActions/designationAction";
import { getOfficeLocations } from "../../actions/userActions/officelocationAction";
import { getDepartments } from "../../actions/userActions/departmentAction";
import { getTeams } from "../../actions/userActions/teamAction";
import { getEmploymentStatuses } from "../../actions/userActions/employmentStatusAction";
import { getRoles } from "../../actions/userActions/roleAction";
import {
  getEmployees,
  getGenders,
} from "../../actions/userActions/employeeAction";
import Loader from "../subComponents/Loader";
import InputMask from "react-input-mask";
import Autocomplete from "@mui/material/Autocomplete";
// import Select from 'react-select'
import DatePicker from "react-datepicker";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import zIndex from "@mui/material/styles/zIndex";

function AddEmployees() {
  const [loadingData, setLoadingData] = useState(true);
  const currentDate = new Date();
  const eighteenYearsAgo = new Date(
    currentDate.getFullYear() - 18,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const [page, setPage] = useState(1);

  const [Filters, setCityFilters] = useState({
    textSearch: "",
    countryId: "1",
  });
  const body = {
    filter: Filters,
    pageNumber: page,
    pageSize: 50,
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [joinDate, setJoinDate] = useState(new Date());
  const [birthDate, setBirthDate] = useState(eighteenYearsAgo);
  const initialValues = {
    firstName: "",
    lastName: "",
    gender: "",
    dateOfJoining: new Date(),
    dateOfBirth: eighteenYearsAgo,
    nic: "",
    passport: "",
    address: "",
    city: "",
    personalContact1: "",
    personalContact2: "",
    personalEmail: "",
    designation: "",
    jobLocation: "",
    officialEmail: "",
    officialContact1: "",
    officialContact2: "",
    department: "",
    reportsTo: "",
    team: "",
    employmentStatus: "",
    password: "",
    employeeRoles: [],
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    if (location.id) {
      dispatch(getEmployee(location.id));
    }
    dispatch(getCities(body))
      .then(() => {
        dispatch(getDesignations(body)),
          dispatch(getOfficeLocations(body)),
          dispatch(getDepartments()),
          dispatch(getTeams()),
          dispatch(getEmploymentStatuses()),
          dispatch(getRoles()),
          dispatch(getEmployees()),
          dispatch(getGenders()),
          setLoadingData(false)
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setLoadingData(false);
      });

  }, [dispatch, location.id]);

  const employee = useSelector((state) => state.employees_reducer.employee);

  const initialSelectedValues = {
    firstName:
      employee && employee !== "isLoading" && location.id && employee.firstName
        ? { label: "loading...", value: "" }
        : "",
    lastName:
      employee && employee !== "isLoading" && location.id && employee.lastName
        ? { label: "loading...", value: "" }
        : "",
    cnic:
      employee && employee !== "isLoading" && location.id && employee.cnic
        ? { label: "loading...", value: "" }
        : "",
    gender:
      employee && employee != "isLoading" && location.id && employee.gender
        ? { label: "loading...", value: "" }
        : "",
    city:
      employee && employee != "isLoading" && location.id && employee.city
        ? { label: "loading...", value: "" }
        : "",
    designation:
      employee && employee != "isLoading" && location.id && employee.designation
        ? { label: "loading...", value: "" }
        : "",
    jobLocation:
      employee &&
        employee != "isLoading" &&
        location.id &&
        employee.jobLocation
        ? { label: "loading...", value: "" }
        : "",
    department:
      employee && employee != "isLoading" && location.id && employee.department
        ? { label: "loading...", value: "" }
        : "",
    reportsTo:
      employee && employee != "isLoading" && location.id && employee.reportsTo
        ? { label: "loading...", value: "" }
        : "",
    team:
      employee && employee != "isLoading" && location.id && employee.team
        ? { label: "loading...", value: "" }
        : "",
    employmentStatus:
      employee &&
        employee != "isLoading" &&
        location.id &&
        employee.employmentStatus
        ? { label: "loading...", value: "" }
        : "",
    employeeRoles:
      employee &&
        employee !== "isLoading" &&
        location.id &&
        employee.employeeRoles
        ? { label: "loading...", value: "" }
        : "",
  };
  const [selected, setSelected] = useState(initialSelectedValues);
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      {
        employee
          ? dispatch(updateEmployees(formValues))
          : dispatch(addEmployees(formValues));
      }
    }
  }, [formErrors]);

  const addEmployeeHandle = async (e) => {
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const hanleChange = (e) => {
    const { name, value } = e.target ? e.target : e;
    setFormValues({ ...formValues, [name]: value });
  };
  const hanleJoinDateChange = (e) => {
    setJoinDate(e);
    setFormValues({
      ...formValues,
      ["dateOfJoining"]: JSON.stringify(e).replace(/['"]+/g, ""),
    });
  };
  const hanleBirthDateChange = (e) => {
    setBirthDate(e);
    setFormValues({
      ...formValues,
      ["dateOfBirth"]: JSON.stringify(e).replace(/['"]+/g, ""),
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = <p className="text-red">First Name is required!</p>;
    }
    if (!values.lastName) {
      errors.lastName = <p className="text-red">Last Name is required! </p>;
    }
    if (!values.nic) {
      errors.nic = <p className="text-red">CNIC is required! </p>;
    } else if (values.nic.replace(/[_\s]/g, "").length != 15) {
      errors.nic = <p className="text-red">CNIC is Invalid! ex: 11111-1111111-1 </p>;
    }
    if (!values.address) {
      errors.address = <p className="text-red">Address is required!</p>;
    }
    if (!values.personalContact1) {
      errors.personalContact1 = (
        <p className="text-red">Contact is required!</p>
      );
    } else if (values.personalContact1.length !== 11) {
      errors.personalContact1 = <p className="text-red">Contact is Invalid!</p>;
    }
    if (values.personalContact2 && values.personalContact2.length !== 11) {
      errors.personalContact2 = <p className="text-red">Contact is Invalid!</p>;
    }
    if (!values.personalEmail) {
      errors.personalEmail = <p className="text-red">Email is required!</p>;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.personalEmail)
    ) {
      errors.personalEmail = (
        <p className="text-red">Please enter valid email!</p>
      );
    }
    if (!values.officialContact1) {
      errors.officialContact1 = (
        <p className="text-red">Official Contact is required!</p>
      );
    } else if (values.officialContact1.length !== 11) {
      errors.officialContact1 = <p className="text-red">Contact is Invalid!</p>;
    }
    if (values.officialContact2 && values.officialContact2.length !== 11) {
      errors.officialContact2 = <p className="text-red">Contact is Invalid!</p>;
    }
    if (!values.officialEmail) {
      errors.officialEmail = <p className="text-red">Email is required!</p>;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.officialEmail)
    ) {
      errors.officialEmail = (
        <p className="text-red">Please enter valid email!</p>
      );
    }
    if (values.gender === "") {
      errors.gender = <p className="text-red">Gender is required!</p>;
    }
    if (values.department === "") {
      errors.department = <p className="text-red">Department is required!</p>;
    }
    // if (values.reportsTo === "") {
    //     errors.reportsTo = "Report is required!"
    // }
    if (values.team === "") {
      errors.team = <p className="text-red">Team is required!</p>;
    }
    if (values.employmentStatus === "") {
      errors.employmentStatus = (
        <p className="text-red">Employment Status is required!</p>
      );
    }
    if (values.city === "") {
      errors.city = <p className="text-red">City is required!</p>;
    }
    if (values.employeeRoles.length === 0) {
      errors.employeeRoles = (
        <p className="text-red">Employee Role is required!</p>
      );
    }
    if (values.designation.length === 0) {
      errors.designation = <p className="text-red">Designation is required!</p>;
    }
    if (values.reportsTo.length === 0) {
      errors.reportsTo = <p className="text-red">Report to is required!</p>;
    }
    if (values.jobLocation.length === 0) {
      errors.jobLocationName = (
        <p className="text-red">Job location is required!</p>
      );
    }
    if (!values.dateOfBirth) {
      errors.dateOfBirth = (
        <p className="text-red">Date of Birth is required!</p>
      );
    } else {
      const dob = new Date(values.dateOfBirth);
      if (dob > eighteenYearsAgo) {
        errors.dateOfBirth = (
          <p className="text-red">
            Date of Birth should be at least 18 years ago!
          </p>
        );
      }
    }
    return errors;
  };

  const cities = useSelector((state) => state.cities_reducer.cities);

  const designations = useSelector(
    (state) => state.designations_reducer.designations
  );
  const officeLocations = useSelector(
    (state) => state.officeLocations_reducer.officeLocations
  );
  const departments = useSelector(
    (state) => state.departments_reducer.departments
  );
  const teams = useSelector((state) => state.teams_reducer.teams);
  const employmentStatuses = useSelector(
    (state) => state.employmentStatuses_reducer.employmentStatuses
  );
  const roles = useSelector((state) => state.roles_reducer.roles);
  const employees = useSelector((state) => state.employees_reducer.employees);
  const genders = useSelector((state) => state.employees_reducer.genders);
  const addEmployee = useSelector(
    (state) => state.employees_reducer.addEmployee
  );
  const updateEmployee = useSelector(
    (state) => state.employees_reducer.updateEmployee
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const getOptions = (data, name) => {
    const getData =
      data && data !== "isLoading"
        ? data.map((row, i) => ({
          value: row.id,
          label: row.name
            ? row.name
            : row.firstName && row.lastName
              ? `${row.firstName} ${row.lastName}`
              : row.address,
          name: name,
        }))
        : [];
    return getData;
  };

  useEffect(() => {
    if (!loadingData && location.id) {
      employee &&
        employee !== "isLoading" &&
        setSelected({
          ...selected,
          ["gender"]: getOptions(genders, "gender")[
            getOptions(genders, "gender")
              .map((e) => e.value)
              .indexOf(employee.gender)
          ],
          ["city"]: getOptions(cities.cities, "city")[
            getOptions(cities.cities, "city")
              .map((e) => e.value)
              .indexOf(employee.city)
          ],
          ["designation"]: getOptions(designations.designations, "designation")[
            getOptions(designations.designations, "designation")
              .map((e) => e.value)
              .indexOf(employee.designation)
          ],
          ["jobLocation"]: getOptions(
            officeLocations.officeLocations,
            "jobLocation"
          )[
            getOptions(officeLocations.officeLocations, "jobLocation")
              .map((e) => e.value)
              .indexOf(employee.jobLocation)
          ],
          ["department"]: getOptions(departments, "department")[
            getOptions(departments, "department")
              .map((e) => e.value)
              .indexOf(employee.department)
          ],
          ["team"]: getOptions(teams, "team")[
            getOptions(teams, "team")
              .map((e) => e.value)
              .indexOf(employee.team)
          ],
          ["reportsTo"]: getOptions(employees, "reportsTo")[
            getOptions(employees, "reportsTo")
              .map((e) => e.value)
              .indexOf(employee.reportsTo)
          ],
          ["employmentStatus"]: getOptions(
            employmentStatuses,
            "employmentStatus"
          )[
            getOptions(employmentStatuses, "employmentStatus")
              .map((e) => e.value)
              .indexOf(employee.employmentStatus)
          ],
          ["employeeRoles"]: getOptions(roles, "employeeRoles")[
            getOptions(roles, "employeeRoles")
              .map((e) => e.value)
              .indexOf(
                employee.employeeRoles[0] && employee.employeeRoles[0].roleId
              )
          ],

        });
      {
        employee &&
          employee !== "isLoading" &&
          setFormValues({
            ...formValues,
            ["firstName"]: employee.firstName,
            ["lastName"]: employee.lastName,
            ["gender"]: employee.gender,
            ["dateOfJoining"]: employee.dateOfJoining,
            ["dateOfBirth"]: employee.dateOfBirth,
            ["nic"]: employee.nic,
            ["passport"]: employee.passport,
            ["address"]: employee.address,
            ["city"]: employee.city,
            ["personalContact1"]: employee.personalContact1,
            ["personalContact2"]: employee.personalContact2,
            ["personalEmail"]: employee.personalEmail,
            ["designation"]: employee.designation,
            ["jobLocation"]: employee.jobLocation,
            ["officialEmail"]: employee.officialEmail,
            ["officialContact1"]: employee.officialContact1,
            ["officialContact2"]: employee.officialContact2,
            ["department"]: employee.department,
            ["reportsTo"]: employee.reportsTo,
            ["team"]: employee.team,
            ["employmentStatus"]: employee.employmentStatus,
            ["password"]: employee.password,
            ["employeeRoles"]: employee.employeeRoles,
            ["id"]: location.id,
          });

      }
      {
        employee &&
          employee !== "isLoading" &&
          setJoinDate(new Date(employee.dateOfJoining));
      }
      {
        employee &&
          employee !== "isLoading" &&
          setBirthDate(new Date(employee.dateOfBirth));
      }
    }
  }, [
    loadingData,
    location.id,
    employee,
    genders,
    cities,
    designations,
    officeLocations,
    departments,
    teams,
    employmentStatuses,
   roles
  ]);

  const handleSelectChange = (e) => {
    const { name, value, label } = e.target;

    if (name === "employeeRoles") {
      setFormValues({
        ...formValues,
        [name]: employee ? [{ roleId: value, employeeId: location.id }] : [{ roleId: value }],
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }

    setSelected({ ...selected, [name]: { value, label } });
  };
  const multiHanleChange = (e) => {
    setFormValues({
      ...formValues,
      ["employeeRoles"]: e.map((row, i) =>
        employee
          ? { roleId: row.value, employeeId: location.id }
          : { roleId: row.value }
      ),
    });
    setSelected({
      ...selected,
      ["employeeRoles"]: e.map((row, i) => ({
        value: row.value,
        label: row.label,
      })),
    });
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <>
      {addEmployee && isSubmit && <Redirect to="/admin/employees" />}
      {!permissions.includes("CanAddEmployee") && <Redirect to="/admin" />}
      {!permissions.includes("CanUpdateEmployee") && <Redirect to="/admin" />}
      {!location.id && <Redirect to={"/admin/Addemployees"} />}
      <div className="container mt-5">
        <div className="row">
          <div
            className="col-md-12"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <i onClick={history.goBack}>
              <svg
                style={{ marginBottom: "8px", cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M20 11.5H7.83L13.42 5.91L12 4.5L4 12.5L12 20.5L13.41 19.09L7.83 13.5H20V11.5Z"
                  fill="#505157"
                />
              </svg>
            </i>{" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2 className="d-inline-block">
                {location.employee
                  ? `${formValues.firstName} ${formValues.lastName}`
                  : "Add Employee"}
              </h2>
              <p style={{ fontSize: "14px", marginLeft: "6px" }}>
                View employee details here
              </p>
            </div>
          </div>
        </div>
        <hr
          style={{
            marginLeft: "14px",
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            height: "1.3px",
            marginTop: "15px",
            width: "94%",
          }}
        />
        <br />

        <div className="row">
          <div className="col-12">
            <h2>Personal Details</h2>
          </div>
        </div>

        <hr
          style={{
            marginLeft: "14px",
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            height: "1.3px",
            marginTop: "15px",
            width: "94%",
          }}
        />
        <br />
        {/* {employee === "isLoading" && (
          <div colSpan={6} style={{ backgroundColor: "#fff !important" }}>
            <Loader colored={true} />
          </div>
        )} */}
        {/* {employee !== "isLoading" &&
          employee != undefined &&
          employee.length !== 0 && ( */}
        <div className="row">
          <div className="col-md-4">
            <TextField
              className="first_textfield"
              style={{ width: "100%" }}
              id="outlined-controlled"
              label="First Name *"
              name="firstName"
              value={formValues.firstName}
              onChange={hanleChange}
            />
            {formErrors.firstName && <div>{formErrors.firstName}</div>}
          </div>
          <div className="col-md-4">
            <TextField
              className="first_textfield"
              style={{ width: "100%" }}
              id="outlined-controlled"
              label="Last Name *"
              name="lastName"
              value={formValues.lastName}
              onChange={hanleChange}
            />
            {formErrors.lastName && <div>{formErrors.lastName}</div>}
          </div>
          <div className="col-md-4"
            style={{ marginTop: "-22px" }}
          >

            <InputLabel>Date Of Birth *</InputLabel>
            <DatePicker
              style={{ width: "100 !important%", zIndex: "12345" }}
              name="dateOfBirth"
              className="form-control"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="MMMM d, yyyy "
              maxDate={eighteenYearsAgo}
              selected={birthDate}
              onSelect={hanleBirthDateChange}
              placeholderText="Date Of Birth"
            />
            {formErrors.dateOfBirth && <div>{formErrors.dateOfBirth}</div>}
          </div>
          <div className="row mt-10">
            <div className="col-md-4 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Gender
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.gender}
                  onChange={handleSelectChange}
                  name="gender"
                >
                  {getOptions(genders, "gender").map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formErrors.gender && <div>{formErrors.gender}</div>}
            </div>

            <div className="col-md-4 mt-5">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="NIC *"
                name="nic"
                value={formValues.nic}
                onChange={hanleChange}
              />
              {formErrors.nic && <div>{formErrors.nic}</div>}
            </div>

            <div className="col-md-4 mt-5">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Passport No *"
                name="passport"
                value={formValues.passport}
                onChange={hanleChange}
              />
              {formErrors.passport && <div>{formErrors.passport}</div>}
            </div>
          </div>

          <div className="row mt-10">
            <div className="col-md-4 mt-5">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Personal Email *"
                name="personalEmail"
                value={formValues.personalEmail}
                onChange={hanleChange}
              />
              {formErrors.personalEmail && <div>{formErrors.personalEmail}</div>}
            </div>
            <div className="col-md-4 mt-5">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Personal Contact 1 *"
                name="personalContact1"
                value={formValues.personalContact1}
                onChange={hanleChange}
              />
              {formErrors.personalContact1 && <div>{formErrors.personalContact1}</div>}
            </div>

            <div className="col-md-4 mt-5">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Personal Contact 2 *"
                name="personalContact2"
                value={formValues.personalContact2}
                onChange={hanleChange}
              />
              {formErrors.personalContact2 && <div>{formErrors.personalContact2}</div>}
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">City</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.city}
                  onChange={handleSelectChange}
                  name="city"
                >
                  {getOptions(cities.cities, "city").map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formErrors.city && <div>{formErrors.city}</div>}
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Address *"
                name="address"
                value={formValues.address}
                onChange={hanleChange}
              />
              {formErrors.address && <div>{formErrors.address}</div>}

            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12">
              <h2>Official Details</h2>
            </div>
          </div>

          <hr
            style={{
              marginLeft: "14px",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              height: "1.3px",
              marginTop: "15px",
              width: "94%",
            }}
          />

          <div className="row mt-5">
            <div className="col-md-4 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Department
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.department}
                  name="department"
                  onChange={handleSelectChange}
                >
                  {getOptions(departments, "department").map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formErrors.department && <div>{formErrors.department}</div>}
            </div>

            <div className="col-md-4 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Designation
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.designation}
                  name="designation"
                  onChange={handleSelectChange}
                >
                  {getOptions(designations.designations, "designation").map(
                    (option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              {formErrors.designation && (
                <div>{formErrors.designation}</div>
              )}
            </div>

            <div className="col-md-4 mt-5">

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Role
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.employeeRoles.map(role => role.roleId)}
                  name="employeeRoles"
                  onChange={handleSelectChange}
                >
                  {getOptions(roles, "employeeRoles").map(
                    (option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}

                </Select>
              </FormControl>
              {formErrors.employeeRoles && <div>{formErrors.employeeRoles}</div>}

            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Team</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.team}
                  name="team"
                  onChange={handleSelectChange}
                >
                  {getOptions(teams, "team").map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formErrors.team && <div>{formErrors.team}</div>}
            </div>

            <div className="col-md-6 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Reports To
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.reportsTo}
                  name="reportsTo"
                  onChange={handleSelectChange}
                >
                  {getOptions(employees, "reportsTo").map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formErrors.reportsTo && <div>{formErrors.reportsTo}</div>}
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              <InputLabel htmlFor="dateOfJoining">
                Date Of Joining *
              </InputLabel>
              <DatePicker
                style={{ width: "100 !important%,", zIndex: "33443" }}
                name="dateOfJoining"
                className="form-control"
                dateFormat="MMMM d, yyyy"
                label="Date Of Joining"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={joinDate}
                onSelect={hanleJoinDateChange}
                placeholderText="Select Joining Date"
              />
              {formErrors.dateOfJoining && <div>{formErrors.dateOfJoining}</div>}
            </div>

            <div className="col-md-6 mt-5">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Employment Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.employmentStatus}
                  name="employmentStatus"
                  onChange={handleSelectChange}
                >
                  {getOptions(employmentStatuses, "employmentStatus").map(
                    (option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              {formErrors.employmentStatus && <div>{formErrors.employmentStatus}</div>}
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-4 mt-5">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Official Email *"
                name="officialEmail"
                value={formValues.officialEmail}
                onChange={hanleChange}
              />
              {formErrors.officialEmail && <div>{formErrors.officialEmail}</div>}
            </div>
            <div className="col-md-4 mt-5">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Official Contact 1 *"
                name="officialContact1"
                value={formValues.officialContact1}
                onChange={hanleChange}
              />
              {formErrors.officialContact1 && <div>{formErrors.officialContact1}</div>}
            </div>

            <div className="col-md-4 mt-5">
              <TextField
                style={{ width: "100%" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Official Contact 2 *"
                name="officialContact2"
                value={formValues.officialContact2}
                onChange={hanleChange}
              />
              {formErrors.officialContact2 && <div>{formErrors.officialContact2}</div>}
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Job Location
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.jobLocation}
                  name="jobLocation"
                  onChange={handleSelectChange}
                >
                  {getOptions(officeLocations.officeLocations, "jobLocation").map(
                    (option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              {formErrors.jobLocation && <div>{formErrors.jobLocation}</div>}
            </div>
          </div>
          {addEmployee === "isLoading" || updateEmployee === "isLoading" ? (
            <div
              className="col-lg-12 loginbttm"
              style={{ marginTop: "70px" }}
            >
              <Loader />
            </div>
          ) : (
            <div
              className="col-lg-12 mt-10 mb-5"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <button
                className="btn btn-secondary "
                onClick={history.goBack}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={addEmployeeHandle}
              >
                {location.employee ? "Update" : "Submit"}
              </button>
            </div>
            // <div className="col-lg-12 loginbttm">
            //   <div className="col-lg-12 login-btm login-button d-block">
            //     <button
            //       className="btn btn-outline-primary d-block m-auto"
            //       onClick={addEmployeeHandle}
            //     >
            //       {location.employee ? 'Update' : 'Submit'}
            //     </button>
            //   </div>
            // </div>
          )}
        </div>
        {/* )} */}
      </div>
    </>
  );
}

export default AddEmployees;
