import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllPermissions,
  getAllPermissionsGroup,
} from "../../actions/userActions/permissionsAction";
import Loader from "../subComponents/Loader";
import { Link, useHistory } from "react-router-dom";
import Search from "../subComponents/search/Search";
import Pagination from "../paginatioin/pagination";
import "./Permissions.css";

function Permissions() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  const [Filters, setFilters] = useState({
    textSearch: "",
    permissionGroup: [1],
  });
  const [selected, setSelected] = useState({
    textSearch: "",
    isActive: true,
  });
  const body = {
    filter: Filters,
    pageNumber: page,
    pageSize: 10,
  };

  useEffect(() => {
    dispatch(getAllPermissionsGroup());
  }, [page, Filters]);

  const allPermissionsGroup = useSelector(
    (state) => state.permissions_reducer.allPermissionsGroup
  );

  const handleSearch = (e) => {
    setSelected({ ...selected, ["textSearch"]: e.target.value });
    if (e.target.value.length >= 4) {
      setTimeout(() => {
        setFilters({ ...Filters, ["textSearch"]: e.target.value });
      }, 500);
    }
    if (e.target.value == "") {
      setFilters({ ...Filters, ["textSearch"]: e.target.value });
    }
  };
  const [query, setQuery] = useState("");
  const getPaginationNo = (pageNum) => {
    const object = [];
    if (
      allPermissionsGroup !== "isLoading" &&
      allPermissionsGroup.length !== 0
    ) {
      for (let i = 0; i < allPermissionsGroup.page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? "active" : ""}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>
        );
      }
    }
    return object;
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-5">
            <h2 className="d-inline-block">Permission Groups</h2>
            <p style={{ fontSize: "14px" }}>
              Manage all your Permissions here{" "}
            </p>
          </div>
          <div className="col-md-4">
            <input
              className="search-input manage_input"
              style={{}}
              type="text"
              placeholder="Search Permission"
              onChange={handleSearch}
              value={selected.textSearch}
            />
          </div>

          <div className="col-md-3">
            {permissions.includes('CanAddPermission') && (
              <Link
                to={'/admin/AddPermissions'}
                className="btn btn-primary float-right"
              >
                + Add Permission Group
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Status</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Actions</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {allPermissionsGroup != undefined &&
                  allPermissionsGroup.length == 0 && (
                    <tr className="text-center">
                      <td colSpan={6}>No data found</td>
                    </tr>
                  )}
                {allPermissionsGroup === "isLoading" && (
                  <tr>
                    <td
                      colSpan={6}
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {allPermissionsGroup != undefined &&
                  allPermissionsGroup !== "isLoading" &&
                  allPermissionsGroup.length !== 0 &&
                  allPermissionsGroup.map((row, i) => (
                    <tr
                      key={i}
                      style={{
                        backgroundColor: "transparent",
                        "--x-table-accent-bg": "transparent",
                      }}
                    >
                      <td>{row.name}</td>
                      <td>{row.description}</td>
                      <td>
                        <span
                          style={{
                            color: row.active
                              ? "#2196F3"
                              : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                            borderRadius: "100px",
                            border: `1px solid ${
                              row.active
                                ? "var(--primary-main, #2196F3)"
                                : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                            }`,
                            padding: "3px 10px",
                          }}
                        >
                          {row.active ? "Active" : "In-active"}
                        </span>
                      </td>

                      <td>
  <div style={{ display: "flex", gap: "15px" }}>
    <Link
      to={{
        pathname: "/admin/ViewPermissions",
        state: { rowData: row },
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
          fill="#505157"
        />
      </svg>
    </Link>{" "}


    
   {permissions.includes('CanUpdatePermission') && (
    <Link
    to={{
      pathname: "/admin/UpdatePermissions",
      permission: { row },
      id: row.id,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z"
        fill="#505157"
      />
    </svg>
  </Link>
   )}
    
  </div>
</td>


                      
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {allPermissionsGroup !== "isLoading" && (
          <div className="task-pagination-wrapper">
            <Pagination
              total={
                allPermissionsGroup.page != undefined &&
                allPermissionsGroup.page.totalPages *
                  allPermissionsGroup.page.pageSize
              }
              current={
                allPermissionsGroup.page != undefined &&
                allPermissionsGroup.page.pageNumber
              }
              pageSize={
                allPermissionsGroup.page != undefined &&
                allPermissionsGroup.page.pageSize
              }
              onChange={(current) => setPage(current)}
              showLessItems
            />
          </div>
        )}
       
      </div>
    </>
  );
}

export default Permissions;
