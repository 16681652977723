import React from 'react';

function Modal(props){
  return <div>
<div>
  {/* Button trigger modal */}
  {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target={`#${props.id}`}>
    Launch demo modal
  </button> */}
  {/* Modal */}
  <div className="modal fade" id={props.id} tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle">{props.title}</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          {props.body}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          {/* <button type="button" className="btn btn-primary">Save changes</button> */}
          {props.btn}
        </div>
      </div>
    </div>
  </div>
</div>


  </div>;
}

export default Modal;
