import React from "react";
import "./TaskTarget.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTaskTarget } from "../../actions/userActions/tasks/taskTargetAction";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { getSubOrdinates } from "../../actions/userActions/tasks/taskAction";
import Loader from "../subComponents/Loader";

const TaskTarget = () => {
  const dispatch = useDispatch();
  const currentDate = moment();

  const [selectedTab, setSelectedTab] = useState("MY TASKS");

  const [subOrdinates, setSubOrdinates] = useState(false);
  const [title, setTitle] = useState("MY TASKS");
  const [showMyTask, setShowMyTask] = useState(true);
  const [filters, setFilters] = useState({
    taskFromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
    taskToDate: moment(currentDate).format("YYYY-MM-DD"),
  });
  const [showDetailsForRow, setShowDetailsForRow] = useState({});

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (auth.isLoggedIn === false && !user) {
      return <Redirect to="/" />;
    }
  });

  const handleDetails = (empId) => {
    const body = {
      startDate: filters.taskFromDate,
      endDate: filters.taskToDate,
      empId: empId,
    };

    setShowDetailsForRow((prevState) => ({
      ...prevState,
      [empId]: !prevState[empId],
    }));

    dispatch(getTaskTarget(body));
  };
  const id = user.result[0].user.id;

  const MyTasksChange = () => {
    setSubOrdinates(false);
    setTitle("MY TASKS");
    setSelectedTab("MY TASKS");
    setShowMyTask(true);
  };
  const SubOrdinatesChange = () => {
    setSubOrdinates(true);
    setTitle("SubOrdinates");
    setSelectedTab("SubOrdinates");
    setShowMyTask(false);
  };
  const dateChange = (start, end) => {
    let startDate = start.format("YYYY-MM-DD");
    let endDate = end.format("YYYY-MM-DD");
    setFilters({
      ...filters,
      ["taskFromDate"]: startDate,
      ["taskToDate"]: endDate,
    });
  };

  const tasksTarget = useSelector((state) => state.tasks_target_reducer);
  const auth = useSelector((state) => state.auth);
  const subOrdinate = useSelector((state) => state.tasks_reducer.subOrdinates);

  const body = {
    startDate: filters.taskFromDate,
    endDate: filters.taskToDate,
    empId: user.result[0].user.id,
  };

  useEffect(() => {
    dispatch(getTaskTarget(body))
      .then(() => {
        dispatch(getSubOrdinates(id));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [filters]);

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-9">
            <h2 className="">Task Targets</h2>
            <p>
              You can see your task targets as well as your team members
            </p>{" "}
            <br />
            <ul className="nav nav-tabs mt-4 overflow-x border-0">
              <li className="nav-item">
                <p
                  style={{
                    cursor: "pointer",
                    color: selectedTab === "MY TASKS" ? "blue" : "black",
                  }}
                  className={
                    selectedTab === "MY TASKS" ? "nav-link active" : "nav-link"
                  }
                  onClick={MyTasksChange}
                >
                  My Tasks
                </p>
              </li>
              <li className="nav-item">
                <p
                  style={{
                    cursor: "pointer",
                    color: selectedTab === "SubOrdinates" ? "blue" : "black",
                  }}
                  className={
                    selectedTab === "SubOrdinates"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={SubOrdinatesChange}
                >
                  Sub Ordinates
                </p>
              </li>
            </ul>
          </div>

          <div className="col-md-3 mt-3">
            <DateRangePicker
              initialSettings={{
                startDate: moment().subtract(6, "days").toDate(),
                endDate: moment().toDate(),

                ranges: {
                  Today: [moment().toDate(), moment().toDate()],
                  Yesterday: [
                    moment().subtract(1, "days").toDate(),
                    moment().subtract(1, "days").toDate(),
                  ],
                  "Last 7 Days": [
                    moment().subtract(6, "days").toDate(),
                    moment().toDate(),
                  ],
                  "Last 30 Days": [
                    moment().subtract(29, "days").toDate(),
                    moment().toDate(),
                  ],
                  "This Month": [
                    moment().startOf("month").toDate(),
                    moment().endOf("month").toDate(),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month").toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                  ],
                  "Last Year": [
                    moment().subtract(1, "year").startOf("year").toDate(),
                    moment().subtract(1, "year").endOf("year").toDate(),
                  ],
                },
              }}
              onCallback={dateChange}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Select Date Range"
              />
            </DateRangePicker>
          </div>
        </div>

        {showMyTask && selectedTab === "MY TASKS" && (
          <>
            {tasksTarget.tasksTarget === "isLoading" &&
              tasksTarget !== undefined && (
                <div style={{ backgroundColor: "#fff !important" }}>
                  <Loader colored={true} />
                </div>
              )}
            {tasksTarget.tasksTarget !== "isLoading" &&
              tasksTarget !== undefined && (
                <div>
                  <div className="row mt-5">
                    <div className="col-12 all_boxes">
                      <div className="first_box p-3">
                        <h5 style={{ color: "white" }}>
                          {" "}
                          {tasksTarget.tasksTarget.createdTask} <br /> Created
                        </h5>
                      </div>
                      <div className="second_box p-3">
                        <h5 style={{ color: "white" }}>
                          {" "}
                          {tasksTarget.tasksTarget.completedTask} <br />{" "}
                          Completed
                        </h5>
                      </div>
                      <div className="third_box p-3">
                        <h5 style={{ color: "white" }}>
                          {" "}
                          {tasksTarget.tasksTarget.inProgressTask} <br />{" "}
                          In-Progress
                        </h5>
                      </div>

                      <div className="five_box p-3">
                        <h5 style={{ color: "white" }}>
                          {" "}
                          {tasksTarget.tasksTarget.inReviewTask} <br />{" "}
                          In-Review
                        </h5>
                      </div>
                      <div className="six_box p-3">
                        <h5 style={{ color: "white" }}>
                          {" "}
                          {tasksTarget.tasksTarget.approvedTask} <br /> Approved
                        </h5>
                      </div>
                      <div className="seven_box p-3">
                        <h5 style={{ color: "white" }}>
                          {" "}
                          {tasksTarget.tasksTarget.rejctedTask} <br /> Rejected
                        </h5>
                      </div>
                      <div className="nine_box p-3">
                        <h5 style={{ color: "white" }}>
                          {" "}
                          {tasksTarget.tasksTarget.totalTask} <br /> Total-Task
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12 all_boxes">
                      <div className="eight_box p-3">
                        <h5 style={{ color: "white" }}>
                          {" "}
                          {tasksTarget.tasksTarget.onHoldTask} <br /> On-Hold
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </>
        )}

        {!showMyTask && selectedTab === "SubOrdinates" && (
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive mt-4">
                  <table className="table table-hover table-nowrap">
                    <thead className="">
                      <tr>
                        <th scope="col" className="task-table-header">
                          Name
                        </th>
                        <th scope="col" className="task-table-header">
                          Designation
                        </th>
                        <th scope="col" className="task-table-header">
                          Id
                        </th>
                        <th scope="col" className="task-table-header">
                          View Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subOrdinate && subOrdinate.length === 0 && (
                        <tr className="text-center">
                          <td colSpan={4}>No data found</td>
                        </tr>
                      )}
                      {subOrdinate === "isLoading" &&
                        subOrdinate !== undefined && (
                          <tr>
                            <td
                              colSpan={4}
                              style={{ backgroundColor: "#fff !important" }}
                            >
                              <Loader colored={true} />
                            </td>
                          </tr>
                        )}
                      {subOrdinate !== "isLoading" &&
                        subOrdinate &&
                        subOrdinate.length !== 0 &&
                        subOrdinate.map((row, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>
                                <span>{row.firstName}</span>
                                <p
                                  style={{
                                    color: "#3A3A3A7A",
                                    height: "14px",
                                    width: "146px",
                                    font: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "14.06px",
                                  }}
                                ></p>
                              </td>
                              <td style={{ height: "54px", width: "178.67px" }}>
                                <span>{row.designationName}</span>
                              </td>
                              <td>
                                <span>{row.id}</span>
                              </td>
                              <td>
                                <button
                                  style={{ background: "none" }}
                                  onClick={() => {
                                    const empId = row.id;
                                    handleDetails(empId);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="black"
                                    fillOpacity="0.56"
                                  >
                                    <path d="M16.59 8.29688L12 12.8769L7.41 8.29688L6 9.70687L12 15.7069L18 9.70687L16.59 8.29688Z" />
                                  </svg>
                                </button>
                              </td>
                            </tr>

                            {showDetailsForRow[row.id] && (
                              <>
                                {tasksTarget.tasksTarget === "isLoading" &&
                                  tasksTarget !== undefined && (
                                    <div
                                      style={{
                                        backgroundColor: "#fff !important",
                                      }}
                                    >
                                      <Loader colored={true} />
                                    </div>
                                  )}
                                <tr>
                                  <td colSpan={4}>
                                    {tasksTarget.tasksTarget !== "isLoading" &&
                                      tasksTarget !== undefined && (
                                        <>
                                          <div className="row mt-5">
                                            <div className="col-12 all_boxes">
                                              <div className="first_box p-3">
                                                <h5 style={{ color: "white" }}>
                                                  {" "}
                                                  {
                                                    tasksTarget.tasksTarget
                                                      .createdTask
                                                  }{" "}
                                                  <br /> Created
                                                </h5>
                                              </div>
                                              <div className="second_box p-3">
                                                <h5 style={{ color: "white" }}>
                                                  {" "}
                                                  {
                                                    tasksTarget.tasksTarget
                                                      .completedTask
                                                  }{" "}
                                                  <br /> Completed
                                                </h5>
                                              </div>
                                              <div className="third_box p-3">
                                                <h5 style={{ color: "white" }}>
                                                  {" "}
                                                  {
                                                    tasksTarget.tasksTarget
                                                      .inProgressTask
                                                  }{" "}
                                                  <br /> In-Progress
                                                </h5>
                                              </div>

                                              <div className="five_box p-3">
                                                <h5 style={{ color: "white" }}>
                                                  {" "}
                                                  {
                                                    tasksTarget.tasksTarget
                                                      .inReviewTask
                                                  }{" "}
                                                  <br /> In-Review
                                                </h5>
                                              </div>
                                              <div className="six_box p-3">
                                                <h5 style={{ color: "white" }}>
                                                  {" "}
                                                  {
                                                    tasksTarget.tasksTarget
                                                      .approvedTask
                                                  }{" "}
                                                  <br /> Approved
                                                </h5>
                                              </div>
                                              <div className="seven_box p-3">
                                                <h5 style={{ color: "white" }}>
                                                  {" "}
                                                  {
                                                    tasksTarget.tasksTarget
                                                      .rejctedTask
                                                  }{" "}
                                                  <br /> Rejected
                                                </h5>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="row mt-5">
                                            <div className="col-12 all_boxes">
                                              <div className="nine_box p-3">
                                                <h5 style={{ color: "white" }}>
                                                  {" "}
                                                  {
                                                    tasksTarget.tasksTarget
                                                      .totalTask
                                                  }{" "}
                                                  <br /> Total-Task
                                                </h5>
                                              </div>
                                              <div className="eight_box p-3">
                                                <h5 style={{ color: "white" }}>
                                                  {" "}
                                                  {
                                                    tasksTarget.tasksTarget
                                                      .onHoldTask
                                                  }{" "}
                                                  <br /> On-Hold
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                  </td>
                                </tr>
                              </>
                            )}
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TaskTarget;
