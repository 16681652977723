import React,{useEffect,useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {addDoctorRanks,updateDoctorRanks} from '../../actions/userActions/doctorRankAction'
import Loader from '../subComponents/Loader'
import {Redirect,useLocation,useHistory} from 'react-router-dom';
function AddDoctorRanks () {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = {name: "", description: ""};
    const [formValues,setFormValues] = useState(initialValues);
    const [formErrors,setFormErrors] = useState({});
    const [isSubmit,setIsSubmit] = useState(false);

    useEffect(()=>{
        {location.doctorRank && setFormValues({...formValues,
            ["name"]:location.doctorRank.row.name,
            ["description"]:location.doctorRank.row.description,
            ["id"]:location.id,
            });
        }
    },[])
    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmit){
            location.doctorRank ? 
            dispatch(updateDoctorRanks(formValues))
            :
            dispatch(addDoctorRanks(formValues));
        }
    },[formErrors])
    const addDoctorRankHandle = async(e) =>{
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }

    const hanleChange = (e) =>{
        const {name,value} = e.target;
        setFormValues({...formValues,[name]:value});
    }

    const validate = (values) => {
        const errors = {};
        if(!values.name){
            errors.name = "Name is required!"
        }
        if(!values.description){
            errors.description = "Description is required!"
        }
        return errors;
    }
    const addDoctorRank =  useSelector(state => state.doctor_rank_reducer.addRanks);
    const updateDoctorRank =  useSelector(state => state.doctor_rank_reducer.updateRanks);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    return (
        <>
        {addDoctorRank && addDoctorRank !== "isLoading" && isSubmit && <Redirect to="/admin/customerRanks"/>}
        {!location.doctorRank && <Redirect to="/admin/AddCustomerRanks"/>}
        {!permissions.includes("CanAddDoctorRank") && <Redirect to="/admin"/>}
        {!permissions.includes("CanUpdateDoctorRank") && <Redirect to="/admin"/>}

            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6">
                    <h2 className='d-inine-block float-left'><i onClick={history.goBack} className="fas fa-arrow-left" aria-hidden="true" style={{cursor:"pointer"}} /> {location.doctorRank ? "Update Customer Rank" : "Add Customer Rank"}</h2>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label required">NAME</label>
                            <input name='name' type="text" className="form-control"
                            value={formValues.name}  
                            onChange={hanleChange}
                            />
                            <div style={{ color: 'red' }}>{formErrors.name}</div>
                        </div>
                        <div className="form-group">
                            <label className="form-control-label required">Description</label>
                            <input name='description' type="text" className="form-control"
                            value={formValues.description}  
                            onChange={hanleChange}
                            />
                            <div style={{ color: 'red' }}>{formErrors.description}</div>
                        </div>
                        {addDoctorRank === "isLoading" ? 
                                    <div className="col-lg-12 loginbttm">
                                        <Loader style={{color:"red"}}/>
                                    </div>
                                    : 
                        <div className="col-lg-12 loginbttm"> 
                            <div className="col-lg-12 login-btm login-button d-block">
                                <button className="btn btn-outline-primary d-block m-auto"
                                onClick={addDoctorRankHandle}
                                >  {location.doctorRank ? "Update " : "Submit"}</button>
                            </div>
                        </div> }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDoctorRanks
