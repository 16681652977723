import axios from "axios";
import authHeader from './auth-header';
import { BASE_URL } from "../../Constatns";

const API_URL = BASE_URL+"Auth/";

class AuthService {
  
  async refreshToken(body){
     return axios.post(API_URL + 'GetAccessToken',body,{ headers: authHeader() });
   }

  async login(email, password) {
   
    const body = {
      "email": email,
      "password": password
    };
    console.log('Login API Request Body:', body);
    return axios
    
      .post(API_URL+"LoginAWS",body)
      .then((response) => {
        console.log(response, "response of login");
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      }).catch((error => {
        return error;
      }));
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("path");
    localStorage.removeItem("permissionsList");
    window.location.href = '/'
  }
}

export default new AuthService();
