import {
    TASK_PRIORITY_SUCCESS,
    TASK_PRIORITY_FAIL,
    SET_MESSAGE,
    TASK_PRIORITY_PENDING,
    ADD_TASK_PRIORITY_SUCCESS,
    ADD_TASK_PRIORITY_FAIL,
    ADD_TASK_PRIORITY_PENDING,
    UPDATE_TASK_PRIORITY_SUCCESS,
    UPDATE_TASK_PRIORITY_FAIL,
    UPDATE_TASK_PRIORITY_PENDING
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";


export const getTaskPriorities = () => async(dispatch) => {
    dispatch({
        type: TASK_PRIORITY_PENDING,
        payload: { taskPriorities: "isLoading" },
    });
    return await UserService.getTaskPriorities().then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: TASK_PRIORITY_SUCCESS,
                    payload: { taskPriorities : data.data.result },
                }) :
                dispatch({
                    type: TASK_PRIORITY_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: TASK_PRIORITY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

//add Task Priority
export const addTaskPriorities = (body) => async(dispatch) => {
    dispatch({
        type: ADD_TASK_PRIORITY_PENDING,
        payload: { addTaskPriorities: "isLoading" },
    });
    return await UserService.addTaskPriority(body).then(
        (data) => {
            data.data.status ? toast.success("Task Priority added Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: ADD_TASK_PRIORITY_SUCCESS,
                    payload: { addTaskPriorities : data.data.status },
                }) :
                dispatch({
                    type: ADD_TASK_PRIORITY_FAIL,
                    payload: { addTaskPriorities : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ADD_TASK_PRIORITY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

//update Task Priority
export const updateTaskPriorities = (body) => async(dispatch) => {
    dispatch({
        type: UPDATE_TASK_PRIORITY_PENDING,
        payload: { updateTaskPriorities: "isLoading" },
    });
    return await UserService.updateTaskPriority(body).then(
        (data) => {
            data.data.status ? toast.success("Task Priority updated Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_TASK_PRIORITY_SUCCESS,
                    payload: { updateTaskPriorities : data.data.status },
                }) :
                dispatch({
                    type: UPDATE_TASK_PRIORITY_FAIL,
                    payload: { updateTaskPriorities : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_TASK_PRIORITY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};