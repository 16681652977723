import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions } from "../../actions/userActions/permissionsAction";
import $ from "jquery";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import {
  TaskExist,
  ManageExist,
  BillingExist,
  InvoiceExist,
} from "./menuArrays";
import { useLocation } from "react-router-dom";
import { logout } from "../../actions/auth/auth";
import SidebarIcons from "./SidebarIcons";
import dashboardIcon from "./SidebarIcons"
import MenuSidebar from "./SidebarIcons"
// import Permissions from "../permissions/Permissions";

function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [localPermissions, setLocalPermissions] = useState([]);
  const employeeId = user.result[0] !== undefined ? user.result[0].user.id : false;
   
  useEffect(() => {
    !employeeId && dispatch(logout());
    // dispatch(getPermissions(employeeId));
   
    $("body").on("click", ".sidebar-dropdown > a", function () {
      $(".sidebar-submenu").slideUp(200);
      if ($(this).parent().hasClass("active")) {
        $(".sidebar-dropdown").removeClass("active");
        $(this).parent().removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this).next(".sidebar-submenu").slideDown(200);
        $(this).parent().addClass("active");
      }
    });

    $("body").on("click", "#close-sidebar", function () {
      let $pageWrapper = $(".page-wrapper");
      let $sidebarWrapper = $(".sidebar-wrapper");
      let $sidebarIcon = $(".sidebar-menu-title");

      if ($pageWrapper.hasClass("toggled")) {
        $pageWrapper.removeClass("toggled");
        $sidebarWrapper.removeClass("toggled-sideBar");
        $sidebarIcon.addClass("sidebar-display-icon hidden");
      } else {
        $pageWrapper.addClass("toggled");
        $sidebarWrapper.addClass("toggled-sideBar");
        $sidebarIcon.removeClass("sidebar-display-icon hidden");
      }
    });
  }, []);
  const logoutHandle = async () => {
    dispatch(logout());
  };

  const path = (path) => {
    if (location.pathname === path) {
      return "active";
    }
  };
  
  useEffect(()=> {
    const permissionsListString = localStorage.getItem('permissionsList');
    if(permissionsListString != null){
      setLocalPermissions(JSON.parse(permissionsListString));
    }
  },[]);
    
  const permissions =useSelector(
    (state) => state.permissions_reducer.permissions
  
  );

  return (
    <>
      <nav id="sidebar" className="sidebar-wrapper sidebar-border-class sidebar-shadow" style={{marginLeft:"16px", marginTop:"27px"}}>
        <div className="sidebar-content">
          <div className="sidebar-menu">
            <ul className="sidebar-menu-list">
              <li
                className={`mb-3  align-items-center gap-3  ${path(
                  "/admin"
                )}`}
              >
                <div className=" align-items-center">
                  <div id="close-sidebar" style={{paddingTop:"24px" }}> 
                    <i style={{ paddingLeft: "15px",paddingTop:"24px", cursor:"pointer"}}>
                      <img src={SidebarIcons.MenuSidebar} alt="Dashboard" />
                    </i>
                  </div><hr style={{border: "1px solid #E7E7E9"}}/>
                  <div>
                    <Link to="/admin">
                    <img src={dashboardIcon.dashboardIcon} alt="Dashboard" />
                      <span className="sidebar-menu-title" style={{paddingLeft:"10px",}}>Dashboard</span>
                    </Link>
                  </div>
                </div>
              </li>

              {/* <li className={`text-left menu-item ${path("/admin")}`}>
                <Link to="/admin">
                  <i>
                    <img
                      className="icon"
                      src={SidebarIcons.dashboardIcon}
                      alt="Dashboard"
                    />
                  </i>
                  <span className="sidebar-menu-title">Dashboard</span>
                </Link>
              </li> */}

              {/* <li className={`text-left menu-item ${path("/admin/legacy")}`}>
                <Link to="/admin/legacy">
                  <i>
                    <img className="icon" src={SidebarIcons.legacyIcon} />
                  </i>
                  <span className="sidebar-menu-title">Legacy</span>
                </Link>
              </li> */}

              {TaskExist(permissions, localPermissions)  && (
                <li className="sidebar-dropdown task-dropdown">
                  <Link to={"#"} className="d-flex align-items-center">
                    <i>
                      <img className="icon" src={SidebarIcons.taskIcon} />
                    </i>
                    <span className="sidebar-menu-title">Tasks</span>
                  </Link>
                  <div className="sidebar-submenu">
                    <ul className="task-ul">
                      {(permissions.includes("CanViewTask") || localPermissions.includes("CanViewTask")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/tasks"
                          )}`}
                        >
                          <Link to={"/admin/tasks"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.taskQueueIcon}
                              />
                            </div>
                            <span>Task List</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTask") || localPermissions.includes("CanViewTask")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/locate"
                          )}`}
                        >
                          <Link to={"/admin/locate"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.locateIcon}
                              />
                            </div>
                            <span>Locate</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTask") || localPermissions.includes("CanViewTask")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            ""
                          )}`}
                        >
                          <Link to={"/admin/TaskTarget"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.taskTargetIcon}
                              />
                            </div>
                            <span>Task Target</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTask") || localPermissions.includes("CanViewTask")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            ""
                          )}`}
                        >
                        
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              )}
              {ManageExist(permissions,localPermissions) && (
                <li className="sidebar-dropdown manage-dropdown">
                  <Link to={"#"} className="d-flex align-items-center">
                    <div className="icon-wrapper">
                      <img className="icon" src={SidebarIcons.manageIcon} />
                    </div>
                    <span className="sidebar-menu-title">Manage</span>
                  </Link>
                  <div className="sidebar-submenu">
                    <ul>
                      {(permissions.includes("CanViewEmployee") || localPermissions.includes("CanViewEmployee")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/employees"
                          )}`}
                        >
                          <Link to={"/admin/employees"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.employeesIcon}
                              />
                            </div>
                            <span>Employee</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewPermission") || localPermissions.includes("CanViewPermission")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/permissions"
                          )}`}
                        >
                          <Link to={"/admin/permissions"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.permissionIcon}
                              />
                            </div>
                            <span>Permission</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewOfficeLocation") || localPermissions.includes("CanViewOfficeLocation")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/officeLocations"
                          )}`}
                        >
                          <Link
                            to={"/admin/officeLocations"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.officeLocationIcon}
                              />
                            </div>
                            <span>Office Location</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTeam") || localPermissions.includes("CanViewTeam")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/teams"
                          )}`}
                        >
                          <Link to={"/admin/teams"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.teamIcon}
                              />
                            </div>
                            <span>Team</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewProduct") || localPermissions.includes("CanViewProduct")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/products"
                          )}`}
                        >
                          <Link to={"/admin/products"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.productIcon}
                              />
                            </div>
                            <span>Product</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewDesignation") || localPermissions.includes("CanViewDesignation")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/designations"
                          )}`}
                        >
                          <Link to={"/admin/designations"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.designationIcon}
                              />
                            </div>
                            <span>Designation</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewDepartment") || localPermissions.includes("CanViewDepartment")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/departments"
                          )}`}
                        >
                          <Link to={"/admin/departments"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.departmentIcon}
                              />
                            </div>
                            <span>Department</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewDoctor") || localPermissions.includes("CanViewDoctor")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/customers"
                          )}`}
                        >
                          <Link to={"/admin/customers"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.doctorIcon}
                              />
                            </div>
                            <span>Customer</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewDoctorRank") || localPermissions.includes("CanViewDoctorRank")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/customerRanks"
                          )}`}
                        >
                          <Link to={"/admin/customerRanks"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.doctorRankIcon}
                              />
                            </div>
                            <span>Customer Rank</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewDoctorSpecialization") || localPermissions.includes("CanViewDoctorSpecialization")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/doctorSpecializations"
                          )}`}
                        >
                          <Link
                            to={"/admin/doctorSpecializations"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.doctorSpecializationIcon}
                              />
                            </div>
                            <span>Doctor Specialization</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewPracticeLocation") || localPermissions.includes("CanViewPracticeLocation")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/organizationLocations"
                          )}`}
                        >
                          <Link
                            to={"/admin/organizationLocations"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.practiceLocationIcon}
                              />
                            </div>
                            <span>Organization Location</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewEmploymentStatus") || localPermissions.includes("CanViewEmploymentStatus")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/employmentStatuses"
                          )}`}
                        >
                          <Link
                            to={"/admin/employmentStatuses"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.employeeStatusIcon}
                              />
                            </div>
                            <span>Employment Status</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewSubTask") || localPermissions.includes("CanViewSubTask")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/subTasks"
                          )}`}
                        >
                          <Link to={"/admin/subTasks"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.subTasksIcon}
                              />
                            </div>
                            <span>Sub Tasks</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTaskStatus") || localPermissions.includes("CanViewTaskStatus")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/taskStatuses"
                          )}`}
                        >
                          <Link to={"/admin/taskStatuses"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.taskStatusIcon}
                              />
                            </div>
                            <span>Task Status</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTaskType") || localPermissions.includes("CanViewTaskType")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/taskTypes"
                          )}`}
                        >
                          <Link to={"/admin/taskTypes"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.taskIcon}
                              />
                            </div>
                            <span>Task Type</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTaskPriority") || localPermissions.includes("CanViewTaskPriority")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/taskPriorities"
                          )}`}
                        >
                          <Link to={"/admin/taskPriorities"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.taskPriorityIcon}
                              />
                            </div>
                            <span>Task Priority</span>
                          </Link>
                        </li>
                      )}
                      {/* 

                             
                      {permissions.includes("CanViewStock") && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/stocks"
                          )}`}
                        >
                          <Link to={"/admin/stocks"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.stockIcon}
                              />
                            </div>
                            <span>Stock</span>
                          </Link>
                        </li>
                      )}
                      {permissions.includes("CanViewStore") && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/stores"
                          )}`}
                        >
                          <Link to={"/admin/stores"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.storeIcon}
                              />
                            </div>
                            <span>Store</span>
                          </Link>
                        </li>
                      )}
                      {permissions.includes("CanViewStoreType") && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/storeTypes"
                          )}`}
                        >
                          <Link to={"/admin/storeTypes"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.storeTypeIcon}
                              />
                            </div>
                            <span>Store Type</span>
                          </Link>
                        </li>
                      )}
                      {permissions.includes("CanViewUnitType") && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/unitTypes"
                          )}`}
                        >
                          <Link to={"/admin/unitTypes"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.unitTypeIcon}
                              />
                            </div>
                            <span>Unit Type</span>
                          </Link>
                        </li>
                      )}


                     
                     */}
                     
                      {(permissions.includes("CanViewCity") || localPermissions.includes("CanViewCity")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/cities"
                          )}`}
                        >
                          <Link to={"/admin/cities"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.cityIcon}
                              />
                            </div>
                            <span>City</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewCountry") || localPermissions.includes("CanViewCountry"))&& (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/countries"
                          )}`}
                        >
                          <Link to={"/admin/countries"} className="d-flex align-items-center">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.countryIcon}
                              />
                            </div>
                            <span>Country</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewRole") || localPermissions.includes("CanViewRole")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/roles"
                          )}`}
                        >
                          <Link to={"/admin/roles"} className="d-flex align-items-center mb-20">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.rolesIcon}
                              />
                            </div>
                            <span>Roles</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              )}
              {/* {BillingExist(permissions) && (
                <li className="sidebar-dropdown billing-dropdown">
                  <Link to={"#"} className="d-flex">
                    <div className="icon-wrapper">
                      <img className="icon" src={SidebarIcons.billingIcon} />
                    </div>
                    <span className="sidebar-menu-title">Billing</span>
                  </Link>
                  <div className="sidebar-submenu">
                    <ul>
                      {permissions.includes("CanViewPendingBill") && (
                        <li className={`sidebar-menu-title menu-item`}>
                          <Link to={"#"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.billingIcon}
                              />
                            </div>
                            <span>Pending</span>
                          </Link>
                        </li>
                      )}
                      {permissions.includes("CanViewPreviousBill") && (
                        <li className={`sidebar-menu-title menu-item`}>
                          <Link to={"#"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.billingIcon}
                              />
                            </div>
                            <span>Previous</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              )} */}
              {/* {InvoiceExist(permissions) && (
                <li className="sidebar-dropdown invoice-dropdown">
                  <Link to={"#"} className="d-flex">
                    <div className="icon-wrapper">
                      <img className="icon" src={SidebarIcons.invoiceIcon} />
                    </div>
                    <span className="sidebar-menu-title">Invoice</span>
                  </Link>
                  <div className="sidebar-submenu">
                    <ul>
                      {permissions.includes("CanGenerateInvoice") && (
                        <li className={`sidebar-menu-title menu-item`}>
                          <Link to={"#"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.invoiceIcon}
                              />
                            </div>
                            <span>New Invoice</span>
                          </Link>
                        </li>
                      )}
                      {permissions.includes("CanUpdateInvoice") && (
                        <li className={`sidebar-menu-title menu-item`}>
                          <Link to={"#"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.invoiceIcon}
                              />
                            </div>
                            <span>Update</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              )} */}
            </ul>
          </div>
          {/* sidebar-menu  */}
        </div>
        {/* sidebar-content  */}
        <div className="sidebar-footer">
          <Link to={"#"}>
            <i className="fa fa-bell" />
            <span className="badge badge-pill badge-warning notification">
              3
            </span>
          </Link>
          <Link to={"#"}>
            <i className="fa fa-envelope" />
            <span className="badge badge-pill badge-success notification">
              7
            </span>
          </Link>
          <Link to={"#"}>
            <i className="fa fa-cog" />
            <span className="badge-sonar" />
          </Link>
          <Link to={"#"} title="Logout" onClick={logoutHandle}>
            <i className="fa fa-power-off" />
          </Link>
        </div>
      </nav>
    </>
  );
}
export default Sidebar;
