import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import "../dashboard/Dashboard.css";
import {
  getEmployeeSubOrdinates,
  getEmployees,
} from "../../actions/userActions/employeeAction";
import { getCallAveragEmployee } from "../../actions/userActions/callAverageEmp";
import { getDoctorsWithCustomerRank } from "../../actions/userActions/doctoreWithCustomerAction";
import { getCustomerRank } from "../../actions/userActions/customerRankAction";
import Loader from "../subComponents/Loader";
import faker from "faker";
import Select from "react-select";
import { Linechart } from "../subComponents/recharts/Linechart.jsx";
import { Barchart } from "../subComponents/recharts/Barchart.jsx";
// import { getToken, getMassaging } from "firebase/messaging";
// import { messaging } from "../../firebase";
// import userService, * as UserService from "../../services/user.service";
import { StackedBarchart } from "../subComponents/recharts/StackedBarchart";
import { getPermissions } from "../../actions/userActions/permissionsAction";
import { Tabular } from "../subComponents/tabular/Tabular";

function Dashboard() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = JSON.parse(localStorage.getItem("user"));
  const [graphData, setGraphData] = useState([]);
  const [showKol, setShowKol] = useState(false);
  const [title, setTitle] = useState("Call Average");
  const [pieData, setPieData] = useState({});
  const [selected, setSelected] = useState();
  const [LineLabels, setLineLabels] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("Call Average");

  // fcm and device id
  // const [fcm, setFcm] = useState("");
  // const [deviceId, setDeviceId] = useState("");

  // useEffect(() => {
  //   requestFCMPermission();
  // }, []);

  // // useEffect(() => {
  // //   postFCMToken();
  // // }, [deviceId, fcm]);

  // async function requestFCMPermission() {
  //   try {
  //     // Geting FCM token and device id
  //     requestPermission();
  //     getDeviceID();

  //     // Saving token and device ID to fcmBody
  //     const fcmBody = {
  //       employeeId: employeeId,
  //       fcm: fcm,
  //       deviceID: deviceId,
  //     };
  //   } catch (error) {
  //     console.error("Error requesting FCM permission:", error);
  //   }
  // }

  // async function postFCMToken() {
  //   if (fcm !== "" && deviceId !== "") {
  //     const fcmBody = {
  //       employeeId: employeeId,
  //       fcm: fcm,
  //       deviceID: deviceId,
  //     };

  //     userService.addFcmToken(fcmBody).then((res) => {
  //     });
  //     setFcm("");
  //     setDeviceId("");
  //   }
  // }
  // function getDeviceID() {
  //   if (navigator && navigator.userAgent) {
  //     setDeviceId(navigator.userAgent);
  //     return navigator.userAgent;
  //   } else {
  //     setDeviceId("default_device_id");
  //     return "default_device_id";
  //   }
  // }

  // async function requestPermission() {
  //   const currentPermission = Notification.permission;

  //   // Check if the permission has already been granted or denied
  //   if (currentPermission === "granted") {
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BMh_TwUq8QA_pWEbsRmHQDC4rqZAe3Qr1qHvyAF68bVJSNOqnjvpMJw26lk_pMjaD7JEICprj2Eabsj6gH7jdmo",
  //     });

  //     setFcm(token);
  //     return token;
  //   } else if (currentPermission === "denied") {
     
  //     return null; // Permission denied
  //   }

  //   // If permission hasn't been granted or denied, proceed with the request
  //   const requestedPermission = await Notification.requestPermission();

  //   if (requestedPermission === "granted") {
  //     try {
  //       const token = await getToken(messaging, {
  //         vapidKey:
  //           "BMh_TwUq8QA_pWEbsRmHQDC4rqZAe3Qr1qHvyAF68bVJSNOqnjvpMJw26lk_pMjaD7JEICprj2Eabsj6gH7jdmo",
  //       });

  //       setFcm(token);
  //       return token;
  //     } catch (error) {
  //       console.error("Error obtaining token:", error.message);
  //       throw error;
  //     }
  //   } else if (requestedPermission === "denied") {
      
  //     return null;
  //   }
  // }
  // // fcm end

  const employeeId =
    user.result[0] !== undefined ? user.result[0].user.id : false;

  const employees = useSelector(
    (state) => state.employees_reducer.employeeSubordinates
  );
  const callAvgEmp = useSelector(
    (state) => state.call_avg_emp_reducer.callAvgEmp
  );


  useEffect(() => {
    dispatch(getDoctorsWithCustomerRank(0))
      .then(() => {
        dispatch(getCustomerRank());
        dispatch(getEmployeeSubOrdinates(employeeId));
        dispatch(getPermissions(employeeId));
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);

  //hard code value of charts
  let aaaaa = [
    {
      dateTime: "2023-08-12T00:00:00",
      productName: "Azimik",
      employeeName: "AbuzarBhinder",
      calls: 3,
    },
    {
      dateTime: "2023-08-13T00:00:00",
      productName: "Azimik",
      employeeName: "AbuzarBhinder",
      calls: 4,
    },
    {
      dateTime: "2023-08-14T00:00:00",
      productName: "Azimik",
      employeeName: "AbuzarBhinder",
      calls: 2,
    },
    {
      dateTime: "2023-08-15T00:00:00",
      productName: "Azimik",
      employeeName: "AbuzarBhinder",
      calls: 4,
    },
    {
      dateTime: "2023-08-16T00:00:00",
      productName: "Azimik",
      employeeName: "AbuzarBhinder",
      calls: 6,
    },
    {
      dateTime: "2023-09-12T00:00:00",
      productName: "Cal-K",
      employeeName: "AbuzarBhinder",
      calls: 4,
    },
    {
      dateTime: "2023-09-13T00:00:00",
      productName: "Cal-K",
      employeeName: "AbuzarBhinder",
      calls: 1,
    },
    {
      dateTime: "2023-09-13T00:00:00",
      productName: "Cal-K",
      employeeName: "AbuzarBhinder",
      calls: 1,
    },
  ];
  const groupCallsByProduct = (data) => {
    const groupedData = {};

    data.forEach((item) => {
      const { productName, calls } = item;
      if (!groupedData[productName]) {
        groupedData[productName] = [];
      }
      groupedData[productName].push(calls);
    });
    const result = Object.entries(groupedData).map(([label, data]) => ({
      label,
      data,
      borderColor: "rgb(53, 12, 235)",
      fill: false,
    }));
    const allDates = Array.from(new Set(data.map((item) => item.dateTime)));

    return { data: result, dates: allDates };
  };

  useEffect(() => {
    // dispatch(getEmployeeSubOrdinates(employeeId));
    const { data, dates } = groupCallsByProduct(aaaaa);
    const formatDate = (dateString) => {
      const options = { month: "short", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };
    const formattedDates = dates.map(formatDate);
    setLineLabels(formattedDates);
  }, []);

  const handleChangeSelectRank = (e) => {
    if (showKol != false) {
      dispatch(getDoctorsWithCustomerRank(e.value));
    }
  };

  const doctors = useSelector(
    (state) => state.doctors_wiith_customer_reducer.doctorsWithCustomer
  );
  const customerRank = useSelector(
    (state) => state.customer_rank_reducer.customerRank
  );

  const PieLabels = ["Week 1", "Week 2", "Week 3", "Week 4"];

  const data = {
    labels: LineLabels,
    datasets: graphData,
  };

  const CallAverageChange = () => {
    setShowKol(false);
    setTitle("Call Average");
    setSelectedTab("Call Average");
  };
  const KolChange = () => {
    setShowKol(true);
    setTitle("KOL");
    setSelectedTab("KOL");
  };
  const MonthlySaleChange = () => {
    setShowKol(false);
    setTitle("Monthly Sale");
    setSelectedTab("Monthly Sale");

    setGraphData([
      {
        fill: true,
        label: "KOL",
        data: LineLabels.map(() =>
          faker.datatype.number({ min: 0, max: 1000 })
        ),
        borderColor: "rgb(7, 200, 235)",
        backgroundColor: "rgba(7, 200, 235, 0.5)",
      },
    ]);
  };
  const handleChange = (e) => {
    setGraphData([
      {
        fill: true,
        label: "Calls",
        data: LineLabels.map(() =>
          faker.datatype.number({ min: 0, max: 1000 })
        ),
        borderColor: "rgb(100, 135, 235)",
        backgroundColor: "rgba(100, 135, 235, 0.5)",
      },
    ]);
    setPieData({
      labels: PieLabels,
      datasets: [
        {
          label: "# of Calls",
          data: PieLabels.map(() =>
            faker.datatype.number({ min: 0, max: 1000 })
          ),
          backgroundColor: [
            "rgba(215, 99, 132, 0.2)",
            "rgba(51, 162, 235, 0.2)",
            "rgba(125, 206, 86, 0.2)",
            "rgba(175, 192, 192, 0.2)",
          ],
          borderColor: [
            "rgba(251, 99, 132, 1)",
            "rgba(51, 162, 235, 1)",
            "rgba(125, 206, 86, 1)",
            "rgba(175, 192, 192, 1)",
          ],
          borderWidth: 1,
        },
      ],
    });
  };
  const getOptions = (data, name) => {
    const getData =
      data && data !== "isLoading"
        ? data.map((row, i) => ({
          value: row.id ? row.id : row.customerRankId,
          label: row.name
            ? row.name
            : row.firstName && row.lastName
              ? `${row.firstName} ${row.lastName}`
              : row.rankDescription
                ? row.rankDescription
                : row.address,
          name: name,
        }))
        : [];
    return getData;
  };
  const [query, setQuery] = useState("");

  const filter_doctors =
    doctors !== "isLoading"
      ? doctors.filter((doctor) => {
        return doctor.name.toLowerCase().includes(query.toLowerCase());
      })
      : [];

  //charts data
  if (auth.isLoggedIn === false && !user) {
    return <Redirect to="/" />;
  }
  return (
    <>
      {/* Dashboard */}
      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <div className=" flex-grow-1 overflow-y-lg-auto">
          {/* Header */}
          <header className="bg-surface-primary border-bottom pt-6">
            <div className="container">
              <div className="mb-npx">
                <div className="row align-items-center">
                  <div className="col-sm-6 col-6 mb-4 mb-sm-0">
                    {/* Title */}
                    <h1 className="h2 mb-0 ls-tight text-left">Dashboard</h1>
                    
                  </div>
                  {/* Actions */}
                  <div className="col-sm-6 col-6 text-sm-end">
                  <Link className="summary-button btn-primary btn-lg"
                  to={{ pathname: "/admin/dashboard/SummaryModal/" }}> <i class="bi bi-download"> </i> Download Summary
                  </Link>
                    {/* employees list here */}
                  </div>
                </div>
                {/* Nav */}
                <div className="row">

                  <div className="col-6">
                    <ul className="nav nav-tabs mt-4 overflow-x border-0">
                      <li className="nav-item">
                        <p
                          style={{
                            cursor: "pointer",
                            color:
                              selectedTab === "Call Average" ? "blue" : "black",
                          }}
                          className={
                            selectedTab === "Call Average"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          onClick={CallAverageChange}
                        >
                          CALL AVERAGE
                        </p>
                      </li>
                      <li className="nav-item">
                        <p
                          style={{
                            cursor: "pointer",
                            color: selectedTab === "KOL" ? "blue" : "black",
                          }}
                          className={
                            selectedTab === "KOL"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          onClick={KolChange}
                        >
                          KOL
                        </p>
                      </li>
                      {/* <li className="nav-item">
                        <p
                          style={{
                            cursor: "pointer",
                            color:
                              selectedTab === "Monthly Sale" ? "blue" : "black",
                          }}
                          className={
                            selectedTab === "Monthly Sale"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          onClick={MonthlySaleChange}
                        >
                          Monthly Sale
                        </p>
                      </li> */}
                    </ul>
                  </div>

                  {showKol != false && (
                    <div className="col-6 pt-5">
                      <div className="row">
                        <div className="col-6"></div>
                        <div className="col-6">
                          <Select
                            value={selected}
                            isSearchable
                            options={getOptions(customerRank, "Month")}
                            name="taskAssignee"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Rank"
                            onChange={handleChangeSelectRank}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </header>
          {/* Main */}
          <main className="py-6 bg-surface-secondary">
            <div className="container">
              <div className="row ">
                {showKol != true && (
                  <div className="col-12" style={{ width: '1088px', height: '374px' }}>

                    <div className="row">
                      <div className="col-6" style={{ width: '528px', height: '374px' }}>
                        {showKol != true && <Linechart />}
                      </div>
                      <div className="col-6" style={{ width: '528px', height: '374px' }}>
                        {showKol != true && <Barchart />}
                      </div>
                    </div>
                    <div className="row ml-1">
                    {showKol != true && <StackedBarchart/>}
                    </div>

                    <div className="row justify-content-center ml-1" style={{width: "1088px",margin: ""}}>
                    {showKol != true && <Tabular/>}
                    </div>
                    
                  </div>
                )}


                {showKol == true && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-striped  mt-4">
                          <thead className="text-left">
                            <tr>
                              <td>Name</td>
                              <td>Customer Rank Id</td>
                              <td>Rank</td>
                            </tr>
                          </thead>
                          <tbody className="text-left">
                            {filter_doctors === "isLoading" && (
                              <tr>
                                <td
                                  colSpan={3}
                                  style={{ backgroundColor: "#fff !important" }}
                                >
                                  <Loader colored={true} />
                                </td>
                              </tr>
                            )}
                            {filter_doctors.length === 0 && (
                              <tr className="text-center">
                                <td></td>{" "}
                                <td>
                                  No data found!
                                </td>
                                <td></td>
                              </tr>
                            )}
                            {filter_doctors !== "isLoading" &&
                              filter_doctors.map((row, i) => (
                                <tr key={i}>
                                  <td>{row.name}</td>
                                  <td>{row.customerRankId}</td>
                                  <td>{row.rank}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
