import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addOfficeLocation,
  updateOfficeLocation,
} from '../../actions/userActions/officelocationAction'
import Loader from '../subComponents/Loader'
import { getCities } from '../../actions/userActions/cityAction'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';

function AddOfficeLocations() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    address: '',
    contact1: '',
    contact2: '',
    email: '',
    fax: '',
    extensions: '',
    cityId: '',
    cityName: '',
    isHeadOffice: false,
  }
  const [page, setPage] = useState(1)

  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })
  const cityBody = {
    filter: cityFilters,
    pageNumber: page,
    pageSize: 100,
  }

  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  useEffect(() => {
    dispatch(getCities(cityBody))
    {
      location.officeLocation &&
        location.officeLocation.row &&
        setFormValues({
          ...formValues,
          ['address']: location.officeLocation.row.address,
          ['contact1']: location.officeLocation.row.contact1,
          ['contact2']: location.officeLocation.row.contact2,
          ['email']: location.officeLocation.row.email,
          ['fax']: location.officeLocation.row.fax,
          ['extensions']: location.officeLocation.row.extensions,
          ['isHeadOffice']: location.officeLocation.row.isHeadOffice,
          ['cityId']: location.officeLocation.row.cityId,
          ['id']: location.id,
          ['cityName']:location.officeLocation.row.cityName,
        })
    }
  }, [])

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      location.officeLocation
        ? dispatch(updateOfficeLocation(formValues))
        : dispatch(addOfficeLocation(formValues))
    }
  }, [formErrors])

  const addSubmitHandle = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues))
    setIsSubmit(true)
    setTimeout(() => {
      setIsSubmit(false);
    }, 1700); 
  };
  

  const getOptions = (data, name) => {
    const getData =
      data && data !== 'isLoading'
        ? data.map((row, i) => ({
            value: row.id,
            label: row.name
              ? row.name
              : row.firstName && row.lastName
              ? `${row.firstName} ${row.lastName}`
              : row.address,
            name: name,
          }))
        : []
    return getData
  }

  const handleChange = (e) => {
    const val = e.target;
    const { name, value } = val;
  
    if (name === "cityId") {
      const selectedCity = allCities.cities.find(city => city.id === value);
      if (selectedCity) {
        setFormValues({ ...formValues, [name]: selectedCity.id, cityName: selectedCity.name });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    
    if (name === 'isHeadOffice' && formValues.isHeadOffice === true) {
      setFormValues({ ...formValues, [name]: false })
    } else if (name === 'isHeadOffice' && formValues.isHeadOffice === false) {
      setFormValues({ ...formValues, [name]: true })
    }
  }
  const validate = (values) => {
    const errors = {}
    if (!values.address) {
      errors.address = 'Address is required!'
    } else if (values.address.length > 300) {
      errors.address = 'Address must be less than 300 characters!'
    }
    if (!values.contact1) {
      errors.contact1 = 'Contact number is required!'
    } else if (
      !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
        values.contact1,
      )
    ) {
      errors.contact1 = 'Please enter valid contact number!'
    }
    if (
      values.contact2 &&
      !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
        values.contact2,
      )
    ) {
      errors.contact2 = 'Please enter valid contact number!'
    }
    if (!values.email) {
      errors.email = 'Email is required!'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Please enter valid email!'
    }
    if (values.cityId === '') {
      errors.cityId = 'City is required!'
    }
    return errors
  }

  const allCities = useSelector((state) => state.cities_reducer.cities)
  const addOfficeLocations = useSelector(
    (state) => state.officeLocations_reducer.addOfficeLocation,
  )
  const updateOfficeLocations = useSelector(
    (state) => state.officeLocations_reducer.updateOfficeLocation,
  )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  return (
    <>
      {addOfficeLocations && addOfficeLocations != 'isLoading' && isSubmit && (
        <Redirect to="/admin/officeLocations" />
      )}
      {!location.officeLocation && <Redirect to="/admin/AddOfficeLocations" />}
      {!permissions.includes('CanAddOfficeLocation') && (
        <Redirect to="/admin" />
      )}
      {!permissions.includes('CanUpdateOfficeLocation') && (
        <Redirect to="/admin" />
      )}

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />{' '}
              {location.officeLocation
                ? 'Update Office Location'
                : 'Add Office Location'}
            </h2>
            
          </div>
          <hr
          style={{
            marginLeft: "14px",
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            height: "1.3px",
            marginTop: "15px",
            width: "94%",
          }}
        />
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-md-9">
          <TextField
            style={{width:"100%", fontSize:"46px"}}   
              className="first_textfield"
              id="outlined-controlled"
              label="Address *"
              name="address"
              value={formValues.address}
              onChange={handleChange}
            />
             <div style={{ color: 'red' }}>{formErrors.address}</div>
          </div>
          <div className="col-md-3">
          <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">City</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={formValues.cityId}
    onChange={handleChange} 
    name='cityId'
  >
    {getOptions(allCities.cities, 'cityId').map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </Select>
  <div style={{ color: 'red' }}>{formErrors.cityId}</div>
</FormControl>


          </div>
          <div className="col-md-6 mt-5">
          <TextField
            style={{width:"100%", fontSize:"46px"}}   
              className="first_textfield"
              id="outlined-controlled"
              label="Contact 1 *"
              name="contact1"
              value={formValues.contact1}
              onChange={handleChange}
            />
             <div style={{ color: 'red' }}>{formErrors.contact1}</div>
          </div>
          <div className="col-md-6 mt-5">
          <TextField
            style={{width:"100%", fontSize:"46px"}}   
              className="first_textfield"
              id="outlined-controlled"
              label="Contact 2"
              name="contact2"
              value={formValues.contact2}
              onChange={handleChange}
            />
             <div style={{ color: 'red' }}>{formErrors.contact2}</div>

          </div>
          <div className="col-md-6 mt-5">
          <TextField
            style={{width:"100%", fontSize:"46px"}}   
              className="first_textfield"
              id="outlined-controlled"
              label="Email *"
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
             <div style={{ color: 'red' }}>{formErrors.email}</div>
          </div>

          <div className="col-md-6 mt-5">
          <TextField
            style={{width:"100%", fontSize:"46px"}}   
              className="first_textfield"
              id="outlined-controlled"
              label="Fax No"
              name="fax"
              value={formValues.fax}
              onChange={handleChange}
            />
             <div style={{ color: 'red' }}>{formErrors.fax}</div>
          </div>

          <div className="col-md-12 mt-5">
          

<div className="form-group set_label">
              <input
              
                name="isHeadOffice"
                style={{cursor:"pointer", height:"24px", width:"24px",}}
                type="checkbox"
                checked={formValues.isHeadOffice}
                className="ml-2"
                onChange={handleChange}
              />

              <label
                style={{ paddingLeft: "10px",}}
                className="form-control-label"

              >
                Is Head Office?
              </label>
            </div>
          </div>
          {addOfficeLocations === 'isLoading' ? (
            <div className="col-lg-12 loginbttm">
              <Loader style={{ color: 'red' }} />
            </div>
          ) : (

            <div className="col-lg-12" style={{display:"flex", justifyContent:"flex-end", gap:"20px"}}>
            <button className="btn btn-secondary "
            onClick={history.goBack}>
              Cancel
            </button>

            <Stack direction="row" spacing={2}>
      <LoadingButton 
      style={{display:"flex", alignItems:"center",justifyContent:"center"}}
        loading={isSubmit}
        variant="outlined"
        onClick={addSubmitHandle}
        
      >
        {location.officeLocation ? "Update" : "Submit"}
      </LoadingButton>
    </Stack>

          </div>
            
          )}
        </div>
      </div>
    </>
  )
}

export default AddOfficeLocations
