import React, { useState, useEffect } from "react";
import "./ForgotPassword.css";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logo_text.svg";
import employeeIcon from "../../assets/images/employee_icon.png";
import userService from "../../services/user.service";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Loader from '../subComponents/Loader';

function GetOtp() {
  const initialValues = { email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const submitHandle = async () => {
    const errors = validate(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setIsSubmit(true);
      setIsLoading(true);
    }
  
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
          const response = await userService.generateOtp(formValues.email);
          if (response.data.status === true) {
            toast.success("OTP generated successfully!");
            setTimeout(()=> {
              history.push("/forgotPassword/UpdatePassword", { email: formValues.email });
            }, 1000)
            
          } else {
            toast.error("Email does not exist!");
          }
        }
      } catch (error) {
        console.error("Error generating OTP:", error);
        toast.error("An error occurred while generating OTP! Enter Valid Email");
      }
      finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [formErrors, isSubmit, history]);

  const hanleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid email!";
    }
    return errors;
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-6">
            <div className="logo-section">
              <div className="border-div">
                <img style={{ height: "90%" }} src={logo} alt="" />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="forgotPassword-form">
              <div className="form">
                <div>
                  <h2>Forgot Password?</h2>
                  <p>Please provide employee email associated with your account</p>
                </div>
                <div className="input-wrapper mt-10">
                  <label htmlFor="#email">
                    <img src={employeeIcon} alt="" /> Employee Email
                  </label>
                  <input
                    name="email"
                    id="email"
                    type="text"
                    value={formValues.email}
                    onChange={hanleChange}
                  />
                  <p style={{ color: "red" }}>{formErrors.email}</p>
                </div>

                <div className="input-wrapper mt-5">
                  <button type="button" onClick={submitHandle}>
                  {isLoading && 
                   <div className="mt-2">
                      <Loader />
                    </div> }
                    Get OTP
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="footer">
              <p className="text-center">&#9400; Copyright Field Force 2024</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetOtp;
