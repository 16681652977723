import React,{useEffect,useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {addTaskPriorities,updateTaskPriorities} from '../../actions/userActions/taskPriorityAction'
import Loader from '../subComponents/Loader'
import {Redirect,useLocation,useHistory} from 'react-router-dom';
import { SketchPicker } from 'react-color';
function AddTaskPriorities() {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = {name: "", description: "", active: false};
    const [formValues,setFormValues] = useState(initialValues);
    const [formErrors,setFormErrors] = useState({});
    const [isSubmit,setIsSubmit] = useState(false);

    useEffect(()=>{
        {location.taskPriorities && setFormValues({...formValues,
            ["name"]:location.taskPriorities.row.name,
            ["description"]:location.taskPriorities.row.description,
            ["color"]:location.taskPriorities.row.color,
            ["id"]:location.id,
            ["active"]:location.taskPriorities.row.active
            });
        }
    },[])
    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmit){
            location.taskPriorities ? 
            dispatch(updateTaskPriorities(formValues))
            :
            dispatch(addTaskPriorities(formValues));
        }
    },[formErrors])

    const addTaskPriorityHandle = async(e) =>{
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }

    const hanleChange = (e) =>{
        const {name,value} = e.target;
        setFormValues({...formValues,[name]:value});
        if(name === "active" && formValues.active === true){
            setFormValues({...formValues,[name]:false});
        }
        else if(name === "active" && formValues.active === false){
            setFormValues({...formValues,[name]:true});
        }
    }
    const handleChangeComplete = (color, event) => {
        setFormValues({...formValues,
            ["color"]:color.hex,
            });
      };
    const validate = (values) => {
        const errors = {};
        if(!values.name){
            errors.name = "Name is required!"
        }
        if(!values.description){
            errors.description = "Description is required!"
        }
        if(!values.color){
            errors.color = "Colour is required!"
        }
        return errors;
    }
    const addTaskPriority =  useSelector(state => state.task_priorities_reducer.addTaskPriorities);
    const updateTaskPriority =  useSelector(state => state.task_priorities_reducer.updateTaskPriorities);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    return (
        <>
        {addTaskPriority && addTaskPriority !== "isLoading" && isSubmit && <Redirect to="/admin/taskPriorities"/>}
        {!location.taskPriorities && <Redirect to="/admin/AddTaskPriorities"/>}
        {!permissions.includes("CanAddTaskPriority") && <Redirect to="/admin"/>}
        {!permissions.includes("CanUpdateTaskPriority") && <Redirect to="/admin"/>}

            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6">
                    <h2 className='d-inine-block float-left'> <i onClick={history.goBack} className="fas fa-arrow-left" aria-hidden="true" style={{cursor:"pointer"}} /> {location.taskPriorities ? "Update Priority" : "Add Priority"}</h2>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label required">NAME</label>
                            <input name='name' type="text" className="form-control"
                            value={formValues.name}  
                            onChange={hanleChange}
                            />
                            <div style={{ color: 'red' }}>{formErrors.name}</div>
                        </div>
                        <div className="form-group">
                            <label className="form-control-label required">Description</label>
                            <input name='description' type="text" className="form-control"
                            value={formValues.description}  
                            onChange={hanleChange}
                            />
                            <div style={{ color: 'red' }}>{formErrors.description}</div>
                        </div>
                        {/* <div className="form-group">
                            <label className="form-control-label required">Colour</label>
                            <input name='color' type="text" className="form-control"
                            value={formValues.color}  
                            onChange={hanleChange}
                            />
                           <div style={{ color: 'red' }}>{formErrors.color}</div>
                        </div> */}
                        <div className="form-group">
                            <label className="form-control-label required">Select Colour</label>
                            <SketchPicker
                            color={ formValues.color }
                            onChangeComplete={handleChangeComplete }
                        />
                            <div style={{ color: 'red' }}>{formErrors.color}</div>
                        </div>
                        
                        <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label">Is Priority active ?</label>
                            <input name='active' type="checkbox" checked={formValues.active} className='ml-2 mt-2'
                            onChange={hanleChange}
                            />
                        </div>
                    </div>
                        {addTaskPriority === "isLoading" ? 
                                    <div className="col-lg-12 loginbttm">
                                        <Loader style={{color:"red"}}/>
                                    </div>
                                    : 
                        <div className="col-lg-12 loginbttm"> 
                            <div className="col-lg-12 login-btm login-button d-block">
                                <button className="btn btn-outline-primary d-block m-auto"
                                onClick={addTaskPriorityHandle}
                                >  {location.taskPriorities ? "Update " : "Submit"}</button>
                            </div>
                        </div> }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddTaskPriorities
