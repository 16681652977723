import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTaskProducts,getTaskSubTasks} from '../../../actions/userActions/tasks/taskAction';
import '../Tasks.css'
import Select from 'react-select'

 const TaskSection3 = (props) => {

  const dispatch = useDispatch();
  const allProductBody = {
    
      "filter": {
        "textSearch": ""
      },
      "pageNumber": -1,
      "pageSize": 0
  
  }

  useEffect (()=>{
    dispatch(getTaskProducts(allProductBody))
    .then(() => {
      dispatch(getTaskSubTasks());
    })
    
        //checking Inputs
        if(
          props.formValues["products"].length !== 0 &&
          props.formValues["subTasks"].length !== 0
          ){
              props.setBtnStatus(false);
      }
      else{
          props.setBtnStatus(true);
      }
  },[])

  const handleProductChange = (e) => {
    props.setFormValues({...props.formValues,["products"]:e.map((row,i)=>(row.value))});
    props.setSelectedValues({...props.selectedValues,["products"]:e.map((row,i)=>({'value':row.value, 'label': row.label}))});
  }
  const handleSubTaskChange = (e) => {
    props.setFormValues({...props.formValues,["subTasks"]:e.map((row,i)=>(row.value))});
    props.setSelectedValues({...props.selectedValues,["subTasks"]:e.map((row,i)=>({'value':row.value, 'label': row.label}))});
  }
    //checking Inputs
    if(
      props.formValues["products"].length !== 0 &&
      props.formValues["subTasks"].length !== 0
      ){
          props.setBtnStatus(false);
      }
      else{
          props.setBtnStatus(true);
      }
  const taskProducts =  useSelector(state => state.tasks_reducer.taskProducts);
  const taskSubTasks =  useSelector(state => state.tasks_reducer.taskSubTasks);

  const getOptions = (data,name) =>{
    const getData = data && data !== "isLoading" ? data.filter(row => row.active === true).map((row, i) => (
            {
            value:row.id ,
            label: row.name ? row.name : (row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : row.address ),
            name:name
            }
        )):
        [];
        return getData;
    }


  return (
    <div className='px-5'>
    <div className="col-md-12 p-0">
        <div className="form-group">
            <label>Products</label>
            <Select
                value={ props.selectedValues["products"]}
                isSearchable
                options={getOptions(taskProducts,"products")}
                name="products"
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select product"
                onChange={handleProductChange}
                />
            {/* {formErrors.gender} */}
        </div>
    </div>
    <div className="col-md-12 p-0">
        <div className="form-group">
            <label>Sub Tasks</label>
            <Select
                value={ props.selectedValues["subTasks"]}
                isSearchable
                options={getOptions(taskSubTasks,"subTasks")}
                name="subTasks"
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select sub-task"
                onChange={handleSubTaskChange}
                />
            {/* {formErrors.gender} */}
        </div>
    </div>
  </div>
    
  )
}

export default TaskSection3