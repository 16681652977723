import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getTeams } from "../../actions/userActions/teamAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
function Teams() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getTeams());
  }, []);
  const teams = useSelector((state) => state.teams_reducer.teams);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");
  const filter_teams =
    teams !== "isLoading"
      ? teams.filter((team) => {
          return team.name.toLowerCase().includes(query.toLowerCase());
        })
      : [];

  return (
    <>
      <div className="container mt-5">
        <div className="row">
        <div className="col-md-6">
          <h2 className="d-inline-block">Teams</h2>
            <p className='employees_set'>
            Manage all the teams here {" "}
            </p>
          </div>
          <div className="col-md-4">
            <Search setText={setQuery} text={query} placeholder="Search Team" />
          </div>
          {/* <div className="col-md-4">
            {permissions.includes("CanAddTeam") && (
              <Link
                to={"/admin/addTeams"}
                className="btn btn-primary float-right"
              >
                Add Team
              </Link>
            )}
          </div> */}
           <div className="col-md-2">
            {permissions.includes('CanAddTeam') && (
              <Link
                to={'/admin/addTeams'}
                className="btn btn-primary float-right"
              >
               + Add Team
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>
                    {" "}
                    <b>Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Status</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Actions</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {teams === "isLoading" && (
                  <tr>
                    <td
                      colSpan={4}
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {filter_teams !== "isLoading" &&
                  filter_teams.map((row, i) => (
                    <tr key={i}
                    style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}
                    >
                      <td>{row.name}</td>
                      <td>{row.description}</td>
                      {/* <td>{row.active ? "Yes" : "No"}</td> */}
                      <td>
                      <span
  style={{
    color: row.active
      ? "#2196F3"
      : "var(--text-primary, rgba(0, 0, 0, 0.87))",
    borderRadius: "100px",
    border: `1px solid ${
      row.active
        ? "var(--primary-main, #2196F3)"
        : "var(--chip-defaultEnabledBorder, #BDBDBD)"
    }`,
    padding: "3px 10px",
  }}
>
  {row.active !== undefined ? (row.active ? "Active" : "In-active") : "Active"}
</span>

                      </td>
                      <td>
                        <Link
                          to={{
                            pathname: "/admin/UpdateTeams",
                            team: { row },
                            id: row.id,
                          }}
                        >
                           <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z"
          fill="#505157"
        />
      </svg>
                        </Link>
                        {/* {" "}
                        <Link className="ml-2" to={"#"}>
                          <i
                            className="fas fa-trash"
                            style={{ color: "red" }}
                          />
                        </Link> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Teams;
