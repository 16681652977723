import React from "react";
import { logout } from "../../actions/auth/auth";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/images/Logo.svg";
import Notification_logo from "../../assets/images/notification-bing.svg"
const Header = (props) => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const logoutHandle = async () => {
    dispatch(logout());
  };
  return (
    <>
      {user.status ? (
        <div className="bg-12 d-flex align-items-center justify-content-between flex-d ">
          <div className="pl-4">
            <a className="navbar-brand" href="#">
              <img src={logo} alt="Logo" style={{width:"128px", height:"40px"}}/>
            </a>
          </div>
          {/* <button onClick={logoutHandle} className="btn d-inline-flex btn-sm btn-primary mx-5 my-2 d-block float-right">
                      <span className=" pe-2">
                        <i className="fa fa-power-off" />
                      </span>
                      <span>Logout</span>
                    </button> */}
          <nav className="navbar navbar-expand-sm navbar-light float-right mr-4">
            <ul className="navbar-nav">
              <li className="nav-item dropdown" style={{display:"flex", gap:"30px"}}>
                {/* <a className="nav-link dropdown-toggle p-0" href="#" id="navbardrop" data-toggle="dropdown">
                Dropdown link
              </a> */}
              <img src={Notification_logo} alt="" />
                <div
               
                  className="header-avatar-wrapper dropdown-toggle"
                  id="navbardrop"
                  data-toggle="dropdown"
                >
                  
                  <div  style={{height:"40px", width:"40px"}}>
                    <p>
                      {user.result[0].user.firstName.charAt(0)}
                      {user.result[0].user.lastName.charAt(0)}
                    </p>
                  </div>
                </div>
                <div className="dropdown-menu avatar-dropdown-menu bg-12">
                  <h4 className="ml-3 mt-2">
                    {user.result[0].user.firstName +
                      " " +
                      user.result[0].user.lastName}
                  </h4>
                  <p className="ml-3">Employee ID: {user.result[0].user.id}</p>
                  <button
                    onClick={logoutHandle}
                    className="btn btn-sm btn-primary m-auto mb-2 mt-4 d-block"
                  >
                    <span className=" pe-2">
                      <i className="fa fa-power-off" />
                    </span>
                    <span>Logout</span>
                  </button>
                </div>
              </li>
            </ul>
          </nav>
          {props.children}
        </div>
      ) : (
        <>
          <Redirect to="/" />
        </>
      )}
    </>
  );
};

export default Header;
