import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Loader from "../../subComponents/Loader";
import {
  getTasks,
  getTaskTypes,
  getTaskPriorities,
  getTaskStatuses,
  getTaskDetails,
  addTaskNotes,
  getTaskNotes,
} from "../../../actions/userActions/tasks/taskAction";
import { getEmployeeSubOrdinates } from "../../../actions/userActions/employeeAction";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Redirect } from "react-router-dom";
import Pagination from "../../paginatioin/pagination";
import moment from "moment";
// import { TaskFilter } from './TaskFilter';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Filter.css";
import { Drawer } from "@mui/material";
import { Slide } from "@mui/material";
import { format } from "date-fns";
import { Dropdown } from "react-bootstrap";

function Tasks() {
  const [show, setShow] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const [taskNote, setTaskNote] = useState("");

  const [newTaskId, setNewTaskId] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleDrawer = (anchor, open, taskId) => (event) => {
    dispatch(getTaskNotes(taskId));
    setNewTaskId(taskId);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      setDrawerVisible(true);
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (taskId) => {
    return (
      <div
        role="presentation"
        onClick={(event) => {
          event.stopPropagation();
        }}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
      >
        <div>
          <>
            <div>
              <p className="heading_notes p-5">Notes</p>
              <hr />
              {getNotes === "isLoading"}

              {getNotes === "isLoading" &&
                getNotes.length > 0 &&
                (!getNotes.createdByName ||
                  !getNotes.createdDate ||
                  !getNotes.note) && <Loader colored={true} />}

              {getNotes !== "isLoading" &&
                getNotes !== undefined &&
                getNotes.map((noteItem) => {
                  const nameParts = noteItem.createdByName.split(" ");
                  const firstNameInitial = nameParts[0] ? nameParts[0][0] : "";
                  const lastNameInitial =
                    nameParts.length > 1
                      ? nameParts[nameParts.length - 1][0]
                      : "";

                  const formattedDate = format(
                    new Date(noteItem.createdDate),
                    "EEEE MMMM d, yyyy"
                  );

                  return (
                    <>
                      <div style={{ marginTop: "40px", marginBottom: "53px" }}>
                        <div className="notes-avatar-wrapper" id="navbardrop">
                          <p>
                            {firstNameInitial}
                            {lastNameInitial}
                          </p>
                        </div>
                        <div style={{ marginLeft: "67px", marginTop: "-49px" }}>
                          <p className="no-line-break notes_name">
                            {noteItem.createdByName}
                          </p>
                          <p>{formattedDate}</p>
                        </div>
                        <p
                          className="notes_paragraph"
                          style={{ marginBottom: "35px" }}
                        >
                          {noteItem.note}
                        </p>
                      </div>
                    </>
                  );
                })}

              <div
                style={{
                  position: "fixed",
                  bottom: "0px",
                  width: "100%",
                  backgroundColor: "white",
                  zIndex: "999",
                }}
              >
                <textarea
                  id="w3review"
                  name="w3review"
                  rows="4"
                  cols="40"
                  className="notes_input"
                  placeholder="Enter Task Note"
                  value={taskNote}
                  onChange={(e) => setTaskNote(e.target.value)}
                ></textarea>{" "}
                <br />
                <Button
                  style={{ width: "28%", margin: "9px" }}
                  onClick={() => {
                    handleAddNote(taskId);
                  }}
                >
                  Add Note
                </Button>
              </div>
            </div>
          </>
        </div>
      </div>
    );
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));
  const auth = useSelector((state) => state.auth);
  const currentDate = moment();

  const toggleModal = () => {
    setShow(true);
    // setShowModal(true);
  };
  const closeModal = () => {
    setShow(false);
    // setShowModal(false);
  };
  const handleButtonClick = () => {
    setTaskId(taskId);
    setShowModal(true);
    // history.push("/admin/task/view/" + row.taskId);
  };
  const handleCloseModal = () => {
    setShowModal(false); // Hide the TaskDetail modal
  };

  useEffect(() => {
    if (auth.isLoggedIn === false && !user) {
      return <Redirect to="/" />;
    }
  });
  const [selected, setSelected] = useState({
    taskType: "",
    taskPriority: "",
    taskStatus: "",
    taskAssignee: "",
    textSearch: "",
  });

  const [filters, setFilters] = useState({
    taskType: [],
    taskPriority: [],
    taskStatus: [],
    taskAssignee: [],
    textSearch: "",
    taskFromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
    taskToDate: moment(currentDate).format("YYYY-MM-DD"),
  });
  const body = {
    filter: filters,
    pageNumber: page,
    pageSize: 10,
    employeeId: user.result[0].user.id,
  };
  const employeeId =
    user.result[0] !== undefined ? user.result[0].user.id : false;
  const getFormattedDate = (date) => {
    let dateTimeEST = new Date(date);
    var str = dateTimeEST.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
      year: "numeric",
      month: "long",
    });
    return str;
  };

  // const addTaskHandler = () => {
    
  //   history.push({ pathname: "/admin/AddTasks", taskType: "1" });
  // };
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (option === "medical") {
      history.push({ pathname: "/admin/AddTasks", taskType: "1" });
    } else if (option === "other") {
      history.push({ pathname: "/admin/AddTasks", taskType: "0" });
    } else if (option === "insurance") {
      history.push({ pathname: "/admin/AddTasks", taskType: "2" });
    }
  };
  const handleAddNote = async (taskId) => {
    if (taskNote.trim() !== "") {
      const noteBody = {
        taskId: newTaskId,
        note: taskNote,
        createdDate: new Date().toISOString(),
        createdBy: user.result[0].user.id,
        updatedDate: new Date().toISOString(),
        updatedBy: user.result[0].user.id,
      };

      try {
        dispatch(addTaskNotes(noteBody)).then(() => {
          dispatch(getTaskNotes(newTaskId));
        })

        setTaskNote("");
      } catch (error) {
        console.error(
          "An error occurred while adding or fetching notes:",
          error
        );
      }
    } else {
      alert("Please enter a note before adding.");
    }
  };

  useEffect(() => {
    dispatch(getTasks(body))
      .then(() => {
        dispatch(getTaskTypes());
        dispatch(getTaskPriorities());
        dispatch(getTaskStatuses());
        dispatch(getEmployeeSubOrdinates(employeeId));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [page, , selected.textSearch]);
  // useEffect(() => {
  //   dispatch(getTasks(body));
  // }, [selected.textSearch]);

  const getOptions = (data, name, activecheck) => {
    const getData =
      data && data !== "isLoading"
        ? !activecheck
          ? data.map((row, i) => ({
              value: row.id,
              label: row.name
                ? row.name
                : row.firstName && row.lastName
                ? `${row.firstName} ${row.lastName}`
                : row.address,
              name: name,
            }))
          : data
              .filter((row) => row.active === true)
              .map((row, i) => ({
                value: row.id,
                label: row.name
                  ? row.name
                  : row.firstName && row.lastName
                  ? `${row.firstName} ${row.lastName}`
                  : row.address,
                name: name,
              }))
        : [];

    if (name === "taskAssignee") {
      getData.unshift({
        value: user.result[0].user.id,
        label: "Assigned To Me",
        name: "taskAssignee",
      });
    }
    return getData;
  };
  const tasks = useSelector((state) => state.tasks_reducer.tasks);
  const taskTypes = useSelector((state) => state.tasks_reducer.taskTypes);
  const taskPriorities = useSelector(
    (state) => state.tasks_reducer.taskPriorities
  );
  const taskStatuses = useSelector((state) => state.tasks_reducer.taskStatuses);
  const getNotes = useSelector((state) => state.tasks_reducer.taskNotes);

  const employees = useSelector(
    (state) => state.employees_reducer.employeeSubordinates
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const handleChange = (e, name) => {
    if (name == "reset") {
      setSelected({
        ...filters,
        ["taskType"]: "",
        ["taskPriority"]: "",
        ["taskStatus"]: "",
        ["taskAssignee"]: "",
      });
      setFilters({
        ...filters,
        ["taskStatus"]: [],
        ["taskType"]: [],
        ["taskPriority"]: [],
        ["taskAssignee"]: [],
      });
    } else {
      setFilters({ ...filters, [name]: e.map((row, i) => row.value) });
      setSelected({
        ...selected,
        [name]: e.map((row, i) => ({
          value: row.value,
          label: row.label,
          name: row.name,
        })),
      });
    }
  };
  const dateChange = (start, end) => {
    let startDate = start.format("YYYY-MM-DD");
    let endDate = end.format("YYYY-MM-DD");
    setFilters({
      ...filters,
      ["taskFromDate"]: startDate,
      ["taskToDate"]: endDate,
    });
  };
  const getPaginationNo = (pageNum) => {
    const object = [];
    if (tasks !== "isLoading" && tasks.length !== 0) {
      for (let i = 0; i < tasks.page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? "active" : ""}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>
        );
      }
    }
    return object;
  };
  const handleSearch = (e) => {
    setSelected({ ...selected, ["textSearch"]: e.target.value });
    if (e.target.value.length >= 4) {
      setTimeout(() => {
        setFilters({ ...filters, ["textSearch"]: e.target.value });
      }, 500);
    }
    if (e.target.value == "") {
      setFilters({ ...filters, ["textSearch"]: e.target.value });
    }
  };
  const handleApply = () => {
    const body = {
      filter: filters,

      employeeId: user.result[0].user.id,
    };
    dispatch(getTasks(body));

    closeModal();
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block">
              {/* <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />{' '} */}
              Tasks
            </h2>
            <p>Manage all your tasks or create new</p>
          </div>
          <div className="col-md-3 d-flex">
            <input
              className="search-input"
              style={{
                width: "220px",
                height: "32px",
                paddingLeft: "10px",
                // border: "2px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#F5F5F5",
              }}
              type="text"
              placeholder="Search Task"
              onChange={handleSearch}
              value={selected.textSearch}
            />
          </div>

          <div
            className="col-2"
            style={{
              width: "auto",
              height: "32px",
              border: "1px solid #444750",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <button
              className="btn-md"
              onClick={toggleModal}
              style={{
                backgroundColor: "#FFFFFF",
                borderRight: "1px solid #444750",
                borderRadius: "0px",
                marginTop: "2px",
              }}
            >
              <i
                className="bi bi-funnel bi-lg"
                style={{ height: "21px", width: "15px" }}
              ></i>
              <span
                style={{
                  height: "14px",
                  width: "34px",
                  fontSize: "12px",
                  fontWeight: "400",
                  font: "Roboto",
                  lineHeight: "14.06px",
                }}
              >
                {" "}
                Filters
              </span>
            </button>
            <button
              className="btn-md position-relative"
              onClick={(e) => handleChange(e, "reset")}
              style={{ backgroundColor: "#FFFFFF", marginTop: "2px" }}
            >
              <i
                className="bi bi-funnel"
                style={{ height: "21px", width: "15px" }}
              ></i>
              <span className="position-absolute">
                <i
                  class="bi bi-x"
                  style={{
                    top: "-1.0px",
                    position: "absolute",
                    left: "-10px",
                    height: "12px",
                    width: "12px",
                    borderRadius: "50px",
                    backgroundColor: "white",
                    fontSize: "10px",
                  }}
                ></i>
              </span>
            </button>
          </div>

          <div className="col-1 ml-4 p-0">
            {permissions.includes("CanAddTask") && (
              <div>
                <Dropdown>
                  <Dropdown.Toggle size="sm" variant="primary" id="dropdown-basic">
                    + Add Task
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleOptionSelect("medical")}
                      className={selectedOption === "medical" ? "active" : ""}
                    >
                      Medical
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleOptionSelect("insurance")}
                      className={selectedOption === "insurance" ? "active" : ""}
                    >
                      Insurance
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleOptionSelect("other")}
                      className={selectedOption === "other" ? "active" : ""}
                    >
                      Other
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </div>
              // <button
              //   className="btn btn-sm btn-primary float-right"
              //   style={{
              //     height: "32px",
              //     display: "flex",
              //     alignItems: "center",
              //   }}
              //   onClick={addTaskHandler}
              // >
              //   + Add Task
              // </button>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive mt-4">
              <table className="table table-hover table-nowrap">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="task-table-header">
                      Title
                    </th>

                    <th scope="col" className="task-table-header">
                      Assigned To
                    </th>
                    <th scope="col" className="task-table-header">
                      Status
                    </th>
                    <th scope="col" className="task-table-header">
                      Task Priority
                    </th>
                    <th scope="col" className="task-table-header">
                      Date
                    </th>
                    <th scope="col" className="task-table-header">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tasks.tasks && tasks.tasks.length == 0 && (
                    <tr className="text-center">
                      <td colSpan={6}>No data found</td>
                    </tr>
                  )}
                  {tasks === "isLoading" && tasks !== undefined && (
                    <tr>
                      <td
                        colSpan={6}
                        style={{ backgroundColor: "#fff !important" }}
                      >
                        <Loader colored={true} />
                      </td>
                    </tr>
                  )}
                  {tasks !== "isLoading" &&
                    tasks.length !== 0 &&
                    tasks.tasks.length !== 0 &&
                    tasks.tasks.map((row, i) => (
                      <tr key={i}>
                        <td>
                          {" "}
                          <span>{row.title}</span>
                          <p
                            style={{
                              color: "#3A3A3A7A",
                              height: "14px",
                              width: "146px",
                              font: "Roboto",
                              fontWeight: "500",
                              fontSize: "12px",
                              lineHeight: "14.06px",
                            }}
                          >
                            {row.description}
                          </p>
                        </td>
                        <td style={{ height: "54px", width: "178.67px" }}>
                          {" "}
                          <span>{row.assigneeName}</span>
                        </td>
                        <td>
                          {" "}
                          <span
                            className="badge badge-lg badge-dot"
                            style={{ color: row.taskStatusColor }}
                          >
                            <i className="bg-success" />
                            {row.taskStatusName}
                          </span>
                        </td>
                        <td>
                          <span style={{ color: row.taskPriorityColor }}>
                            {row.taskPriorityName}
                          </span>
                        </td>
                        <td>
                          <span>{getFormattedDate(row.taskCreateDate)}</span>
                        </td>
                        <td style={{ paddingTop: "6px", paddingLeft: "6px" }}>
                          <Link
                            to={{ pathname: "/admin/task/view/"+ row.taskId }}
                            className="btn btn-sm icons_adjust"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                                fill="#505157"
                              />
                            </svg>
                          </Link>

                          {["right"].map((anchor) => (
                            <React.Fragment key={anchor}>
                              <button
                                style={{ background: "none" }}
                                onClick={toggleDrawer(anchor, true, row.taskId)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M19 5V14H14V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H15L21 15V5C21 3.9 20.1 3 19 3ZM12 14H7V12H12V14ZM17 10H7V8H17V10Z"
                                    fill="#505157"
                                  />
                                </svg>
                              </button>
                              <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                                TransitionComponent={Slide}
                                transitionDuration={500} // Adjust the duration as per your preference
                                transitionProps={{ direction: "right" }}
                                PaperProps={{ style: { width: "30%" } }}
                              >
                                {list(row.taskId)}
                              </Drawer>
                            </React.Fragment>
                          ))}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {tasks !== "isLoading" && (
          <div className="task-pagination-wrapper">
            <Pagination
              total={
                tasks.page != undefined &&
                tasks.page.totalPages * tasks.page.pageSize
              }
              current={tasks.page != undefined && tasks.page.pageNumber}
              pageSize={tasks.page != undefined && tasks.page.pageSize}
              onChange={(current) => setPage(current)}
              showLessItems
            />
          </div>
        )}
        <div className="modal-container">
          <Modal show={show} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Filter Results</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="row">
                <div className="col-6">
                  <Select
                    value={selected.taskType}
                    isSearchable
                    isMulti
                    options={getOptions(taskTypes, "taskType", true)}
                    name="taskType"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Type"
                    onChange={(e) => handleChange(e, "taskType")}
                  />
                </div>
                <div className="col-6">
                  <Select
                    value={selected.taskPriority}
                    isSearchable
                    isMulti
                    options={getOptions(taskPriorities, "taskPriority", true)}
                    name="taskType"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Priority"
                    onChange={(e) => handleChange(e, "taskPriority")}
                  />
                </div>
                <div className="col-6 mt-5">
                  <Select
                    value={selected.taskStatus}
                    isSearchable
                    isMulti
                    options={getOptions(taskStatuses, "taskStatus", true)}
                    name="taskStatus"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Status"
                    onChange={(e) => handleChange(e, "taskStatus")}
                  />
                </div>
                <div className="col-6 mt-5">
                  <Select
                    value={selected.taskAssignee}
                    isSearchable
                    isMulti
                    options={getOptions(employees, "taskAssignee", false)}
                    name="taskAssignee"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Emp"
                    onChange={(e) => handleChange(e, "taskAssignee")}
                  />
                </div>
                <div className="col-6 mt-5">
                  <DateRangePicker
                    initialSettings={{
                      startDate: moment().subtract(6, "days").toDate(),
                      endDate: moment().toDate(),
                      ranges: {
                        Today: [moment().toDate(), moment().toDate()],
                        Yesterday: [
                          moment().subtract(1, "days").toDate(),
                          moment().subtract(1, "days").toDate(),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").toDate(),
                          moment().toDate(),
                        ],
                        "Last 30 Days": [
                          moment().subtract(29, "days").toDate(),
                          moment().toDate(),
                        ],
                        "This Month": [
                          moment().startOf("month").toDate(),
                          moment().endOf("month").toDate(),
                        ],
                        "Last Month": [
                          moment()
                            .subtract(1, "month")
                            .startOf("month")
                            .toDate(),
                          moment().subtract(1, "month").endOf("month").toDate(),
                        ],
                        "Last Year": [
                          moment().subtract(1, "year").startOf("year").toDate(),
                          moment().subtract(1, "year").endOf("year").toDate(),
                        ],
                      },
                    }}
                    onCallback={dateChange}
                  >
                    <button className="btn btn-primary btn-sm">
                      Date Range
                    </button>
                  </DateRangePicker>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" size="sm" onClick={handleApply}>
                Apply
              </Button>
              <Button
                variant="secondary"
                size="sm"
                onClick={(e) => handleChange(e, "reset")}
              >
                Reset
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Tasks;
