import React, { useEffect, useState } from "react";
import "./StackedBarchart.css";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { StackedBarchartFilter } from "./StackedBarchartFilter";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Loader from '../Loader';



export function StackedBarchart() {
    const [appliedFilters, setAppliedFilters] = useState([]);
    function choosefilters(filters) {

        setAppliedFilters(filters);
    }
    const [employeesName, setEmployeesName] = useState([]);
    const employees = useSelector((state) => state.employees_reducer.employees);

    const setEmployeeNames = (array) => {
        setEmployeesName(array);
    };
    useEffect(() => {

        let data = [];
        employees &&
            employees !== "isLoading" &&
            employees.map((row) => {
                appliedFilters.map((row2) => {
                    if (row.id == row2) {
                        data.push(row.firstName + " " + row.lastName);
                    }
                });
            });

        setEmployeeNames(data);
    }, [employees, appliedFilters]);
    const dispatch = useDispatch();
    const [uniqueEmployees, setUniqueEmployees] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleModal = () => {
        setIsModalVisible(true);
    };
    const closeModal = () => {
        setIsModalVisible(false);
    };


    const callAvgPracticeLocation = useSelector(
        (state) => state.call_avg_pract_reducer.callAvgPract
    );

    // push data in array for rechart
    const [data, setData] = useState([]);
    useEffect(() => {
        if (
            callAvgPracticeLocation &&
            callAvgPracticeLocation !== "isLoading" &&
            callAvgPracticeLocation.byPracticeLocations &&
            callAvgPracticeLocation.byPracticeLocations.length > 0
        ) {
            const newData = callAvgPracticeLocation.byPracticeLocations.map((item) => ({
                date: moment(item.dateTime).format("YYYY-MM-DD"),
                call: Number(item.calls),
                employeeName: item.employeeName || "",
            }));
            setData(newData);
        }
    }, [callAvgPracticeLocation]);

    // data conversion
    useEffect(() => {


        // Check if callAvgProduct is defined and not null
        if (
            callAvgPracticeLocation &&
            callAvgPracticeLocation !== "isLoading" &&
            callAvgPracticeLocation.byPracticeLocations &&
            callAvgPracticeLocation.byPracticeLocations.length > 0
        ) {
            const uniqueEmployees = [];

            const groupedData = callAvgPracticeLocation.byPracticeLocations.reduce((result, item) => {
                const date = item.dateTime.split("T")[0];
                const key = `${date}_${item.employeeName}`;

                if (!result[key]) {
                    result[key] = { date };
                }

                // Handle any employee name
                if (!uniqueEmployees.includes(item.employeeName)) {
                    uniqueEmployees.push(item.employeeName);
                }

                // Set calls for the respective employee
                result[key][item.employeeName] =
                    (result[key][item.employeeName] || 0) + item.calls;

                return result;
            }, {});

            // Convert grouped data to the desired format
            const outputData = Object.values(groupedData).map((item) => {
                const formattedItem = {
                    date: moment(item.date).format("MMM D"),
                };

                // Map calls for each employee dynamically
                uniqueEmployees.forEach((employee) => {
                    formattedItem[employee] =
                        item[employee] || 0;
                });

                return formattedItem;
            });

            // Update state with the unique employee names
            setUniqueEmployees(uniqueEmployees);

            // Update state with the converted data
            setData(outputData);
        }
    }, [callAvgPracticeLocation]);


    return (
        <div className="chart-container" style={{ position: "relative", width: '528px', height: '374px', padding: '24px' }}>
            <div className="container">
                <div className="row" style={{ height: '73px', width: '480px' }}>
                    <div style={{ width: "330px", height: "23px" }}><p style={{
                        fontFamily: "Roboto",
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "23px",
                        textAlign: "left",
                        color: "#000000"
                    }}>Call average by Organization Location</p>
                    </div>
                    <div className="col-10" style={{ height: '32px' }}>
                    <p  style={{margin: "8px", maxHeight: '24px', fontFamily: "inter", fontWeight: "500", fontSize: "16px", lineHeight: "24px"}}>
                            {callAvgPracticeLocation.byPracticeLocations?.[0]?.practiceLocationName}
                        </p>
                    </div>
                    <div className="col-2" style={{ width: '32px', height: '32px' }}>
                        <button className="btn-sm" onClick={toggleModal} style={{ backgroundColor: "#FFFFFF", border: "1px #D9D9D9" }}>
                            <i className="bi bi-funnel"></i>
                        </button>
                    </div>
                    <div className="row" style={{ marginTop: '8px' }}>
                        {/* <div className="col-12 d-flex" style={{ width: '480px', height: '24px' }}>
                            {employeesName &&
                                employeesName.map((pn) => <span className="mr-2" style={{ paddingLeft: '4px', paddingBottom: '3px', paddingRight: '4px', borderRadius: '100px', border: '1px solid #00000014', height: '24px', width: "" }} key={pn}>{pn}</span>)}
                        </div> */}
                    </div>
                </div>
            </div>
            
            <div className="graph-container" style={{ maxWidth: '480px', height: '240px' }}>
            {callAvgPracticeLocation === 'isLoading' &&(<div className="col-12 d-flex justify-content-center"><Loader colored={true} /></div>)}
                {callAvgPracticeLocation !== 'isLoading' && callAvgPracticeLocation.byPracticeLocations && callAvgPracticeLocation.byPracticeLocations.length > 0 && (

                    <BarChart
                        width={480}
                        height={240}
                        data={data}
                        margin={{
                            top: 24,
                            right: 10,
                            left: 0,
                            bottom: 5,
                        }}
                    >

                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />

                        {/* Dynamically generate Line components for each product */}
                        {uniqueEmployees.map((employee, index) => (
                            <Bar
                                key={index}
                                dataKey={employee} // Assuming employee names are valid identifiers
                                stackId="stacked"
                                fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`} // Random color
                            />

                        ))}
                    </BarChart>
                )}
                {!callAvgPracticeLocation || (callAvgPracticeLocation === "isLoading") ||
            (callAvgPracticeLocation.length === 0 ||
              (callAvgPracticeLocation.byPracticeLocations && callAvgPracticeLocation.byPracticeLocations.length === 0)) && (
                <div style={{height: "240px", width: "480", border: "1px solid grey", display: "flex", alignItems: "center", justifyContent: "center",margin: "24px 10px 5px 0px"}}>
                <div className="col-12 d-flex justify-content-center">
              No Data to show "Apply or change Filters"
            </div>
              </div>
                    )}
                {/* Modal */}

                <StackedBarchartFilter
                    show={isModalVisible}
                    onClose={closeModal}
                    choosefilters={choosefilters}
                />
            </div>

        </div>
    );
}