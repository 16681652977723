import {
    TEAM_SUCCESS,
    TEAM_FAIL,
    SET_MESSAGE,
    TEAM_PENDING,
    ADD_TEAM_SUCCESS,
    ADD_TEAM_FAIL,
    ADD_TEAM_PENDING,
    UPDATE_TEAM_SUCCESS,
    UPDATE_TEAM_FAIL,
    UPDATE_TEAM_PENDING
} from "../types";
import { toast} from 'react-toastify'; 
import UserService from "../../services/user.service";


export const getTeams = () => async(dispatch) => {
    dispatch({
        type: TEAM_PENDING,
        payload: { teams: "isLoading" },
    });
    return await UserService.getTeams().then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: TEAM_SUCCESS,
                    payload: { teams : data.data.result },
                }) :
                dispatch({
                    type: TEAM_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: TEAM_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};
//add team
export const addTeams = (body) => async(dispatch) => {
    dispatch({
        type: ADD_TEAM_PENDING,
        payload: { addTeams: "isLoading" },
    });
    return await UserService.addTeam(body).then(
        (data) => {
            data.data.status ? toast.success("Team added Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: ADD_TEAM_SUCCESS,
                    payload: { addTeams : data.data.status },
                }) :
                dispatch({
                    type: ADD_TEAM_FAIL,
                    payload: { addTeams : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ADD_TEAM_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

//add team
export const updateTeams = (body) => async(dispatch) => {
    dispatch({
        type: UPDATE_TEAM_PENDING,
        payload: { updateTeams: "isLoading" },
    });
    return await UserService.updateTeam(body).then(
        (data) => {
            data.data.status ? toast.success("Team updated Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_TEAM_SUCCESS,
                    payload: { updateTeams : data.data.status },
                }) :
                dispatch({
                    type: UPDATE_TEAM_FAIL,
                    payload: { updateTeams : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_TEAM_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};