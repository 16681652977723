import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDoctorSpecializations } from "../../actions/userActions/doctorSpecializationAction";
import { Link, useHistory } from "react-router-dom";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
function DoctorSpecializations() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getDoctorSpecializations());
  }, []);
  const specializations = useSelector(
    (state) => state.doctor_specialization_reducer.specializations
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");
  const filter_specializations =
    specializations !== "isLoading"
      ? specializations.filter((specialization) => {
          return specialization.name
            .toLowerCase()
            .includes(query.toLowerCase());
        })
      : [];
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              Specializations
            </h2>
          </div>
          <div className="col-md-4">
            <Search
              setText={setQuery}
              text={query}
              placeholder="Search Specialization"
            />
          </div>
          <div className="col-md-4">
            {permissions.includes("CanAddDoctorSpecialization") && (
              <Link
                className="btn btn-primary float-right"
                to={"/admin/AddDoctorSpecializations"}
              >
                Add Specializations
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>Name</td>
                  <td>Description</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody className="text-left">
                {specializations === "isLoading" && (
                  <tr>
                    <td
                      colSpan={3}
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {filter_specializations !== "isLoading" &&
                  filter_specializations.map((row, i) => (
                    <tr key={i}>
                      <td>{row.name}</td>
                      <td>{row.description}</td>
                      <td>
                        <Link
                          to={{
                            pathname: "/admin/UpdateDoctorSpecializations",
                            doctorSpecialization: { row },
                            id: row.id,
                          }}
                        >
                          <i className="fas fa-edit" />
                        </Link>{" "}
                        {/* <Link className="ml-2" to={"#"}>
                          <i
                            className="fas fa-trash"
                            style={{ color: "red" }}
                          />
                        </Link> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorSpecializations;
