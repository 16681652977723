import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getRoles } from "../../actions/userActions/roleAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import "./Roles.css";
function Roles() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getRoles());
  }, []);
  const roles = useSelector((state) => state.roles_reducer.roles);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");
  const filter_roles =
    roles !== "isLoading"
      ? roles.filter((role) => {
          return role.name.toLowerCase().includes(query.toLowerCase());
        })
      : [];
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-7">
            <h2 className="d-inline-block">Roles</h2>
            <p>Manage all the roles in your organization here or add new</p>
          </div>
          <div className="col-md-3">
            <Search setText={setQuery} text={query} placeholder="Search Role" />
          </div>
          <div className="col-md-2">
            {permissions.includes("CanAddRole") && (
              <Link
                to={"/admin/AddRoles"}
                className="btn btn-primary float-right"
              >
                + Add Role
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Active</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {roles === "isLoading" && (
                  <tr>
                    <td
                      colSpan={6}
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {filter_roles !== "isLoading" &&
                  filter_roles.map((row, i) => (
                    <tr
                      key={i}
                      style={{
                        backgroundColor: "transparent",
                        "--x-table-accent-bg": "transparent",
                      }}
                    >
                      <td>{row.name}</td>
                      <td>{row.description}</td>
                      <td>
                        <span
                          style={{
                            color: row.active
                              ? "#2196F3"
                              : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                            borderRadius: "100px",
                            border: `1px solid ${
                              row.active
                                ? "var(--primary-main, #2196F3)"
                                : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                            }`,
                            padding: "3px 10px",
                          }}
                        >
                          {row.active ? "Active" : "In-active"}
                        </span>
                      </td>

                      <td>
                        <Link
                          to={{
                            pathname: "/admin/UpdateRoles",
                            role: { row },
                            id: row.id,
                          }}
                        >
                          <i className="fas fa-edit" />
                        </Link>{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Roles;
