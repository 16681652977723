import React from 'react'
import './Search.css'

const Search = (props) => {
  return (
    <>
      <input
        className="search-input"
        style={{
          width: '260px',
          height: '90%',
          paddingLeft: '10px',
          backgroundColor: '#F5F5F5',
          borderRadius: '5px',
          fontWeight: '600',
          fontSize: '16px',
          marginRight: '20px',
          border: '1px solid #F5F5F5 !important',
        }}
        type="text"
        placeholder={props.placeholder}
        onChange={(e) => props.setText(e.target.value)}
        value={props.text}
      />
    </>
  )
}
export default Search
