import {
  CUSTOMER_RANK_SUCCESS,
  CUSTOMER_RANK_FAIL,
  SET_MESSAGE,
  CUSTOMER_RANK_PENDING,
} from "../types";
import UserService from "../../services/user.service";
import { toast } from "react-toastify";

export const getCustomerRank = () => async (dispatch) => {
  dispatch({
    type: CUSTOMER_RANK_PENDING,
    payload: { customerRank: "isLoading" },
  });
  return await UserService.getCustomerRank()
    .then(
      (data) => {
        console.log(data);
        data.data.status
          ? dispatch({
              type: CUSTOMER_RANK_SUCCESS,
              payload: { customerRank: data.data.result },
            })
          : dispatch({
              type: CUSTOMER_RANK_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: CUSTOMER_RANK_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};
