import React,{useEffect,useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {addDepartments,updateDepartments} from '../../actions/userActions/departmentAction'
import Loader from '../subComponents/Loader'
import {Redirect,useLocation,useHistory} from 'react-router-dom';
function AddDepartments() {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = {name: "", description: "", active: false};
    const [formValues,setFormValues] = useState(initialValues);
    const [formErrors,setFormErrors] = useState({});
    const [isSubmit,setIsSubmit] = useState(false);

    useEffect(()=>{
        {location.department && setFormValues({...formValues,
            ["name"]:location.department.row.name,
            ["description"]:location.department.row.description,
            ["id"]:location.id,
            ["active"]:location.department.row.active
            });
        }
    },[])
    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmit){
            location.department ? 
            dispatch(updateDepartments(formValues))
            :
            dispatch(addDepartments(formValues));
        }
    },[formErrors])

    const addDepartmentHandle = async(e) =>{
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }

    const hanleChange = (e) =>{
        const {name,value} = e.target;
        setFormValues({...formValues,[name]:value});
        if(name === "active" && formValues.active === true){
            setFormValues({...formValues,[name]:false});
        }
        else if(name === "active" && formValues.active === false){
            setFormValues({...formValues,[name]:true});
        }
    }

    const validate = (values) => {
        const errors = {};
        if(!values.name){
            errors.name = "Name is required!"
        }
        if(!values.description){
            errors.description = "Description is required!"
        }
        return errors;
    }
    const addDepartment =  useSelector(state => state.departments_reducer.addDepartment);
    const updateDepartment =  useSelector(state => state.departments_reducer.updateDepartment);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    return (
        <>
        {addDepartment && addDepartment !== "isLoading" && isSubmit && <Redirect to="/admin/departments"/>}
        {!location.department && <Redirect to="/admin/AddDepartments"/>}
        {!permissions.includes("CanAddDepartment") && <Redirect to="/admin"/>}
        {!permissions.includes("CanUpdateDepartment") && <Redirect to="/admin"/>}

            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6">
                    <h2 className='d-inine-block float-left'><i onClick={history.goBack} className="fas fa-arrow-left" aria-hidden="true" style={{cursor:"pointer"}} /> {location.department ? "Update Department" : "Add Department"}</h2>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label required">NAME</label>
                            <input name='name' type="text" className="form-control"
                            value={formValues.name}  
                            onChange={hanleChange}
                            />
                           <div style={{ color: 'red' }}>{formErrors.name}</div>
                        </div>
                        <div className="form-group">
                            <label className="form-control-label required">Description</label>
                            <input name='description' type="text" className="form-control"
                            value={formValues.description}  
                            onChange={hanleChange}
                            />
                            <div style={{ color: 'red' }}>{formErrors.description}</div>
                        </div>
                        <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-control-label">Is Department active ?</label>
                            <input name='active' type="checkbox" checked={formValues.active} className='ml-2 mt-2'
                            onChange={hanleChange}
                            />
                        </div>
                    </div>
                        {addDepartment === "isLoading" ? 
                                    <div className="col-lg-12 loginbttm">
                                        <Loader style={{color:"red"}}/>
                                    </div>
                                    : 
                        <div className="col-lg-12 loginbttm"> 
                            <div className="col-lg-12 login-btm login-button d-block">
                                <button className="btn btn-outline-primary d-block m-auto"
                                onClick={addDepartmentHandle}
                                >  {location.department ? "Update " : "Submit"}</button>
                            </div>
                        </div> }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDepartments
