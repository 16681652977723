import React, { useEffect, useState } from "react";
import "./Permissions.css";
import { useLocation, useHistory } from "react-router-dom";

const ViewPermissions = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [filteredPermissions, setFilteredPermissions] = useState([]);
  const [query, setQuery] = useState("");
  const rowData = location.state;
  console.log(rowData, "props in view permission");
  useEffect(() => {
    if (rowData) {
      const filtered = rowData.rowData.permission.filter((permission) =>
        permission.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredPermissions(filtered);
    }
  }, [query, rowData]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div
            className="col-md-8"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <i onClick={history.goBack}>
              <svg
                style={{ marginBottom: "8px", cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M20 11.5H7.83L13.42 5.91L12 4.5L4 12.5L12 20.5L13.41 19.09L7.83 13.5H20V11.5Z"
                  fill="#505157"
                />
              </svg>
            </i>{" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2 className="d-inline-block">
                {rowData.rowData.name} Permissions
              </h2>
              <p style={{ fontSize: "14px", marginLeft: "6px" }}>
                This permission group contains below listed permission
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <input
              className="search-input manage_input"
              type="text"
              placeholder="Search Permission"
              onChange={handleSearch}
              value={query}
            />
          </div>
          <hr
            style={{
              marginLeft: "14px",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              height: "1.3px",
              marginTop: "15px",
              width: "94%",
            }}
          />

          {filteredPermissions.length > 0 && (
            <table>
              <tbody>
                {filteredPermissions.map((permission, i) => (
                  <tr
                    key={i}
                    style={{ display: "grid", paddingBottom: "15px" }}
                  >
                    <td style={{ paddingLeft: "18px" }} className="table_text">
                      {permission.name}
                      <hr
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.12)",
                          height: "1.3px",
                          marginTop: "15px",
                          width: "98%",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {filteredPermissions.length === 0 && (
            <p style={{ display: "flex", justifyContent: "center" }}>
              No permissions found.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewPermissions;
