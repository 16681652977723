import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getTaskPriorities } from "../../actions/userActions/taskPriorityAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
function TaskPriorities() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getTaskPriorities());
  }, []);
  const taskPriorities = useSelector(
    (state) => state.task_priorities_reducer.taskPriorities
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");
  const filter_taskPriorities =
    taskPriorities !== "isLoading"
      ? taskPriorities.filter((taskPriority) => {
          return taskPriority.name.toLowerCase().includes(query.toLowerCase());
        })
      : [];

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              Task Priorities
            </h2>
          </div>
          <div className="col-md-4">
            <Search
              setText={setQuery}
              text={query}
              placeholder="Search Priority"
            />
          </div>
          <div className="col-md-4">
            {permissions.includes("CanAddTaskPriority") && (
              <Link
                to={"/admin/addTaskPriorities"}
                className="btn btn-primary float-right"
              >
                Add Priority
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>
                    {" "}
                    <b>Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Colour</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Active</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {taskPriorities === "isLoading" && (
                  <tr>
                    <td
                      colSpan={6}
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {filter_taskPriorities !== "isLoading" &&
                  filter_taskPriorities.map((row, i) => (
                    <tr key={i}>
                      <td>{row.name}</td>
                      <td>{row.description}</td>
                      <td>
                        <div
                          style={{
                            height: "20px",
                            width: "20px",
                            backgroundColor: row.color,
                            display: "inline-block",
                          }}
                        ></div>
                        <div className="float-left mr-2">
                          <span style={{ lineHeight: "20px" }}>
                            {row.color}
                          </span>
                        </div>
                      </td>
                      <td>{row.active ? "Yes" : "No"}</td>
                      <td>
                        <Link
                          to={{
                            pathname: "/admin/UpdateTaskPriorities",
                            taskPriorities: { row },
                            id: row.id,
                          }}
                        >
                          <i className="fas fa-edit" />
                        </Link>{" "}
                        {/* <Link className="ml-2" to={"#"}>
                          <i
                            className="fas fa-trash"
                            style={{ color: "red" }}
                          />
                        </Link> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskPriorities;
