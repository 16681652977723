import React, { useEffect, useState } from "react";
import "./Barchart.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { BarchartFilter } from "./BarchartFilter";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Loader from '../Loader';



export function Barchart() {
  const [appliedFilters, setAppliedFilters] = useState([]);
  function choosefilters(filters) {
    setAppliedFilters(filters);
  }
  const [employeesName, setEmployeesName] = useState([]);
  const employees = useSelector((state) => state.employees_reducer.employees);

  const setEmployeeNames = (array) => {
    setEmployeesName(array);
  };
  useEffect(() => {

    let data = [];
    employees &&
      employees !== "isLoading" &&
      employees.map((row) => {
        appliedFilters.map((row2) => {
          if (row.id == row2) {
            data.push(row.firstName + " " + row.lastName);
          }
        });
      });

    setEmployeeNames(data);
  }, [employees, appliedFilters]);
  const dispatch = useDispatch();
  const [uniqueEmployees, setUniqueEmployees] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };


  const callAvgProduct = useSelector(
    (state) => state.call_avg_pro_reducer.callAvgPro
  );

  // push data in array for rechart
  const [data, setData] = useState([]);
  useEffect(() => {
    if (
      callAvgProduct &&
      callAvgProduct !== "isLoading" &&
      callAvgProduct.byProducts &&
      callAvgProduct.byProducts.length > 0
    ) {
      const newData = callAvgProduct.byProducts.map((item) => ({
        date: moment(item.dateTime).format("YYYY-MM-DD"),
        call: Number(item.calls),
        employeeName: item.employeeName || "",
      }));
      setData(newData);
    }
  }, [callAvgProduct]);

  // data conversion
  useEffect(() => {


    // Check if callAvgProduct is defined and not null
    if (
      callAvgProduct &&
      callAvgProduct !== "isLoading" &&
      callAvgProduct.byProducts &&
      callAvgProduct.byProducts.length > 0
    ) {
      const uniqueEmployees = [];

      const groupedData = callAvgProduct.byProducts.reduce((result, item) => {
        const date = item.dateTime.split("T")[0];
        const key = `${date}_${item.employeeName}`;

        if (!result[key]) {
          result[key] = { date };
        }

        // Handle any employee name
        if (!uniqueEmployees.includes(item.employeeName)) {
          uniqueEmployees.push(item.employeeName);
        }

        // Set calls for the respective employee
        result[key][item.employeeName] =
          (result[key][item.employeeName] || 0) + item.calls;

        return result;
      }, {});

      // Convert grouped data to the desired format
      const outputData = Object.values(groupedData).map((item) => {
        const formattedItem = {
          date: moment(item.date).format("MMM D"),
        };

        // Map calls for each employee dynamically
        uniqueEmployees.forEach((employee) => {
          formattedItem[employee] =
            item[employee] || 0;
        });

        return formattedItem;
      });

      // Update state with the unique employee names
      setUniqueEmployees(uniqueEmployees);

      // Update state with the converted data
      setData(outputData);
    }
  }, [callAvgProduct]);


  return (
    <div className="chart-container" style={{ position: "relative", width: '528px', height: '374px', padding: '24px' }}>
      <div className="container">
        <div className="row" style={{ height: '73px', width: '480px' }}>
        <div style={{ width: "260px", height: "23px" }}><p style={{
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "23px",
              textAlign: "left",
              color: "#000000"
            }}>Call average by Product</p>
            </div>
          <div className="col-10" style={{ height: '32px' }}>
          <p  style={{margin: "8px", maxHeight: '24px', fontFamily: "inter", fontWeight: "500", fontSize: "16px", lineHeight: "24px"}}>
              {callAvgProduct.byProducts?.[0]?.productName}
            </p>
          </div>
          <div className="col-2" style={{ width: '32px', height: '32px' }}>
            <button className="btn-sm" onClick={toggleModal} style={{ backgroundColor: "#FFFFFF", border: "1px #D9D9D9" }}>
              <i className="bi bi-funnel"></i>
            </button>
          </div>
          <div className="row" style={{ marginTop: '8px' }}>
            {/* <div className="col-12 d-flex" style={{ width: '480px', height: '24px' }}>
              {employeesName &&
                employeesName.map((pn) => <span className="mr-2" style={{ paddingLeft: '4px', paddingBottom: '3px', paddingRight: '4px', borderRadius: '100px', border: '1px solid #00000014', height: '24px', width: "" }} key={pn}>{pn}</span>)}
            </div> */}
          </div>
        </div>
      </div>
      
      <div className="graph-container" style={{ maxWidth: '480px', height: '240px' }}>
      {callAvgProduct === 'isLoading' &&(<div className="col-12 d-flex justify-content-center"><Loader colored={true} /></div>)}
        {callAvgProduct !== 'isLoading' &&callAvgProduct.byProducts && callAvgProduct.byProducts !== undefined && callAvgProduct.byProducts.length > 0 && (

          <BarChart
            width={480}
            height={240}
            data={data}
            margin={{
              top: 24,
              right: 10,
              left: 0,
              bottom: 5,
            }}
          >

            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />

            {/* Dynamically generate Line components for each product */}
            {uniqueEmployees.map((employee, index) => (
              <Bar
                key={index}
                dataKey={employee} // Assuming employee names are valid identifiers
                fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`} // Random color
              />
            ))}
          </BarChart>
        )}
        {callAvgProduct !== 'isLoading' &&
          (callAvgProduct === undefined || callAvgProduct.length === 0) && (
            <div style={{height: "240px", width: "480", border: "1px solid grey", display: "flex", alignItems: "center", justifyContent: "center", margin: "24px 10px 5px 0px"}}>
                  <div className="col-12 d-flex justify-content-center">
                No Data to show "Apply or change Filters"
              </div>
                </div>
          )}
        {/* Modal */}
        <BarchartFilter
          show={isModalVisible}
          onClose={closeModal}
          choosefilters={choosefilters}
        />
      </div>

    </div>
  );
}