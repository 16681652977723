import RCPagination from 'rc-pagination'
// import { ArrowNext } from '@/components/icons/arrow-next'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'

// import { ArrowPrev } from '@/components/icons/arrow-prev'
import 'rc-pagination/assets/index.css'

const Pagination = (props) => {
  return (
    <RCPagination
      nextIcon={<AiOutlineArrowRight />}
      prevIcon={<AiOutlineArrowLeft />}
      {...props}
    />
  )
}

export default Pagination
