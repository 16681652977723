import destinationIconNew from '../../assets/images/destinationIconNew.svg';
import employeesIcon from "../../assets/images/Icon=Employee.svg";
import departmentIcon from "../../assets/images/Icon=Departments.svg";
import employeeStatusIcon from "../../assets/images/Icon=Employee Status.svg";
import officeLocationIcon from "../../assets/images/Icon=Office Locations.svg";
import permissionIcon from "../../assets/images/Icon=Permissions.svg";
import productIcon from "../../assets/images/Icon=Products.svg";
import rolesIcon from "../../assets/images/Icon=Roles.svg";
import employeeIcon from "../../assets/images/employee_icon.svg";
import productBlueIcon from "../../assets/images/product__blue_icon.svg";
import doctorIcon from "../../assets/images/Icon=Doctors.svg"
import subTasksIcon from "../../assets/images/Icon=Subtasks.svg";
import taskStatusIcon from "../../assets/images/Icon=Task Statuses.svg";
import taskPriorityIcon from "../../assets/images/Icon=Task Priorities.svg";
import assignedToIcon from "../../assets/images/assignedToIcon.svg";
import productsIcon from "../../assets/images/productsIcon.svg";
import createdonIcon from "../../assets/images/createdonIcon.svg";
import subtasksIconNew from "../../assets/images/subtasksIconNew.svg";
import statusIcon from "../../assets/images/statusIcon.svg";



const TaskdetailIcons = {
   
    employeesIcon,
    assignedToIcon,
    destinationIconNew,
    employeeStatusIcon,
    officeLocationIcon,
    productIcon,
    employeeIcon,
    productBlueIcon,
    doctorIcon,
    subTasksIcon,
    taskStatusIcon,
    taskPriorityIcon,
    productsIcon,
    createdonIcon,
    subtasksIconNew,
    statusIcon
    
  };
  
  export default TaskdetailIcons;