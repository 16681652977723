import "./Tabular.css";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { TabularFilter } from "./TabularFilter";
import Loader from "../Loader";
import { Link, useHistory } from "react-router-dom";
import { getCallAveragTasks } from "../../../actions/userActions/callAverageTasks";
import SidebarIcons from "../../nav/SidebarIcons";


export function Tabular() {
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showDetailsForRow, setShowDetailsForRow] = useState({});

    const toggleModal = () => {
        setIsModalVisible(true);
    };
    const closeModal = () => {
        setIsModalVisible(false);
    };

    const callAvgTable = useSelector(
        (state) => state.call_avg_tab_reducer.callAvgTab
    );
    const handleDetails = (taskIDs, i) => {
        const body = {
            taskIDs: taskIDs
        };

        setShowDetailsForRow((prevState) => ({
            ...prevState,
            [i]: !prevState[i],
        }));

        dispatch(getCallAveragTasks(body));
    };
    const callAvgTasks = useSelector(
        (state) => state.call_avg_tasks_reducer.callAvgTasks
    );

    return (
        <>
            <div className="main-container" style={{ marginBottom: "20px", marginTop: "10px"  }}>
                <div className="container">
                    <div className="row" style={{ height: '73px', width: ""  }}>
                        <div className="col-11"><p style={{
                            fontFamily: "Roboto",
                            fontSize: "18px",
                            fontWeight: "700",
                            lineHeight: "23px",
                            textAlign: "left",
                            color: "#000000",
                            width: "260px", height: "23px"
                        }}>Call average table</p>
                        </div>

                                    {/* <div className="col-10" style={{ height: '32px' }}>
                                        <p  style={{margin: "8px", maxHeight: '24px', fontFamily: "inter", fontWeight: "500", fontSize: "16px", lineHeight: "24px"}}>
                            {callAvgEmployee.byEmployee?.[0]?.employeeName}
                            </p>
                                    </div> */}
                        <div className="col-1" style={{ width: '32px', height: '32px', }}>
                            <button
                                className="btn-sm"
                                onClick={toggleModal}
                                style={{ backgroundColor: "#FFFFFF", border: "1px #D9D9D9" }}
                            >
                                <i className="bi bi-funnel"></i>
                            </button>
                        </div>
                            {/* <div className="row" style={{ marginTop: '8px' }}>
                                            <div className="col-12 d-flex" style={{width: '480px', height: '24px'}}>
                                {productsName &&
                                productsName.map((pn) => <span className="mr-2" style={{paddingLeft: '4px', paddingBottom: '3px', paddingRight: '4px', borderRadius: '100px', border: '1px solid #00000014', height: '24px', width: ""}}  key={pn}>{pn}</span>)}
                            </div>
                                        </div> */}
                    </div>
                </div>

                <div className="table-container" >
                    <table className="table table-hover table-nowrap">
                        <thead className="text-left">
                            <tr className="main-header-table"
                            >
                                <td className="main-header-table-td"
                                >
                                    {" "}
                                    <b>Employee</b>{" "}
                                </td>
                                <td className="main-header-table-td" >
                                    {" "}
                                    <b>Product</b>{" "}
                                </td>
                                <td className="main-header-table-td">
                                    {" "}
                                    <b>Calls</b>{" "}
                                </td>
                                <td className="main-header-table-td">
                                    {" "}
                                    <b>Date</b>{" "}
                                </td>
                                <td className="main-header-table-td">
                                    {" "}
                                    <b>View Details</b>{" "}
                                </td>
                            </tr>
                        </thead>
                        <tbody className="main-table-row">
                            {callAvgTable && callAvgTable.length == 0 && (
                                <tr className="text-center">
                                    <td colSpan={6}>No data found</td>
                                </tr>
                            )}
                            {callAvgTable === "isLoading" && (
                                <tr>
                                    <td
                                        colSpan={6}
                                        style={{ backgroundColor: "#fff !important" }}
                                    >
                                        <Loader colored={true} />
                                    </td>
                                </tr>
                            )}
                            {callAvgTable && callAvgTable !== "isLoading" &&
                                callAvgTable.byTable &&
                                callAvgTable.byTable.map((row, i) => (
                                    <React.Fragment key={i}>
                                        <tr className="main-table-row" >
                                            <td className="main-table-employee">
                                                <div className="row align-items-center" style={{
                                                    width: "186px",
                                                    height: "40px"

                                                }}>
                                                    <div className="col d-flex align-items-center justify-content-center" style={{ height: "40px", width: "40px" }}>
                                                        <img src={SidebarIcons.person_icon} className="img-fluid" alt="Employee" style={{ height: "40px", width: "40px", borderRadius: "100%" }} />
                                                    </div>
                                                    <div className="col">
                                                        <p className="main-header-table-td-title">{row.employeeName}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="main-table-td">
                                                <p className="main-header-table-td-title">{row.productName}</p></td>
                                            <td className="main-table-td">
                                                <p className="main-header-table-td-title">{row.calls}</p></td>
                                            <td className="main-table-td"><p className="main-header-table-td-title">{moment(row.dateTime).format("MMM DD, YYYY")}</p></td>
                                            <td className="main-table-td">
                                                <button
                                                    style={{ background: "none" }}
                                                    onClick={() => {
                                                        handleDetails(row.taskIDs, i);
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="black"
                                                        fillOpacity="0.56"
                                                    >
                                                        <path d="M16.59 8.29688L12 12.8769L7.41 8.29688L6 9.70687L12 15.7069L18 9.70687L16.59 8.29688Z" />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>

                                        {
                                            showDetailsForRow[i] && (
                                                <>
                                                    <tr className="expansion-table-header">
                                                        <td className="expansion-table-header-td">
                                                            {" "}
                                                            <b>Title</b>{" "}
                                                        </td>
                                                        <td className="expansion-table-header-td">
                                                            {" "}
                                                            <b>Assigned to</b>{" "}
                                                        </td>
                                                        <td className="expansion-table-header-td">
                                                            {" "}
                                                            <b>Status</b>{" "}
                                                        </td>
                                                        <td className="expansion-table-header-td">
                                                            {" "}
                                                            <b>Priority</b>{" "}
                                                        </td>
                                                        <td className="expansion-table-header-td">
                                                            {" "}
                                                            <b>Action</b>{" "}
                                                        </td>
                                                    </tr>
                                                    {callAvgTasks === "isLoading" && (
                                                        <tr>
                                                            <td
                                                                colSpan={6}
                                                                style={{ backgroundColor: "#E6F4FF" }}
                                                            >
                                                                <Loader colored={true} />
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {callAvgTasks && callAvgTasks !== "isLoading" &&
                                                        callAvgTasks.tasks &&
                                                        callAvgTasks.tasks.map((row, i) => (
                                                            <tr style={{
                                                                width: "1088px",
                                                                height: "56px",
                                                                borderBottom: "0.5px solid #3A3A3A7A",
                                                                border: "0px 0px 1px 0px",
                                                                background: "#E6F4FF"

                                                            }}>
                                                                <td className="expansion-table-row-td"> <p className="expansion-table-row-td-title">{row.doctorName}</p>
                                                                    <tr><span style={{ color: "grey" }}>{row.practiceLocationName}</span>
                                                                    </tr>
                                                                </td>
                                                                <td className="expansion-table-row-td">
                                                                    <p className="expansion-table-row-td-title">{row.assigneeName}</p></td>
                                                                <td className="expansion-table-row-td">
                                                                    <p style={{
                                                                    // width: "95px",
                                                                    height: "24px",
                                                                    padding: "6px 8px 6px 8px",
                                                                    borderRadius: "4px 0px 0px 0px",
                                                                    background: "#7E7E7E1A",
                                                                }}>
                                                                    {row.taskStatusName}</p></td>
                                                                <td className="expansion-table-row-td">
                                                                    <p className="task-priority" style={{
                                                                        color: row.taskPriorityColor,
                                                                        width: "45px",
                                                                        height: "24px",
                                                                        padding: "3px 8px 6px 8px",
                                                                        borderRadius: "4px",
                                                                    background: "#FF00001A",
                                                                    }}>{row.taskPriorityName}
                                                                </p>
                                                                </td>
                                                                {/* <td>{moment(row.taskCreateDate).format("MMM DD, YYYY")}</td> */}
                                                                <td className="expansion-table-row-td">
                                                                    <Link
                                                                        to={{ pathname: "/admin/task/view/" + row.taskId }}
                                                                        className="btn btn-sm icons_adjust"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                                                                                fill="#505157"
                                                                            />
                                                                        </svg>
                                                                    </Link>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                </>
                                            )
                                        }
                                    </React.Fragment>

                                ))}
                        </tbody >
                    </table >

                    {/* Modal */}

                    < TabularFilter
                        show={isModalVisible}
                        onClose={closeModal}
                    //   choosefilters={choosefilters}
                    />

                </div >


            </div >
        </>
    );
}
