import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addDoctors,
  updateDoctors,
} from '../../actions/userActions/doctorAction'
import Loader from '../subComponents/Loader'
import { getCities } from '../../actions/userActions/cityAction'
import { getDoctorRanks } from '../../actions/userActions/doctorRankAction'
import { getDoctorSpecializations } from '../../actions/userActions/doctorSpecializationAction'
import { Redirect } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router-dom'
function AddDoctors() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    name: '',
    pmdcnumber: '',
    specialization: '',
    rank: '',
    contact1: '',
    contact2: '',
    city: '',
  }

  const [page, setPage] = useState(1)

  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })
  const cityBody = {
    filter: cityFilters,
    pageNumber: page,
    pageSize: 10,
  }

  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  useEffect(() => {
    dispatch(getCities(cityBody))
    .then(() => {
      dispatch(getDoctorRanks());
      dispatch(getDoctorSpecializations());
    })
    .catch(error => {
      console.error("Error fetching data:", error);
  });
    {
      location.doctors &&
        setFormValues({
          ...formValues,
          ['name']: location.doctors.row.name,
          // ['pmdcnumber']: location.doctors.row.pmdcnumber,
          ['pmdcnumber']: " ",
          ['specialization']: location.doctors.row.specialization,
          ['rank']: location.doctors.row.rank,
          ['contact1']: location.doctors.row.contact1,
          ['contact2']: location.doctors.row.contact2,
          ['city']: location.doctors.row.city,
          ['id']: location.id,
        })
    }
  }, [])

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      {
        location.doctors
          ? dispatch(updateDoctors(formValues))
          : dispatch(addDoctors(formValues))
      }
    }
  }, [formErrors])

  const addSubmitHandle = async (e) => {
    setFormErrors(validate(formValues))
    setIsSubmit(true)
  }

  const hanleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Name is required!'
    }
    // if (!values.pmdcnumber) {
    //   errors.pmdcnumber = 'PMDC number is required!'
    // }
    if (values.specialization === '') {
      errors.specialization = 'specialization is required!'
    }
    if (values.rank === '') {
      errors.rank = 'rank is required!'
    }
    if (!values.contact1) {
      errors.contact1 = 'contact1 is required!'
    }
    
    if (values.city === '') {
      errors.city = 'City is required!'
    }
    return errors
  }

  const allCities = useSelector((state) => state.cities_reducer.cities)
  const ranks = useSelector((state) => state.doctor_rank_reducer.ranks)
  const specializations = useSelector(
    (state) => state.doctor_specialization_reducer.specializations,
  )
  const addDoctor = useSelector((state) => state.doctors_reducer.addDoctors)
  const updateDoctor = useSelector((state) => state.doctors_reducer.doctors)
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  console.log(allCities)
  return (
    <>
      {addDoctor && addDoctor != 'isLoading' && isSubmit && (
        <Redirect to="/admin/customers" />
      )}
      {!permissions.includes('CanAddDoctor') && <Redirect to="/admin" />}
      {!permissions.includes('CanUpdateDoctor') && <Redirect to="/admin" />}
      {!location.doctors && <Redirect to={'/admin/AddCustomer'} />}

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />{' '}
              {location.doctors ? 'Update' : 'Add'} Customer
            </h2>
          </div>
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label required">Name</label>
              <input
                name="name"
                type="text"
                className="form-control"
                value={formValues.name}
                onChange={hanleChange}
              />
             <div style={{ color: 'red' }}>{formErrors.name}</div>
            </div>
          </div>
          <div className="col-md-12">
            {/* <div className="form-group">
              <label className="form-control-label required">PMDC Number</label>
              <input
                name="pmdcnumber"
                type="text"
                className="form-control"
                value={formValues.pmdcnumber}
                onChange={hanleChange}
              />
              <div style={{ color: 'red' }}>{formErrors.pmdcnumber}</div>
            </div> */}
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label required">Specialization</label>
              <select
                name="specialization"
                className="form-control"
                style={{ height: '45px' }}
                value={formValues.specialization}
                onChange={hanleChange}
              >
                <option value="" disabled>
                  {' '}
                  Select Specialization
                </option>
                {specializations !== 'isLoading' &&
                  specializations.map((row, i) => (
                    <option key={i} value={row.id}>
                      {row.name}
                    </option>
                  ))}
              </select>
              <div style={{ color: 'red' }}>{formErrors.specialization}</div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label required">Rank</label>
              <select
                name="rank"
                className="form-control"
                style={{ height: '45px' }}
                value={formValues.rank}
                onChange={hanleChange}
              >
                <option value="" disabled>
                  {' '}
                  Select Rank
                </option>
                {ranks !== 'isLoading' &&
                  ranks.map((row, i) => (
                    <option key={i} value={row.id}>
                      {row.name}
                    </option>
                  ))}
              </select>
              <div style={{ color: 'red' }}>{formErrors.rank}</div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label required">Contact 1</label>
              <input
                name="contact1"
                type="text"
                className="form-control"
                value={formValues.contact1}
                onChange={hanleChange}
              />
              <div style={{ color: 'red' }}>{formErrors.contact1}</div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label">contact 2</label>
              <input
                name="contact2"
                type="text"
                className="form-control"
                value={formValues.contact2}
                onChange={hanleChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label required">Select City</label>
              <select
                name="city"
                className="form-control"
                style={{ height: '45px' }}
                value={formValues.city}
                onChange={hanleChange}
              >
                <option value="" disabled>
                  {' '}
                  Select City
                </option>
                {allCities !== 'isLoading' &&
                  allCities?.cities?.map((row, i) => (
                    <option key={i} value={row.id}>
                      {row.name}
                    </option>
                  ))}
              </select>
              <div style={{ color: 'red' }}>{formErrors.city}</div>
            </div>
          </div>
          {addDoctor === 'isLoading' ? (
            <div className="col-lg-12 loginbttm">
              <Loader style={{ color: 'red' }} />
            </div>
          ) : (
            <div className="col-lg-12 loginbttm">
              <div className="col-lg-12 login-btm login-button d-block">
                <button
                  className="btn btn-outline-primary d-block m-auto"
                  onClick={addSubmitHandle}
                >
                  {' '}
                  {location.doctors ? 'Update' : 'Submit'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AddDoctors
