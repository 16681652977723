import {
  CITY_SUCCESS,
  CITY_FAIL,
  SET_MESSAGE,
  CITY_PENDING,
  ADD_CITY_SUCCESS,
  ADD_CITY_FAIL,
  ADD_CITY_PENDING,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_FAIL,
  UPDATE_CITY_PENDING,
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";

export const getCities = (body) => async (dispatch) => {
  dispatch({
    type: CITY_PENDING,
    payload: { cities: "isLoading" },
  });
  return await UserService.getCities(body)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: CITY_SUCCESS,
              payload: { cities: data.data.result[0] },
            })
          : dispatch({
              type: CITY_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: CITY_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

// Add Cities
export const addCities = (body) => async (dispatch) => {
  dispatch({
    type: ADD_CITY_PENDING,
    payload: { addCity: "isLoading" },
  });
  return await UserService.addCity(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("City added Successfully !", { autoClose: 2000 })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: ADD_CITY_SUCCESS,
              payload: { addCity: data.data.status },
            })
          : dispatch({
              type: ADD_CITY_FAIL,
              payload: { addCity: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_CITY_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

// Update Cities
export const updateCities = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_CITY_PENDING,
    payload: { updateCity: "isLoading" },
  });
  return await UserService.updateCity(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("City updated Successfully !", { autoClose: 2000 })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: UPDATE_CITY_SUCCESS,
              payload: { updateCity: data.data.status },
            })
          : dispatch({
              type: UPDATE_CITY_FAIL,
              payload: { updateCity: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_CITY_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};
