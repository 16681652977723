import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTaskDoctors, getTaskPracticeLocations, getTaskCities } from '../../../actions/userActions/tasks/taskAction';

import '../Tasks.css'
import Select from 'react-select'

 const TaskSection1 = (props) => {

  const dispatch = useDispatch();
  const allDoctorsBody= {
    "pageNumber": -1,
    "pageSize": 0,
    "textSearch": "",
    "cityId": props.city
  }
  useEffect (()=>{
    dispatch(getTaskCities())
    .then(() => {
        dispatch(getTaskPracticeLocations(props.city));
        dispatch(getTaskDoctors(allDoctorsBody))
    })
    .catch(error => {
        console.error("Error fetching data:", error);
    });
    
  },[props.city])

  const handleSelectChange = (e) => {
      props.setFormValues({...props.formValues,[e.name]:e.value});
      props.setSelectedValues({...props.selectedValues,[e.name]:{'value':e.value, 'label': e.label}});
      if(e.name == "doctorId"){
        props.setFormValues({...props.formValues,
            ["title"]:e.label ,
            ["doctorId"]:e.value });

        props.setSelectedValues({...props.selectedValues,
            ["doctorId"]:{'value':e.value, 'label': e.label} });

      } 
      if(e.name == "practiceLocationId"){
        props.setFormValues({...props.formValues,
            ["practiceLocationId"]:e.value,
            ["description"]:e.label});

        props.setSelectedValues({...props.selectedValues,
            ["practiceLocationId"]:{'value':e.value, 'label': e.label} });
      }
  }
    //checking inputs
    if(
        props.formValues["doctorId"] !== '' &&
        props.formValues["practiceLocationId"] !== '' 
        ){
            props.setBtnStatus(false);
    }
    else{
        props.setBtnStatus(true);
    }
  const getOptions = (data,name) =>{
    const getData = data && data !== "isLoading" ? data.map((row, i) => (
            {
            value:row.id ,
            label: row.name ? row.name : (row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : row.address ),
            name:name
            }
        )):
        [];
        return getData;
    }

  const taskDoctors =  useSelector(state => state.tasks_reducer.taskDoctors);
  const taskPracticeLocations =  useSelector(state => state.tasks_reducer.taskPracticeLocations);
 

  return (
      <div className='px-5'>
        <div className="col-md-12 p-0">
            <div className="form-group">
                <label>Customer</label>
                <Select
                    value={ props.selectedValues["doctorId"]}
                    isSearchable
                    options={getOptions(taskDoctors,"doctorId")}
                    name="doctorId"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select customer"
                    onChange={handleSelectChange}
                    />
                {/* {formErrors.gender} */}
            </div>
        </div>
        <div className="col-md-12 p-0">
            <div className="form-group">
                <label>Location</label>
                <Select
                    value={ props.selectedValues["practiceLocationId"]}
                    isSearchable
                    options={getOptions(taskPracticeLocations,"practiceLocationId")}
                    name="practiceLocationId"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select location"
                    onChange={handleSelectChange}
                    />
                {/* {formErrors.gender} */}
            </div>
        </div>
      </div>
    
  )
}

export default TaskSection1