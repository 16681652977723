import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addPermissions,
  updatePermissions,
  getAllPermissions,
  getAllPermissionsGroup,
} from "../../actions/userActions/permissionsAction";
import PreLoader from "../preLoader/PreLoader";
import Loader from "../subComponents/Loader";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import { InputLabel } from "@mui/material";
function AddPermissions() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const initialValues = { name: "", active : false, description: "", permission:[]};
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const body = {
    filter: {
      textSearch: "",
      permissionGroup: [0],
    },
    pageNumber: -1,
    pageSize: 0,
  };
  useEffect(() => {
    dispatch(getAllPermissions(body));
    if (location && location.permission) {
      setFormValues({
        ...formValues,
        name: location.permission.row.name,
        active: location.permission.row.active,
        description: location.permission.row.description,
        id: location.id,
        permission: location.permission.row.permission,
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (location.permission !== null && location.permission !== undefined) {
        dispatch(updatePermissions(formValues)).then(() => {
          dispatch(getAllPermissionsGroup());
        });
      } else {
        dispatch(addPermissions(formValues)).then(() => {
          dispatch(getAllPermissionsGroup());
        });
      }
    }
  }, [formErrors, isSubmit, location]);
  

  const addPermissionHandle = async (e) => {
    const errors = validate(formValues);
    setFormErrors(errors);
    setIsSubmit(true);
    if (Object.keys(errors).length === 0) {
      if (location.permission !== null && location.permission !== undefined) {
        dispatch(updatePermissions(formValues)).then(() => {
          dispatch(getAllPermissionsGroup());
        });
      } else {
        dispatch(addPermissions(formValues)).then(() => {
          dispatch(getAllPermissionsGroup());
        });
      }
    }
  };



  const handlePermissionChange = (e, newValue) => {
    const updatedFormValues = { 
      ...formValues, 
      permission: newValue 
    };

    setFormValues(updatedFormValues);
  };

  
  const handleChange = (e) => {
    const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
    if (name === "active" && formValues.active === true) {
      setFormValues({ ...formValues, [name]: false });
    } else if (name === "active" && formValues.active === false) {
      setFormValues({ ...formValues, [name]: true });
    }
  };

  const handleDescriptionChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.description) {
      errors.description = "Description is required!";
    }
    if (values.permission === "") {
      errors.permission = "Permission is required!";
    }
    return errors;
  };

  const allPermissions = useSelector(
    (state) => state.permissions_reducer.allPermissions
  );

  const addPermission = useSelector(
    (state) => state.permissions_reducer.addPermission
  );
  const updatePermission = useSelector(
    (state) => state.permissions_reducer.updatePermission
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  const totalPermissions = allPermissions.permissions;

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
        box-sizing: border-box;
        width: 320px;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${
          theme.palette.mode === "dark" ? grey[700] : grey[200]
        };
        box-shadow: 0px 2px 2px ${
          theme.palette.mode === "dark" ? grey[900] : grey[50]
        };
    
        &:hover {
          border-color: ${blue[400]};
        }
    
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${
            theme.palette.mode === "dark" ? blue[600] : blue[200]
          };
        }
    
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `
  );
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  return (
    <>
   
      {addPermission && addPermission != "isLoading" && isSubmit && (
        <Redirect to="/admin/permissions" />
      )}
      {!location.permission && <Redirect to="/admin/AddPermissions" />}
      {!permissions.includes("CanAddPermission") && <Redirect to="/admin" />}
      {!permissions.includes("CanUpdatePermission") && <Redirect to="/admin" />}


      
     
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-8"
                >
            <h2 className="d-inline-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              {location.permission ? formValues.name : "Add Permission"}
            </h2>
            <p style={{ fontSize: "14px" }}></p>
          </div>
         
        </div>
        <hr
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            height: "1.3px",
            marginTop: "15px",
          }}
        />

        <div className="row mt-5">
          <div className="col-6">
            <TextField
            style={{width:"100%"}}   
              className="first_textfield"
              id="outlined-controlled"
              label="Name *"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            {formErrors.name}
          </div>
          <div className="col-6">
            <div className="col-6">
            {allPermissions.permissions && allPermissions.permissions.length > 0 && (
 <Autocomplete
 
 multiple
 limitTags={2}
 id="multiple-limit-tags"
 value={formValues.permission.filter(permission => permission)} // Filter out undefined values
 options={totalPermissions}
//  isOptionEqualToValue={(option) => option.id} 
 getOptionLabel={(option) => option.name}
 onChange={handlePermissionChange}
 name="permission"
 renderInput={(params) => (
   <TextField
     {...params}
     label="Permissions"
     placeholder="Select Permissions"
     
   />
    )}
    sx={{ width: "450px" }}
    
      />
)}
{formErrors.permission}

            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-12">
            {" "}
            <InputLabel htmlFor="my-textarea">Description:</InputLabel>
            <Textarea
            style={{width:"100%"}}   
              value={formValues.description}
              name="description"
              onChange={handleDescriptionChange}
              aria-label="minimum height"
              minRows={6}
              placeholder="ADD Description"
            />
            {formErrors.description}
          </div>
          <div className="col-md-12 mt-5">
            <div className="form-group">
              <input
                name="active"
                style={{cursor:"pointer"}}
                type="checkbox"
                checked={formValues.active}
                className="ml-2 mt-2"
                onChange={handleChange}
              />

              <label
                style={{ paddingLeft: "10px",}}
                className="form-control-label"

              >
                Is permission group Active?
              </label>
            </div>
          </div>
        </div>
        {addPermission === "isLoading" || updatePermission === "isLoading" ? (
          <div className="col-lg-12">
            <Loader />
          </div>
        ) : (
          <div className="col-lg-12" style={{display:"flex", justifyContent:"flex-end", gap:"20px"}}>
            <button className="btn btn-secondary "
            onClick={history.goBack}>
              Cancel
            </button>

            <button type="button" className="btn btn-primary" onClick={addPermissionHandle}
            >
              {location.permission ? "Update" : "Submit"}
            </button>

            

          </div>
        
        )}
      </div>
    </>
  );
}

export default AddPermissions;
