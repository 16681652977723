import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getCountries } from "../../actions/userActions/countryAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
function Countries() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [cityPage, setCityPage] = useState(1);
  const [selected, setSelected] = useState({
    textSearch: "",
  });

  const [filters, setFilters] = useState({
    textSearch: "",
  });

  const body = {
    filter: filters,
    pageNumber: page,
    pageSize: 10,
  };

  const countries = useSelector((state) => state.countries_reducer.countries);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");
  console.log(countries);

  useEffect(() => {
    dispatch(getCountries());
  }, []);
  const filter_countries =
    countries !== "isLoading"
      ? countries.filter((country) => {
          return country.name.toLowerCase().includes(query.toLowerCase());
        })
      : [];
  const onSearch = (e) => {
    setQuery(e.target.value);
  };

  // const getPaginationNo = (pageNum) => {
  //   const object = []
  //   if (countries !== 'isLoading' && countries.length !== 0) {
  //     for (let i = 0; i < countries.page.totalPages; i++) {
  //       object.push(
  //         <button
  //           className={pageNum == i + 1 ? 'active' : ''}
  //           onClick={() => setPage(i + 1)}
  //         >
  //           {i + 1}
  //         </button>,
  //       )
  //     }
  //   }
  //   return object
  // }

  const handleSearch = (e) => {
    setSelected({ ...selected, ["textSearch"]: e.target.value });
    if (e.target.value.length >= 4) {
      setTimeout(() => {
        setFilters({ ...filters, ["textSearch"]: e.target.value });
      }, 500);
    }
    if (e.target.value == "") {
      setFilters({ ...filters, ["textSearch"]: e.target.value });
    }
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              Countries
            </h2>
          </div>
          <div className="col-md-4">
            <input
              className="search-input"
              style={{
                width: "100%",
                height: "100%",
                paddingLeft: "10px",
                border: "2px solid #ccc",
                borderRadius: "10px",
              }}
              type="text"
              placeholder="Search Country"
              onChange={onSearch}
              value={query}
            />
          </div>
          <div className="col-md-4">
            {/* {permissions.includes("CanAddCountry") && <button className='btn btn-primary float-right'>Add Country</button>} */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>
                    {" "}
                    <b>Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Short Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {/* {countries?.countries && countries?.countries.length == 0 && (
                  <tr className="text-center">
                    <td colSpan={6}>No data found</td>
                  </tr>
                )} */}
                {countries === "isLoading" && (
                  <tr>
                    <td
                      colSpan={3}
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {countries !== "isLoading" &&
                  filter_countries.map((row, i) => (
                    <tr key={i}>
                      <td>{row.name}</td>
                      <td>{row.shortName}</td>
                      <td>
                        <Link to={"#"}>
                          <i className="fas fa-edit" />
                        </Link>{" "}
                        {/* <Link className="ml-2" to={"#"}>
                          <i
                            className="fas fa-trash"
                            style={{ color: "red" }}
                          />
                        </Link> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12">
            <div className="task-pagination-wrapper">
              {getPaginationNo(page)}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Countries;
