import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTaskDetails } from "../../actions/userActions/tasks/taskAction";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import Loader from "../subComponents/Loader";
import SidebarIcons from "../nav/SidebarIcons";
import { GOOGLE_MAP_API_KEY } from "../../Constatns.js";
import "../tasks/TaskDetail.css";
import GMap from "../gMap/GMap";
import moment from "moment";
import TaskdetailIcons from "./TaskdetailIcons";
import DetailsModal from "./DetailsModal";

const TaskDetail = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams();
  const icon =
    "https://cdn2.iconfinder.com/data/icons/social-media-8/512/pointer.png";

  const [loadMap, setLoadMap] = useState(false);

  const [showComponent, setShowComponent] = useState(false);
  const [selectedSubTask, setSelectedSubTask] = useState(null);

  const handleClick = useCallback((subTask) => {
    setShowComponent(true);
    setSelectedSubTask(subTask);
  }, []);
  const handleClose = () => {
    setShowComponent(false);
  };

  const loadGoogleMapScript = (callback) => {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      callback();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", callback);
    }
  };

  useEffect(() => {
    dispatch(getTaskDetails(id));
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  const taskDetails = useSelector((state) => state.tasks_reducer.taskDetails);
  const markerList = [];

  taskDetails.length !== 0 &&
  taskDetails !== "isLoading" &&
  taskDetails.taskLog &&
  taskDetails.taskLog.map((e) => {
    if (e.longitude != null && e.latitude != null) {
      markerList.push({
        lat: e.latitude,
        lng: e.longitude,
        icon: icon,
        title: e.taskStatusName,
      });
    }
  });


  return (
    <>
      <div className="container mt-5">
        {taskDetails == "isLoading" ? (
          <Loader colored={true} />
        ) : (
          taskDetails !== "isLoading" && (
            <div className="task-details">
              <div className="task-details-content">
                <div className="top-heading row">
                  <div className="title col-6">
                    <h1>Task Details</h1>
                  </div>

                  <div className="col-4 d-flex statuses ml-2">
                    <div className="">
                      <p className="bg-billing ">
                        <span
                          className="text-billing"
                          style={{ height: "28px", width: "107px" }}
                        >
                          {taskDetails.statusName}
                        </span>
                      </p>
                    </div>
                    <div className="other-details">
                      <p className="priority_name">
                        <span
                          className="priority_color"
                          style={{
                            backgroundColor: taskDetails.priorityColor,
                            height: "28px",
                            width: "122px",
                            borderRadius: "4px",
                            color:"white"
                          }}
                        >
                          {taskDetails.priorityName}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-2 d-flex justify-content-end ml-auto"
                    style={{ height: "48px", width: "48px" }}
                  >
                    <i
                      class="bi bi-x-circle-fill"
                      style={{
                        cursor: "pointer",
                        fontSize: "24px",
                        color: "#B4B4B4",
                      }}
                      onClick={history.goBack}
                    ></i>
                  </div>
                </div>
                <div className="detail-container">
                  <div className="other-detail">
                    <img
                      className="image-wrapper"
                      src={SidebarIcons.productBlueIcon}
                    />{" "}
                    <span className="other-detail-title">Customer</span>
                    <p className="other-detail-text">
                      {taskDetails.doctorName
                        ? taskDetails.doctorName
                        : "John Doe"}
                    </p>
                  </div>

                  <div className="other-detail">
                    <img
                      className="image-wrapper"
                      src={TaskdetailIcons.destinationIconNew}
                    />{" "}
                    <span className="other-detail-title">Destination</span>
                    <p className="other-detail-text">
                      {taskDetails.practiceLocationName
                        ? taskDetails.practiceLocationName
                        : "John Doe"}
                    </p>
                  </div>

                  <div className="other-detail">
                    <img
                      className="image-wrapper"
                      src={TaskdetailIcons.assignedToIcon}
                    />{" "}
                    <span className="other-detail-title">Assigned by</span>
                    <p className="other-detail-text">
                      {taskDetails.assignedByName
                        ? taskDetails.assignedByName
                        : "John Doe"}
                    </p>
                  </div>

                  <div className="other-detail">
                    <img
                      className="image-wrapper"
                      src={TaskdetailIcons.employeeIcon}
                    />{" "}
                    <span className="other-detail-title">Assigned to</span>
                    <p className="other-detail-text">
                      {taskDetails.assignedToName
                        ? taskDetails.assignedToName
                        : "John Doe"}
                    </p>
                  </div>

                  <div className="other-detail">
                    <img
                      className="image-wrapper"
                      src={TaskdetailIcons.productsIcon}
                    />{" "}
                    <span className="other-detail-title">Products</span>
                    {taskDetails.taskProducts &&
                      taskDetails.taskProducts.length > 0 && (
                        <ul>
                          {taskDetails.taskProducts.map((product) => (
                            <li key={product.id} className="">
                              <p className="other-detail-text">
                                {product.productName
                                  ? product.productName
                                  : "John Doe"}
                              </p>
                            </li>
                          ))}
                        </ul>
                      )}
                  </div>

                  <div className="other-detail">
                    <img
                      className="image-wrapper"
                      src={TaskdetailIcons.createdonIcon}
                    />{" "}
                    <span className="other-detail-title">Created on</span>
                    <p className="other-detail-text">
                      {taskDetails.createdAt
                        ? moment(taskDetails.createdAt).format("MMM DD, YYYY")
                        : "John Doe"}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="subtasks-container">
                  <div className="subtasks-title">Subtasks</div>
                  {taskDetails.taskSubTasks && (
                    <ul>
                      {taskDetails.taskSubTasks.map((subTask) => (
                        <li key={subTask.id}>
                          <div className="other-detail">
                            <img
                              className="image-wrapper"
                              src={TaskdetailIcons.subtasksIconNew}
                              alt="Subtask Icon"
                            />
                            <span className="other-detail-title">
                              {subTask.subTaskName
                                ? subTask.subTaskName
                                : "Test Sub-Task"}
                            </span>
                            <p className="other-detail-text">
                              {subTask.remarks
                                ? subTask.remarks
                                : "Sub task detail here"}
                              <button
                                style={{ background: "none" }}
                                onClick={() => handleClick(subTask)}
                              >
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_2876_13973)">
                                    <path
                                      d="M3 17.2514V21.0014H6.75L17.815 9.93641L14.065 6.18641L3 17.2514ZM20.705 7.04641C21.095 6.65641 21.095 6.02141 20.705 5.63141L18.37 3.29641C17.98 2.90641 17.345 2.90641 16.955 3.29641L15.125 5.12641L18.875 8.87641L20.705 7.04641Z"
                                      fill="#002D72"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2876_13973">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>
                              {showComponent && (
                                <DetailsModal
                                  show={showComponent}
                                  selectedSubTask={selectedSubTask}
                                  onClose={handleClose}
                                />
                              )}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {taskDetails.taskLog && (
                  <div className="statusHistory-container">
                    <div className="statusHistory-title">Status History</div>

                    {taskDetails.taskLog.map((log) => (
                      <>
                        <div className="other-detail">
                          <img
                            className="image-wrapper"
                            src={TaskdetailIcons.statusIcon}
                          />{" "}
                          <span className="other-detail-title">Status</span>
                          <p
                            className="other-detail-text created_name"
                            style={{
                              height: "30px",
                              width: "114px",
                              justifyContent: "center",
                            }}
                          >
                            {log.taskStatusName
                              ? log.taskStatusName
                              : "John Doe"}
                          </p>
                        </div>

                        <div className="other-detail">
                          <img
                            className="image-wrapper"
                            src={TaskdetailIcons.employeeIcon}
                          />{" "}
                          <span className="other-detail-title">Updated by</span>
                          <p className="other-detail-text">
                            {log.updatedByName ? log.updatedByName : "John Doe"}
                          </p>
                        </div>

                        <div className="other-detail">
                          <img
                            className="image-wrapper"
                            src={TaskdetailIcons.createdonIcon}
                          />{" "}
                          <span className="other-detail-title">Updated on</span>
                          <p className="other-detail-text">
                            {log.updatedDate
                              ? moment(log.updatedDate).format(
                                  "MMM DD, YYYY - h:mma"
                                )
                              : "John Doe"}
                          </p>
                        </div>
                        {log.latitude && log.longitude ? (
  <div className="map-container" id="map">
    {!loadMap ? (
      <div>Loading...</div>
    ) : (
      <GMap markerList={markerList} />
    )}
  </div>
) : null}
                      </>
                    ))}
                  </div>
                )}
                
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default TaskDetail;
