import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  updateSubTask,
  getTaskDetails,
} from "../../actions/userActions/tasks/taskAction";
import "../tasks/TaskDetail.css";
import { useHistory } from "react-router-dom";

const DetailsModal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [remarks, setRemarks] = useState("");
  const [showModal, setShowModal] = useState(true);

  const closeDialog = () => {
    if (!remarks.trim()) {
      alert("Please enter a value in the textarea.");
      return;
    }
    const body = {
      taskId: props.selectedSubTask.taskId,
      subTaskId: props.selectedSubTask.subTaskId,
      remarks: remarks,
      subTaskName: props.selectedSubTask.subTaskName,
    };
    dispatch(updateSubTask(body)).then(() => {
      dispatch(getTaskDetails(props.selectedSubTask.taskId));
      props.onClose();
    });
  };

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleCloseIcon = () => {
    setShowModal(false);
    props.onClose();
  };
  return (
    <>
      {props.show && (
        <>
          <div className="dialog-overlay">
            <div className="dialog-content">
              <div className="row">
                <div className="col-9 d-flex">
                  <label htmlFor="">
                    {" "}
                    Enter Remarks about {props.selectedSubTask.subTaskName}
                  </label>
                </div>
                <div className="col-3 d-flex justify-content-end ml-auto">
                  <div style={{ height: "48px", width: "48px" }}>
                    <i
                      className="bi bi-x-circle-fill"
                      style={{
                        cursor: "pointer",
                        fontSize: "24px",
                        color: "#B4B4B4",
                      }}
                      // onClick={() => history.goBack()
                      onClick={handleCloseIcon}
                    ></i>
                  </div>
                </div>
              </div>
              <textarea
                className="remarks_textarea"
                placeholder="Enter Remarks"
                value={remarks}
                onChange={handleRemarksChange}
              />
              <button className="btn_remarks" onClick={closeDialog}>
                Add Remarks
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DetailsModal;
