import React from 'react'
import './Login.css'
import {useState,useEffect} from 'react' ;
import {useSelector, useDispatch} from 'react-redux'
import {login} from '../../../actions/auth/auth' 
import {Redirect} from 'react-router-dom';
import { ToastContainer} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';  
import Loader from '../../subComponents/Loader';
import logo from '../../../assets/images/logo_text.svg'
import employeeIcon from '../../../assets/images/employee_icon.png'
import passwordIcon from '../../../assets/images/pass_icon.png' 


function Login() {
    const initialValues = {email: "", password: ""};
    const [formValues,setFormValues] = useState(initialValues);
    const [formErrors,setFormErrors] = useState({});
    const [isSubmit,setIsSubmit] = useState(false);
    const [passwordType, setPasswordType] = useState('password')
    
    const dispatch = useDispatch();
    const auth =  useSelector(state => state.auth);
    const user = JSON.parse(localStorage.getItem("user"));



    useEffect(()=>{
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
              event.preventDefault();
      
              // 👇️ call submit function
              loginHandle();
            }
          };
      
          document.addEventListener('keydown', keyDownHandler);
      
          return () => {
            document.removeEventListener('keydown', keyDownHandler);
          };
    })
    const togglePassword = () => {
        if (passwordType === 'password') {
          setPasswordType('text')
          return
        }
        setPasswordType('password')
      }
    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmit){
            dispatch(login(formValues.email,formValues.password));
        }
    },[formErrors])

    const loginHandle = async() =>{
        setFormErrors(validate(formValues)) ;
        setIsSubmit(true);
    }
    const hanleChange = (e) =>{
        const {name,value} = e.target;
        setFormValues({...formValues,[name]:value});
        {user && !user.status && localStorage.clear()}
    }
    const validate = (values) => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!values.email){
            errors.email = "Email is required!"
        } else if (!emailRegex.test(values.email)) {
          errors.email = "Invalid email!";
        }
        if(!values.password){
            errors.password = "Password is required!"
        }
        return errors;
    }
    if(user && user.status){
        return <Redirect to="/admin"/>
    }
    else return (
      <>
        {user && !user.status && <ToastContainer />}

        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-6">
              <div className="logo-section">
                <div className="border-div">
                  <img style={{ height: "90%" }} src={logo} alt="" />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="login-form">
                <div className="form">
                  <div className="input-wrapper">
                    <label htmlFor="#id">
                      <img src={employeeIcon} alt="" /> Employee Email
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="text"
                      value={formValues.email}
                      onChange={hanleChange}
                    />
                    <p style={{ color: 'red' }}>{formErrors.email}</p>
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="#password">
                      {" "}
                      <img src={passwordIcon} alt="" /> Password
                    </label>
                    <div className="password-input-wrapper">
                      <input
                        name="password"
                        id="password"
                        type={passwordType}
                        value={formValues.password}
                        onChange={hanleChange}
                      />
                      <span onClick={togglePassword} >
                        {passwordType === "password"? (
                          <i className="bi bi-eye-slash"></i>
                        ) : (
                          <i className="bi bi-eye"></i>
                        )}
                      </span>
                    </div>
                    <p style={{ color: 'red' }}>{formErrors.password}</p>
                  </div>

                  <div className="input-wrapper">
                    <button
                      type="submit"
                      disabled={auth.isLogging ? true : false}
                      className="mt-5"
                      onSubmit={loginHandle}
                      onClick={loginHandle}
                    >
                      {auth.isLogging ? (
                        <div className="mt-2">
                          <Loader />
                        </div>
                      ) : (
                        "Login"
                      )}
                    </button>
                    <p className="text-right mt-3">
                      {" "}
                      <a href="/forgotPassword" style={{ color: "black" }}>
                        Forgot Password?
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-12 p-0">
              <div className="footer">
                <p className="text-center">
                  &#9400; Copyright Field Force 2024
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div classNameName="body">
            <div classNameName="container">
                <div classNameName="row">
                    <div classNameName="col-lg-3 col-md-2"></div>
                    <div classNameName="col-lg-6 col-md-8 login-box">
                        <div classNameName="col-lg-12 login-key mt-5">
                        <img src={logo}/>
                        </div>
                        <div classNameName="col-lg-12 login-title">
                            
                        </div>

                        <div classNameName="col-lg-12 login-form">
                            <div classNameName="col-lg-12 login-form">
                                    <div classNameName="form-group">
                                        <label classNameName="form-control-label">USERNAME</label>
                                        <input name='id' type="text" classNameName="form-control"
                                        value={formValues.id}  
                                        onChange={hanleChange}
                                        />
                                        <p>{formErrors.id}</p>
                                    </div>
                                    <div classNameName="form-group">
                                        <label classNameName="form-control-label">PASSWORD</label>
                                        <input name='password' type="password" classNameName="form-control" 
                                        value={formValues.password}  
                                        onChange={hanleChange}/>
                                        <p>{formErrors.password}</p>
                                    </div>
                                    {auth.isLogging ? 
                                    <div classNameName="col-lg-12 loginbttm">
                                        <Loader/>
                                    </div>
                                    :      
                                    <div classNameName="col-lg-12 loginbttm"> 
                                        <div classNameName="col-lg-12 login-btm login-button d-block">
                                            <button classNameName="btn btn-outline-primary d-block m-auto"
                                            onClick={loginHandle}
                                            >  LOGIN</button>
                                        </div>
                                    </div> 
                                    }
                                </div>
                            </div>
                        <div classNameName="col-lg-3 col-md-2"></div>
                    </div>
                </div>
            </div>
        </div> */}
      </>
    );
}

export default Login
