import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getOfficeLocations } from '../../actions/userActions/officelocationAction'
import Loader from '../subComponents/Loader'
import Search from '../subComponents/search/Search'
import Pagination from '../paginatioin/pagination'
import './OfficeLocation.css'

function OfficeLocations() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [isExpanded, setIsExpanded] = useState(false);

  
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const [selected, setSelected] = useState({
    textSearch: '',
  })
  const [filters, setFilters] = useState({
    textSearch: '',
  })
  useEffect(() => {
    dispatch(getOfficeLocations(body))
  }, [page, filters])

  const officeLocations = useSelector(
    (state) => state.officeLocations_reducer.officeLocations,
  )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const [query, setQuery] = useState('')

  
const officeLocation_data = officeLocations.officeLocations;
  const filtered_officeLocations =
  officeLocation_data && officeLocation_data !== "isLoading" 
    ? officeLocation_data.filter(officeLocation => {
        return officeLocation.address && officeLocation.address.toLowerCase().includes(query.toLowerCase());
      })
    : [];


  const body = {
    filter: filters,
    pageNumber: page,
    pageSize: 10,
  }

  const getPaginationNo = (pageNum) => {
    const object = []
    if (officeLocations !== 'isLoading' && officeLocations.length !== 0) {
      for (let i = 0; i < officeLocations.page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }

  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 4) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }


  return (
    <>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <h2 className="d-inline-block">Office Locations</h2>
            <p className="employees_set">Manage all office locations here </p>
          </div>
          <div className="col-md-4">
            <Search
              setText={setQuery}
              text={query}
              placeholder="Search Location"
            />
          </div>
          <div className="col-md-3">
            {permissions.includes("CanAddOfficeLocation") && (
              <Link
                to={"/admin/AddOfficeLocations"}
                className="btn btn-primary float-right"
              >
                + Add Office Location
              </Link>
            )}
          </div>
          <div className="col-md-12">
            {officeLocations !== "isLoading" && (
              <div className="task-pagination-wrapper">
                <Pagination
                  total={
                    officeLocations.page != undefined &&
                    officeLocations.page.totalPages *
                      officeLocations.page.pageSize
                  }
                  current={
                    officeLocations.page != undefined &&
                    officeLocations.page.pageNumber
                  }
                  pageSize={
                    officeLocations.page != undefined &&
                    officeLocations.page.pageSize
                  }
                  onChange={(current) => setPage(current)}
                  showLessItems
                />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>
                    <b>Address</b>
                  </td>
                  <td>
                    <b>City</b>
                  </td>
                  <td>
                    <b>Contact No.</b>
                  </td>
                  <td>
                    <b>Head Office</b>
                  </td>
                  <td>
                    <b>Actions</b>
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {officeLocations?.officeLocations &&
                  officeLocations?.officeLocations.length == 0 && (
                    <tr className="text-center">
                      <td colSpan={6}>No data found</td>
                    </tr>
                  )}
                {officeLocations == "isLoading" && (
                  <tr>
                    <td
                      colSpan={6}
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {filtered_officeLocations !== "isLoading" &&
                  filtered_officeLocations.map((row, i) => (
                    <tr
                      key={i}
                      style={{
                        backgroundColor: "transparent",
                        "--x-table-accent-bg": "transparent",
                      }}
                    >
                      <td
                        style={{
                          whiteSpace: "pre-line",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          verticalAlign: "middle",
                        }}
                      >
                        <div
                          style={{
                            maxHeight:
                              isExpanded || row.address.length <= 100
                                ? "none"
                                : "3.4em",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          {row.address}
                          {row.address.length > 100 && (
                            <span
                              className="toggle_expand"
                              onClick={toggleExpansion}
                            >
                              ...
                            </span>
                          )}
                        </div>
                      </td>

                      <td style={{ verticalAlign: "middle" }}>
                        {row.cityName}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {row.contact1}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {row.isHeadOffice ? "Yes" : "No"}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <Link
                          to={{
                            pathname: "/admin/UpdateOfficeLocations",
                            officeLocation: { row },
                            id: row.id,
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z"
                              fill="#505157"
                            />
                          </svg>
                        </Link>{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {officeLocations !== "isLoading" && (
          <div className="task-pagination-wrapper">
            <Pagination
              total={
                officeLocations.page != undefined &&
                officeLocations.page.totalPages * officeLocations.page.pageSize
              }
              current={
                officeLocations.page != undefined &&
                officeLocations.page.pageNumber
              }
              pageSize={
                officeLocations.page != undefined &&
                officeLocations.page.pageSize
              }
              onChange={(current) => setPage(current)}
              showLessItems
            />
          </div>
        )}
      </div>
    </>
  );
}

export default OfficeLocations
