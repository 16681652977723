import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getCities } from '../../actions/userActions/cityAction'
import { getPracticeLocations } from '../../actions/userActions/practiceLocationAction'
import Loader from '../subComponents/Loader'
import Search from '../subComponents/search/Search'
import { TextField, Autocomplete } from '@mui/material'
import Pagination from '../paginatioin/pagination'

function PracticeLocations() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedCity, setSelectedCity] = useState('1')

  const [page, setPage] = useState(1)
  const [cityPage, setCityPage] = useState(1)
  const [selected, setSelected] = useState({
    textSearch: '',
    city: '',
  })

  const [filters, setFilters] = useState({
    textSearch: '',
    city: '1',
  })
  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })

  const body = {
    filter: filters,
    pageNumber: page,
    pageSize: 10,
  }
  const cityBody = {
    filter: cityFilters,
    pageNumber: cityPage,
    pageSize: 100,
  }
  useEffect(() => {
    dispatch(getCities(cityBody))
    .then(() => {
      dispatch(getPracticeLocations(body))
    })
  }, [])

  const practiceLocations = useSelector(
    (state) => state.practiceLocations_reducer.practiceLocations,
  )
  const allCities = useSelector((state) => state.cities_reducer.cities)
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const [query, setQuery] = useState('')

  const getPaginationNo = (pageNum) => {
    const object = []
    if (practiceLocations !== 'isLoading' && practiceLocations?.length !== 0) {
      for (let i = 0; i < practiceLocations?.page?.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }
  useEffect(() => {
    dispatch(getPracticeLocations(body))
  }, [page, filters])

  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 4) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }
  console.log(allCities)

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />{' '}
              Organization Locations
            </h2>
          </div>
          <div className="col-md-4">
            <input
              className="search-input"
              style={{
                width: '100%',
                height: '90%',
                paddingLeft: '10px',
                border: '2px solid #ccc',
                borderRadius: '10px',
              }}
              type="text"
              placeholder="Search Location"
              onChange={handleSearch}
              value={selected.textSearch}
            />
          </div>
          <div
              className="col-2 PL-select-city mt-1"
              style={{
                float: 'right',
                // width: '200px',
                marginRight: '0px',
              }}
            >
              {allCities && allCities !== 'isLoading' && (
                <Autocomplete
                  id="grouped-demo"
                  name="type"
                  size="small"
                  style={{ maxWidth: '350px' }}
                  onChange={(e, newVal) => {
                    setFilters({ ...selected, ['city']: newVal?.id })
                  }}
                  options={allCities.cities}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Select City" />
                  )}
                />
              )}
            </div>
          <div className="col-md-2">
            {permissions.includes('CanAddPracticeLocation') && (
              <Link
                to={'/admin/AddOrganizationLocations'}
                className="btn btn-primary float-right"
              >
                Add Location
              </Link>
            )}
           
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>
                    {' '}
                    <b>Name</b>{' '}
                  </td>
                  <td>
                    {' '}
                    <b>Address</b>{' '}
                  </td>
                  <td>
                    {' '}
                    <b>Action</b>{' '}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {practiceLocations?.practiceLocations &&
                  practiceLocations?.practiceLocations?.length == 0 && (
                    <tr className="text-center">
                      <td colSpan={6}>No data found</td>
                    </tr>
                  )}
                {practiceLocations === 'isLoading' && (
                  <tr>
                    <td
                      colSpan={3}
                      style={{ backgroundColor: '#fff !important' }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {practiceLocations !== 'isLoading' &&
                  practiceLocations?.length !== 0 &&
                  practiceLocations?.practiceLocations?.length !== 0 &&
                  practiceLocations?.practiceLocations?.map((row, i) => (
                    <tr key={i}>
                      <td>{row.name}</td>
                      <td>{row.address}</td>
                      <td>
                        <Link
                          to={{
                            pathname: '/admin/UpdateOrganizationLocations',
                            practiceLocation: { row },
                            id: row.id,
                          }}
                        >
                          <i className="fas fa-edit" />
                        </Link>{' '}
                        {/* <Link className="ml-2" to={'#'}>
                          <i
                            className="fas fa-trash"
                            style={{ color: 'red' }}
                          />
                        </Link> */}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {practiceLocations !== 'isLoading' && (
          <div className="task-pagination-wrapper">
            <Pagination
              total={
                practiceLocations?.page != undefined &&
                practiceLocations?.page.totalPages *
                  practiceLocations?.page.pageSize
              }
              current={
                practiceLocations?.page != undefined &&
                practiceLocations?.page.pageNumber
              }
              pageSize={
                practiceLocations?.page != undefined &&
                practiceLocations?.page.pageSize
              }
              onChange={(current) => setPage(current)}
              showLessItems
            />
          </div>
        )}
        {/* <div className="row">
          <div className="col-12">
            <div className="task-pagination-wrapper">
              {getPaginationNo(page)}
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default PracticeLocations
