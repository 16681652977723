import {
  CUSTOMER_RANK_SUCCESS,
  CUSTOMER_RANK_FAIL,
  CUSTOMER_RANK_PENDING,
} from "../../actions/types";

const initialState = {
  customerRank: [],
};

const customer_rank_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case CUSTOMER_RANK_SUCCESS:
      return {
        ...state,
        customerRank: payload.customerRank,
      };
    case CUSTOMER_RANK_PENDING:
      return {
        ...state,
        customerRank: payload.customerRank,
      };
    case CUSTOMER_RANK_FAIL:
      return {
        ...state,
        customerRank: [],
      };
    default:
      return state;
  }
};
export default customer_rank_reducer;
