import React, { useEffect } from "react";
import "../Tasks.css";
import TaskSection1 from "./TaskSection1";
import TaskSection2 from "./TaskSection2";
import TaskSection3 from "./TaskSection3";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getTaskCities } from "../../../actions/userActions/tasks/taskAction";
import { addTasks } from "../../../actions/userActions/tasks/taskAction";


function AddTasks(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const taskCities = useSelector(
    (state) => state.tasks_reducer?.taskCities[0]?.cities
  );
  const addTask = useSelector((state) => state.tasks_reducer.addTask);
  const user = JSON.parse(localStorage.getItem("user"));
  const [city, setCity] = useState(1);
  const [isSubmit, setSubmit] = useState(false);
  const initialValues = {
    employeeId: "",
    title: "",
    description: "",
    doctorId: "",
    practiceLocationId: "",
    assignedTo: "",
    priority: "",
    type: location.taskType,
    products: [],
    subTasks: [],
    visitDate: new Date(),
    createdByName: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [selectedValues, setSelectedValues] = useState(initialValues);

  useEffect(() => {
    // dispatch(getTaskCities());
    setSubmit(false);
    setFormValues({ ...formValues, ["employeeId"]: user.result[0].user.id, ["createdByName"]: user.result[0].user.firstName + " " + user.result[0].user.lastName});
  }, []);

  const [page, setPage] = useState(0);
  const [btnStatus, setBtnStatus] = useState(true);
  const pageTitles = ["section 1", "section 2", "section 3"];
  const getSection = (pageNo) => {
    if (pageNo == 0) {
      return (
        <TaskSection1
          formValues={formValues}
          setFormValues={setFormValues}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          setBtnStatus={setBtnStatus}
          city={city}
        />
      );
    } else if (pageNo == 1) {
      return (
        <TaskSection2
          formValues={formValues}
          setFormValues={setFormValues}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          setBtnStatus={setBtnStatus}
        />
      );
    } else {
      return (
        <TaskSection3
          formValues={formValues}
          setFormValues={setFormValues}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          setBtnStatus={setBtnStatus}
        />
      );
    }
  };
  const nextPageHandler = async (e) => {
    if (page <= 1) {
      setPage((currentPage) => currentPage + 1);
    } else {
      try {
        await dispatch(addTasks({ ...formValues, city }));
        setSubmit(true);
        // Navigate to the next page upon successful task addition
        history.push("/admin/tasks");
      } catch (error) {
        // Handle the error, if needed
        console.error("Error adding task:", error);
      }
    }
  };
  
  const handleChange = (e) => {
    setCity(e.target.value);
  };
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <h2 className="d-inine-block float-left">
            <i
              onClick={history.goBack}
              className="fas fa-arrow-left"
              aria-hidden="true"
              style={{ cursor: "pointer" }}
            />{" "}
            Add Task
          </h2>
        </div>
        <div className="col-md-6">
          <div className="PL-select-city float-right">
            <span>Select City:</span>
            <select
              name="cityId"
              className="form-control"
              style={{ height: "45px" }}
              onChange={handleChange}
              value={city}
            >
              {/* <option value="" disabled>
                {" "}
                Select City
              </option> */}
              {taskCities !== "isLoading" &&
                taskCities?.map((row, i) => (
                  <option key={i} value={row.id}>
                    {row.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <h2 className="text-center">{pageTitles[page]}</h2>
        <div classname="progress p-0 m-auto my-5" style={{ width: "50%" }}>
          <div
            classname="progress-bar"
            role="progressbar"
            style={{
              width: page == 0 ? "33.3%" : page == 1 ? "66.6%" : "100%",
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        {getSection(page)}
        <button
          disabled={page === 0}
          className="btn btn-primary ml-4"
          onClick={() => {
            setPage((currentPage) => currentPage - 1);
          }}
          style={{ width: "150px" }}
        >
          Previous
        </button>
        <button
          disabled={btnStatus}
          className="btn btn-primary ml-3"
          onClick={nextPageHandler}
          style={{ width: "150px" }}
        >
          {page === 2 ? "Submit" : "Next"}
        </button>
      </div>
    </div>
  );
}

export default AddTasks;
