import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Filter.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { getProducts } from "../../../actions/userActions/productAction";
import { getEmployees } from "../../../actions/userActions/employeeAction";
import { getCallAveragTable } from "../../../actions/userActions/callAverageTab";
import moment from "moment";
import React, { useState, useEffect } from "react";

export function TabularFilter({ show, onClose }) {
 
    const [formError, setFormError] = useState({});
    const currentDate = moment();
    const dispatch = useDispatch();
    const [Filters, setFilters] = useState({
      productsIds: [],
      employeeIds: [],
      fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment(currentDate).format("YYYY-MM-DD"),
    });
    const productBody = {
      filter: {
        textSearch: "",
      },
      pageNumber: -1,
      pageSize: 0,
    };
    useEffect(() => {
      if (show){
        dispatch(getProducts(productBody))
        .then(() => {
          dispatch(getEmployees());
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
        
      }
      
      
    }, [show]);
  
    const getOptions = (data, name, activecheck) => {
      const getData =
        data && data !== "isLoading"
          ? !activecheck
            ? data.map((row, i) => ({
                value: row.id,
                label: row.name
                  ? row.name
                  : row.firstName && row.lastName
                  ? `${row.firstName} ${row.lastName}`
                  : row.address,
                name: name,
              }))
            : data
                .filter((row) => row.active === true)
                .map((row, i) => ({
                  value: row.id,
                  label: row.name
                    ? row.name
                    : row.firstName && row.lastName
                    ? `${row.firstName} ${row.lastName}`
                    : row.address,
                  name: name,
                }))
          : [];
  
      return getData;
    };
  
    const [selected, setSelected] = useState({
      textSearch: "",
      isActive: true,
    });
  
    const handleChange = (e, name) => {
      if (name == "reset") {
        setSelected({
          ...Filters,
          productsIds: [],
          employeeIds: [],
        });
        setFilters({
          ...Filters,
          productsIds: [],
          employeeIds: [],
        });
      } else {
          setFilters({ ...Filters, [name]: e.map((data, i) => data.value) });
          setSelected({
            ...selected,
            [name]: e.map((data, i) => ({
              value: data.value,
              label: data.label,
              name: data.name,
            })),
          });
         
        // else {
        //   setFilters({ ...Filters, [name]: e.value });
        //   setSelected({
        //     ...selected,
        //     [name]: {
        //       value: e.value,
        //       label: e.label,
        //       name: e.name,
        //     },
        //   });
        // }
      }
    };
  
    const products = useSelector((state) => state.products_reducer.products);
  
    const employees = useSelector((state) => state.employees_reducer.employees);
    // date change handler for date picker
    const dateChange = (start, end) => {
      let startDate = start.format("YYYY-MM-DD");
      let endDate = end.format("YYYY-MM-DD");
      setFilters({
        ...Filters,
        ["fromDate"]: startDate,
        ["toDate"]: endDate,
      });
    };
  
    const validateForm = () => {
      let errors = {};
      let isValid = true;
  
      if (Filters.productsIds.length === 0) {
        errors.products = "Product is required";
        isValid = false;
      }
  
      if (!Filters.employeeIds) {
        errors.employee = "Employee is required.";
        isValid = false;
      }
  
      setFormError(errors);
      return isValid;
    };
  
    const handleApply = () => {
      if (validateForm()) {
        // body for CallAvragEmployee
        const body = {
          tableFilters: {
            productsIds: Filters.productsIds,
            employeeIds: Filters.employeeIds,
          },
          startTime: Filters.fromDate,
          endTime: Filters.toDate,
        };
        dispatch(getCallAveragTable(body));
        // choosefilters(Filters.productsIds);
        onClose();
      }
    };
  
    return (
      <div className="modal-container">
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>Filter Results</Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            <div className="row">
              <div className="col-6">
                <Select
                  value={selected.productsIds}
                  isSearchable
                  isMulti
                  options={getOptions(products.products, "products", false)}
                  name="product"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Product"
                  onChange={(e) => handleChange(e, "productsIds")}
                />
                {formError.products && (
                  <span style={{ color: "red" }}>{formError.products}</span>
                )}
              </div>
              <div className="col-6">
                <Select
                  value={selected.employeeIds}
                  isSearchable
                  isMulti
                  options={getOptions(employees, "employees", false)}
                  name="employee"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Employee"
                  onChange={(e) => handleChange(e, "employeeIds")}
                />
                {formError.employee && (
                  <span style={{ color: "red" }}>{formError.employee}</span>
                )}
              </div>
              <div className="col-6 mt-5">
                <DateRangePicker
                  initialSettings={{
                    startDate: moment().subtract(6, "days").toDate(),
                    endDate: moment().toDate(),
                    ranges: {
                      Today: [moment().toDate(), moment().toDate()],
                      Yesterday: [
                        moment().subtract(1, "days").toDate(),
                        moment().subtract(1, "days").toDate(),
                      ],
                      "Last 7 Days": [
                        moment().subtract(6, "days").toDate(),
                        moment().toDate(),
                      ],
                      "Last 30 Days": [
                        moment().subtract(29, "days").toDate(),
                        moment().toDate(),
                      ],
                      "This Month": [
                        moment().startOf("month").toDate(),
                        moment().endOf("month").toDate(),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month").toDate(),
                        moment().subtract(1, "month").endOf("month").toDate(),
                      ],
                      "Last Year": [
                        moment().subtract(1, "year").startOf("year").toDate(),
                        moment().subtract(1, "year").endOf("year").toDate(),
                      ],
                    },
                  }}
                  onCallback={dateChange}
                >
                  {/* <button className="btn btn-primary btn-sm">Date Range</button> */}
                  <input
                  type="text"
                  className="form-control"
                  placeholder="Select Date Range"
                />
                </DateRangePicker>
              </div>
            </div>
          </Modal.Body>
  
          <Modal.Footer>
            <Button variant="primary" size="sm" onClick={handleApply}>
              Apply
            </Button>
            <Button
              variant="secondary"
              size="sm"
              onClick={(e) => handleChange(e, "reset")}
            >
              Reset
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }