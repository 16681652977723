import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getEmployees } from '../../actions/userActions/employeeAction'
import './Employee.css'
import Loader from '../subComponents/Loader'
import Search from '../subComponents/search/Search'
import { Link, useHistory } from 'react-router-dom'
import InnerHeader from '../InnerHeader/InnderHeader'
// import Pagination from '../paginatioin/pagination'
import Pagination from '@mui/material/Pagination';

function Employees() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [query, setQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(10)

  useEffect(() => {
    dispatch(getEmployees())
  }, [])

  const employees = useSelector((state) => state.employees_reducer.employees)
  const filter_employees =
    employees !== 'isLoading'
      ? employees.filter((employee) => {
        let name =
          employee.firstName.toLowerCase() +
          ' ' +
          employee.lastName.toLowerCase()
        return name.includes(query.toLowerCase())
      })
      : []
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))

  // Pagination logic
  const indexOfLastEmployee = currentPage * perPage;
  const indexOfFirstEmployee = indexOfLastEmployee - perPage;
  const currentEmployees = filter_employees.slice(indexOfFirstEmployee, indexOfLastEmployee);
  const totalPages = Math.ceil(filter_employees.length / perPage);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inline-block">Employees</h2>
            <p className='employees_set'>
              Manage all your employees here or add new{" "}
            </p>
          </div>
          <div className="col-md-3">
            <Search
              setText={setQuery}
              text={query}
              placeholder="Search Employee"
            />
          </div>
          <div className="col-md-3">
            {permissions.includes('CanAddEmployee') && (
              <Link
                to={'/admin/Addemployees'}
                className="btn btn-primary float-right"
              >
                Add Employee
              </Link>
            )}
          </div>
        </div>
        {/* <InnerHeader
          title="Stocks"
          description="Manage all your stocks or add new one"
          setQuery={setQuery}
          query={query}
          searchPlaceholder="Search stock"
          permission={permissions.includes('CanAddStock')}
          buttonTitle="+ Add Stock"
          onClick={handleOpenAdd}
        /> */}


        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left">
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Id</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Designation</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Reports To</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Status</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">View Details</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {employees != undefined &&
                  employees.length == 0 && (
                    <tr className="text-center">
                      <td colSpan={6}>No data found</td>
                    </tr>
                  )}
                {employees === "isLoading" && (
                  <tr>
                    <td
                      colSpan={6}
                      style={{ backgroundColor: "#fff !important" }}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {currentEmployees != undefined &&
                  currentEmployees !== "isLoading" &&
                  currentEmployees.length !== 0 &&
                  currentEmployees.map((row, i) => (
                    <tr
                      key={i}
                      style={{
                        backgroundColor: "transparent",
                        "--x-table-accent-bg": "transparent",
                      }}
                    >
                      <td>{row.id}</td>
                      <td>
                        <div className="avatar-wrapper">
                          <div className='avatar-set'>
                            <p>
                              {row.firstName.charAt(0)}
                              {row.lastName.charAt(0)}
                            </p>
                          </div>

                          {row.firstName + " "}{row.lastName}

                        </div></td>
                      <td>{row.designationName}</td>
                      <td>{row.id}</td>
                      <td>
                        <span
                          style={{
                            color: row.active
                              ? "#2196F3"
                              : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                            borderRadius: "100px",
                            border: `1px solid ${row.active
                                ? "var(--primary-main, #2196F3)"
                                : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                              }`,
                            padding: "3px 10px",
                          }}
                        >
                          {row.active !== undefined ? (row.active ? "Active" : "In-active") : "Active"}
                        </span>

                      </td>

                      <td>
                        <div style={{ display: "flex", gap: "15px" }}>
                          <Link
                            to={{
                              pathname: "/admin/ViewEmployees",
                              state: { rowData: row },
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                                fill="#505157"
                              />
                            </svg>
                          </Link>{" "}


                          <Link
                            to={{
                              pathname: '/admin/UpdateEmployees',
                              employee: { row },
                              id: row.id,
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z"
                                fill="#505157"
                              />
                            </svg>
                          </Link>
                        </div>
                      </td>



                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* <div className="row">
          {employees === 'isLoading' && <Loader colored={true} />}
          {employees !== 'isLoading' &&
            filter_employees.map((row, i) => (
              <div
                className="col-12 col-sm-8 col-md-4 col-lg-3 employee-wrapper"
                key={i}
              >
                <div className="card mt-4">
                  <div className="card-body text-center">
                    <div className="avatar-wrapper">
                      <div>
                        <p>
                          {row.firstName.charAt(0)}
                          {row.lastName.charAt(0)}
                        </p>
                      </div>
                    </div>
                    <h4 className="card-title">
                      {row.firstName} {row.lastName}
                    </h4>
                    <h6 className="card-subtitle mb-2 text-muted">
                      {row.designationName}
                    </h6>
                    <p className="card-subtitle mb-2 text-muted mt-2">
                      ID: {row.id}
                    </p>
                    
                    <Link
                      to={{
                        pathname: '/admin/UpdateEmployees',
                        employee: { row },
                        id: row.id,
                      }}
                      className="btn btn-outline-info"
                    >
                      View
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div> */}

        <div className='task-pagination-wrapper'>
          <Pagination count={totalPages} color="primary" shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>

      </div>
    </>
  )
}

export default Employees
