import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Filter.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { getProducts } from "../../../actions/userActions/productAction";
import { getEmployees } from "../../../actions/userActions/employeeAction";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { getCallAveragPractice } from "../../../actions/userActions/callAveragePract";
import { getPracticeLocations } from "../../../actions/userActions/practiceLocationAction";
import { getCities } from "../../../actions/userActions/cityAction";

export function StackedBarchartFilter({ show, onClose, choosefilters }) {
    const [formError, setFormError] = useState({});
    const currentDate = moment();
    const dispatch = useDispatch();
    const [Filters, setFilters] = useState({
        practiceLocation: 0,
        employeeIds: [],
        fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
        toDate: moment(currentDate).format("YYYY-MM-DD"),
    });
    const [practiceLocationBody, setPracticeLocationBody] = useState({
        filter: {
            textSearch: "",
            city: 0
        },
        pageNumber: -1,
        pageSize: 0,
    });

    const handleCityChange = selectedOption => {
        setPracticeLocationBody({
            filter: {
                textSearch: "",
                city: selectedOption.value
            },
            pageNumber: -1,
            pageSize: 0,
        }
        );
    };


    useEffect(() => {
        const cityBody = {
            "filter": {
                "textSearch": "",
                "countryId": 1
            },
            "pageNumber": -1,
            "pageSize": 0
        }
        if (show) {
            dispatch(getCities(cityBody))
            .then(() => {
                dispatch(getEmployees());
            })
            .catch(error => {
              console.error("Error fetching data:", error);
            });
            
        }

    }, [show]);
    useEffect(() => {
        if (show) {
            dispatch(getPracticeLocations(practiceLocationBody));
        }
    }, [show, practiceLocationBody]);

    const getOptions = (data, name, activecheck) => {
        const getData =
            data && data !== "isLoading"
                ? !activecheck
                    ? data.map((row, i) => ({
                        value: row.id,
                        label: row.name
                            ? row.name
                            : row.firstName && row.lastName
                                ? `${row.firstName} ${row.lastName}`
                                : row.address,
                        name: name,
                    }))
                    : data
                        .filter((row) => row.active === true)
                        .map((row, i) => ({
                            value: row.id,
                            label: row.name
                                ? row.name
                                : row.firstName && row.lastName
                                    ? `${row.firstName} ${row.lastName}`
                                    : row.address,
                            name: name,
                        }))
                : [];

        return getData;
    };

    const [selected, setSelected] = useState({
        textSearch: "",
        city: 0,
        isActive: true,
    });

    const handleChange = (e, name) => {
        if (name == "reset") {
            setSelected({
                ...Filters,
                practiceLocation: "",
                employeeIds: [],
            });
            setFilters({
                ...Filters,
                practiceLocation: "",
                employeeIds: [],
            });
        } else {
            if (name == "employeeIds") {
                setFilters({ ...Filters, [name]: e.map((data, i) => data.value) });
                setSelected({
                    ...selected,
                    [name]: e.map((data, i) => ({
                        value: data.value,
                        label: data.label,
                        name: data.name,
                    })),
                });
            } else {
                setFilters({ ...Filters, [name]: e.value });
                setSelected({
                    ...selected,
                    [name]: {
                    value: e.value,
                    label: e.label,
                    name: e.name,
                    },
                });
            }
        }
    };

    const practiceLocations = useSelector((state) => state.practiceLocations_reducer?.practiceLocations);
    const cities = useSelector((state) => state.cities_reducer.cities);
    const employees = useSelector((state) => state.employees_reducer.employees);


    // date change handler for date picker
    const dateChange = (start, end) => {
        let startDate = start.format("YYYY-MM-DD");
        let endDate = end.format("YYYY-MM-DD");
        setFilters({
            ...Filters,
            ["fromDate"]: startDate,
            ["toDate"]: endDate,
        });
    };

    const validateForm = () => {
        let errors = {};
        let isValid = true;

        if (Filters.employeeIds.length === 0) {
            errors.employee = "Employee is required";
            isValid = false;
        }

        if (!Filters.practiceLocation) {
            errors.practiceLocation = "Practice Location is required.";
            isValid = false;
        }

        setFormError(errors);
        return isValid;
    };

    const handleApply = () => {
        if (validateForm()) {
            const body = {
                practiceLocationFilters: {
                    practiceLocation: Filters.practiceLocation,
                    employeeIds: Filters.employeeIds,
                },
                startTime: Filters.fromDate,
                endTime: Filters.toDate,
            };
            dispatch(getCallAveragPractice(body));
            choosefilters(Filters.employeeIds);

            onClose();
        }
    };

    return (
        <div className="modal-container">
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Filter Results</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-6">
                            <Select
                                defaultValue={{ value: 'Lahore', label: 'Lahore' }}
                                isSearchable
                                options={getOptions(cities.cities, "cities", false)}
                                name="city"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="City"
                                onChange={handleCityChange}
                            />

                        </div>
                        <div className="col-6">
                            <Select
                                value={selected.practiceLocation}
                                isSearchable
                                //   isMulti
                                options={practiceLocations && practiceLocations !== null && getOptions(practiceLocations.practiceLocations, "practiceLocations", false)}
                                name="practiceLocation"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Select Location"
                                onChange={(e) => handleChange(e, "practiceLocation")}
                            />
                            {formError.practiceLocation && (
                                <span style={{ color: "red" }}>{formError.practiceLocation}</span>
                            )}
                        </div>
                        <div className="col-6 mt-5">
                            <Select
                                value={selected.employeeIds}
                                isSearchable
                                isMulti
                                options={getOptions(employees, "employees", false)}
                                name="employee"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Employee"
                                onChange={(e) => handleChange(e, "employeeIds")}
                            />
                            {formError.employee && (
                                <span style={{ color: "red" }}>{formError.employee}</span>
                            )}
                        </div>
                        <div className="col-6 mt-5">
                            <DateRangePicker
                                initialSettings={{
                                    startDate: moment().subtract(6, "days").toDate(),
                                    endDate: moment().toDate(),
                                    ranges: {
                                        Today: [moment().toDate(), moment().toDate()],
                                        Yesterday: [
                                            moment().subtract(1, "days").toDate(),
                                            moment().subtract(1, "days").toDate(),
                                        ],
                                        "Last 7 Days": [
                                            moment().subtract(6, "days").toDate(),
                                            moment().toDate(),
                                        ],
                                        "Last 30 Days": [
                                            moment().subtract(29, "days").toDate(),
                                            moment().toDate(),
                                        ],
                                        "This Month": [
                                            moment().startOf("month").toDate(),
                                            moment().endOf("month").toDate(),
                                        ],
                                        "Last Month": [
                                            moment().subtract(1, "month").startOf("month").toDate(),
                                            moment().subtract(1, "month").endOf("month").toDate(),
                                        ],
                                        "Last Year": [
                                            moment().subtract(1, "year").startOf("year").toDate(),
                                            moment().subtract(1, "year").endOf("year").toDate(),
                                        ],
                                    },
                                }}
                                onCallback={dateChange}
                            >
                                {/* <button className="btn btn-primary btn-sm">Date Range</button> */}
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Select Date Range"
                                />
                            </DateRangePicker>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" size="sm" onClick={handleApply}>
                        Apply
                    </Button>
                    <Button
                        variant="secondary"
                        size="sm"
                        onClick={(e) => handleChange(e, "reset")}
                    >
                        Reset
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
