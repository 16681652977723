import React, { useEffect,useState } from 'react'
import { Redirect, useHistory, useLocation } from "react-router-dom";
import Sidebar from '../nav/Sidebar'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../header/Header';
import { StyledEngineProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getToken, getMassaging } from "firebase/messaging";
import { messaging } from "../../firebase";
import userService, * as UserService from "../../services/user.service";
const Main = (props) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (history.location.pathname !== '/') {
            localStorage.setItem("path", history.location.pathname)
        }
    }, [location])

    useEffect(() => {
        if (localStorage.getItem("path")) {
            history.push(localStorage.getItem("path"))
        }
    }, [])
     //fcm work
     const user = JSON.parse(localStorage.getItem("user"));
     const employeeId =
     user && user.result[0] !== undefined && user.result[0] != null  ? user.result[0].user.id : false;
      // fcm and device id
   const [fcm, setFcm] = useState("");
   const [deviceId, setDeviceId] = useState("");
 
   useEffect(() => {
     requestFCMPermission();
   }, []);
 
   useEffect(() => {
     postFCMToken();
   }, [deviceId, fcm]);
 
   async function requestFCMPermission() {
     try {
       // Geting FCM token and device id
       requestPermission();
       getDeviceID();
 
       // Saving token and device ID to fcmBody
       const fcmBody = {
         employeeId: employeeId,
         fcm: fcm,
         deviceID: deviceId,
       };
     } catch (error) {
       console.error("Error requesting FCM permission:", error);
     }
   }
 
   async function postFCMToken() {
     if (fcm !== "" && deviceId !== "") {
       const fcmBody = {
         employeeId: employeeId,
         fcm: fcm,
         deviceID: deviceId,
       };
 
       userService.addFcmToken(fcmBody).then((res) => {
       });
       setFcm("");
       setDeviceId("");
     }
   }
   function getDeviceID() {
     if (navigator && navigator.userAgent) {
       setDeviceId(navigator.userAgent);
       return navigator.userAgent;
     } else {
       setDeviceId("default_device_id");
       return "default_device_id";
     }
   }
 
   async function requestPermission() {
     const currentPermission = Notification.permission;
 
     // Check if the permission has already been granted or denied
     if (currentPermission === "granted") {
       const token = await getToken(messaging, {
         vapidKey:
           "BMh_TwUq8QA_pWEbsRmHQDC4rqZAe3Qr1qHvyAF68bVJSNOqnjvpMJw26lk_pMjaD7JEICprj2Eabsj6gH7jdmo",
       });
 
       setFcm(token);
       return token;
     } else if (currentPermission === "denied") {
      
       return null; // Permission denied
     }
 
     // If permission hasn't been granted or denied, proceed with the request
     const requestedPermission = await Notification.requestPermission();
 
     if (requestedPermission === "granted") {
       try {
         const token = await getToken(messaging, {
           vapidKey:
             "BMh_TwUq8QA_pWEbsRmHQDC4rqZAe3Qr1qHvyAF68bVJSNOqnjvpMJw26lk_pMjaD7JEICprj2Eabsj6gH7jdmo",
         });
 
         setFcm(token);
         return token;
       } catch (error) {
         console.error("Error obtaining token:", error.message);
         throw error;
       }
     } else if (requestedPermission === "denied") {
       
       return null;
     }
   }
   // fcm end

    return (
        localStorage.getItem("user") ? (
            <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ToastContainer style={{ width: "auto", maxWidth: "800px", minWidth: "300px" }} />
                    <Sidebar />
                    <Header />
                    <StyledEngineProvider>
                        <div className="page-content" >
                            <div >
                                {props.children}
                            </div>
                        </div>
                    </StyledEngineProvider>
                </LocalizationProvider>
            </>
        ) :
            <Redirect to="/" />
    )
}

export default Main
