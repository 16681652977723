import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTaskPriorities,getTaskTypes} from '../../../actions/userActions/tasks/taskAction';
import {getEmployeeSubOrdinates} from '../../../actions/userActions/employeeAction'
import '../Tasks.css'
import Select from 'react-select'
 const TaskSection2 = (props) => {

  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect (()=>{
    dispatch(getTaskPriorities())
    .then(() => {
      dispatch(getEmployeeSubOrdinates(employeeId));
      dispatch(getTaskTypes());
    })
    
  },[])
  const employeeId = user.result[0] !== undefined ? user.result[0].user.id : false;
  const handleSelectChange = (e) => {
    props.setFormValues({...props.formValues,[e.name]:e.value});
    props.setSelectedValues({...props.selectedValues,[e.name]:{'value':e.value, 'label': e.label}});
 
  }
       //checking inputs
       if(
        props.formValues["priority"] !== '' &&
        props.formValues["assignedTo"] !== ''
        ){
            props.setBtnStatus(false);
        }
        else{
          props.setBtnStatus(true);
        }

  const taskPriorities =  useSelector(state => state.tasks_reducer.taskPriorities);
  const employees =  useSelector(state => state.employees_reducer.employeeSubordinates);

    const getOptions = (data, name, activecheck) => {

      const getData = data && data !== "isLoading"
          ?
          !activecheck
              ?
              data.map((row, i) => (
                  {
                      value: row.id,
                      label: row.name ? row.name : (row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : row.address),
                      name: name
                  }
              ))
              :
              data.filter(row => row.active === true).map((row, i) => (
                  {
                      value: row.id,
                      label: row.name ? row.name : (row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : row.address),
                      name: name
                  }
              ))
          :
          [];

          if(name === "assignedTo" ){
            getData.unshift(
              {
                value: user.result[0].user.id, 
                label:  "Assign To Me",
                name: "assignedTo"
              });
          }


      return getData;
  }
  return (
      <div className='px-5'>
        <div className="col-md-12 p-0">
            <div className="form-group">
                <label>Priority</label>
                <Select
                    value={ props.selectedValues["priority"]}
                    isSearchable
                    options={getOptions(taskPriorities,"priority",true)}
                    name="priority"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select priority"
                    onChange={handleSelectChange}
                    />
                {/* {formErrors.gender} */}
            </div>
        </div>
        <div className="col-md-12 p-0">
            <div className="form-group">
                <label>Assign To </label>
                <Select
                    value={ props.selectedValues["assignedTo"]}
                    isSearchable
                    options={getOptions(employees,"assignedTo",false)}
                    name="assignedTo"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select employee"
                    onChange={handleSelectChange}
                    />
                {/* {formErrors.gender} */}
            </div>
        </div>
      </div>
    
  )
}

export default TaskSection2